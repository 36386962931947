export const LocalStorageKeys = {
  USER_ID: "uuid",
  EMAIL: "email",
  BRAND_TABLE: "brand_table",
  BRAND_TABLE_R: "brand_table_right",
  PRODUCT_TABLE: "product_table",
  PRODUCT_TABLE_R: "product_table_right",
  GROUP_TABLE: "group_table",
  GROUP_TABLE_R: "group_table_right",
  DISTRIBUTOR_TABLE: "distributor_table",
  DISTRIBUTOR_TABLE_R: "distributor_table_right",
  DISTRIBUTOR_SET_TABLE: "distributor_set_table",
  DISTRIBUTOR_SET_TABLE_R: "distributor_set_table_right",
  CUSTOMERS_TABLE: "customers_table",
  CUSTOMERS_TABLE_R: "customers_table_right",
  EXCHANGES_TABLE: "exchanges_table",
  EXCHANGES_TABLE_R: "exchanges_table_right",
  REASON_FOR_EXCHANGE_TABLE: "reason_for_exchange_table",
  REASON_FOR_EXCHANGE_TABLE_R: "reason_for_exchange_table_right",
  REASON_FOR_REFUSAL_TABLE: "reason_for_refusal_table",
  REASON_FOR_REFUSAL_TABLE_R: "reason_for_refusal_table_right",
  CONTENTS_TABLE: "contents_table",
  CONTENTS_TABLE_R: "contents_table_right",
  CHANGELOGS_TABLE: "changelogs_table",
  CHANGELOGS_TABLE_R: "changelogs_table_right",
  USERS_TABLE: "users_table",
  USERS_TABLE_R: "users_table_right",
};

export const setInLocalStorage = (key: string, value: string) => {
  localStorage.setItem(key, value);
};

export const setInLocalStorageArray = (key: string, value: string[]) => {
  localStorage.setItem(key, JSON.stringify(value));
};

export const getInLocalStorage = (key: string) => {
  const result = localStorage.getItem(key);
  return result;
};

export const getInLocalStorageArray = (key: string) => {
  const array = localStorage.getItem(key);
  const result = JSON.parse(`${array}`);
  return result;
};

export const clearLocalStorage = () => {
  localStorage.clear();
};

import { AxiosCall } from "../models/axiosCall";
import { AxiosResponse } from "axios";
import { useEffect, useState } from "react";

const useFetchAndLoad = () => {
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string>("");
  let controller: AbortController;

  const callEndpoint = async (axiosCall: AxiosCall<any>, errorFunction?: any, adapter?: Function, successFunction?: any) => {
    if (axiosCall.controller) controller = axiosCall.controller;
    setLoading(true);
    let result = {} as AxiosResponse<any>;
    try {
      result = await axiosCall?.call;
      if (successFunction) {
        successFunction && successFunction();
      }
    } catch (err: any) {
      setLoading(false);
      setErrorMessage(`${err?.response?.data?.message}`);
      console.log("error");
      errorFunction && errorFunction();
      throw err;
    }
    setLoading(false);
    return adapter ? adapter(result) : result;
  };

  const cancelEndpoint = () => {
    setLoading(false);
    controller && controller.abort();
  };

  useEffect(() => {
    return () => {
      cancelEndpoint();
    };
  }, []);

  return { loading, callEndpoint, errorMessage };
};

export default useFetchAndLoad;

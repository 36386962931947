import "./App.scss";
import { Provider } from "react-redux";
import "bootstrap/dist/css/bootstrap.css";
import { STORE as store, PERSISTOR } from "./store/redux";
import { PersistGate } from "redux-persist/integration/react";
import MainComponent from "./MainComponent";
import "./constants/global.css";
import "./components/pages/styles/Mainpage.css";
import "./constants/responsive.css";
import "./constants/variables.css";
import { setInterceptors } from "./frameworks_and_drivers/Axios";
import { SnackbarProvider } from "notistack";
setInterceptors();

function App() {
  return (
    <Provider store={store}>
      <PersistGate persistor={PERSISTOR}>
        <SnackbarProvider maxSnack={3}>
          <MainComponent />
        </SnackbarProvider>
      </PersistGate>
    </Provider>
  );
}
export default App;

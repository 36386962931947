import React from "react";
import "bootstrap/dist/css/bootstrap.css";
import "../../../constants/responsive.css";
import "../../../constants/global.css";
import "../../../components/pages/styles/Mainpage.css";
import LogoLoading from "../../../images/loadLogo.png";
import Box from "@mui/material/Box";
import LinearProgress from "@mui/material/LinearProgress";
import "./loadingStart.css";

export default function LoadingStart() {
  const [progress, setProgress] = React.useState(14);

  React.useEffect(() => {
    const timer = setInterval(() => {
      setProgress((oldProgress) => {
        if (oldProgress === 100) {
          return 0;
        }
        const diff = Math.random() * 10;
        return Math.min(oldProgress + diff, 100);
      });
    }, 500);

    return () => {
      clearInterval(timer);
    };
  }, []);
  return (
    <div style={{ zIndex: "100000000", position: "fixed", top: "0", width: "100%" }}>
      <div>
        <div className="px-3"></div>
        <Box sx={{ width: "100%" }}>
          <LinearProgress variant="determinate" value={progress} className="color1" style={{ height: "50px" }} />
        </Box>
        <div style={{ display: "flex" }}>
          {/* <div
            className="side-menu active staticMenu color2"
            style={{ height: "95vh", padding: 0 }}
          ></div> */}

          <div className="c_body_loading color1" style={{ textAlign: "center" }}>
            <div style={{ marginTop: "40vh" }}>
              <h6>Loading Facom...</h6>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <img alt="" className="container__LogoMove" src={LogoLoading}></img>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

import React from "react";
import Alert from "@material-ui/lab/Alert";

import "./styles/ValidationAlert.css";

interface Ivalidation {
  variant?: any;
  message: string;
  show?: boolean;
}

export default function ValidationAlert(props: Ivalidation) {
  const { variant = "error", message } = props;
  return (
    <div>
      <div className="alert-stl-val">
        <Alert severity={variant}>{message}</Alert>
      </div>
    </div>
  );
}

import "./App.scss";
import "bootstrap/dist/css/bootstrap.css";
import React, { useContext } from "react";

import { Accordion, Nav } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faUser,
  faCog,
  faExchangeAlt,
  faWrench,
  faLaptopCode,
  faHammer,
  faTimesCircle,
  faHotel,
  faBuilding,
  faBoxes,
  faCircle,
  faColumns,
  faCity,
} from "@fortawesome/free-solid-svg-icons";

import "./constants/global.css";
import "./components/pages/styles/Mainpage.css";
import { Link } from "react-router-dom";

import changeLogIcon from "./images/changeLogIcon.svg";
import userIcon from "./images/userIcon.svg";

import redCircle from "./images/redCircle.svg";
import greenCircle from "./images/greenCircle.svg";
import orangeCircle from "./images/orangeCircle.svg";
import blueCircle from "./images/blueCircle.svg";
import GrayCircle from "./images/GrayCircle.svg";
import purpleCircle from "./images/purpleCircle.svg";
import yellowCircle from "./images/yellowCircle.svg";
import secondCircle from "./images/secondCircle.svg";
import { ApplicationContext } from "./context/ApplicationContext";
import { resetNewPassword } from "./api/services/user_services";

//Redux
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { IRootState } from "./store/redux";

import "./constants/responsive.css";

import { useTranslation } from "react-i18next";
import { typeUserData } from "./constants/userTypes";
import { useMediaQuery } from "@mui/material";

export default function AppNavbar(props: any) {
  const {
    exchangesDraftLength,
    exchangesDAbandonnedength,
    exchangesSubmittedLength,
    exchangesAnalisisLength,
    exchangesAcceptedLength,
    exchangesAviableLength,
    exchangesExchangedLength,
    exchangesRefusedLength,
    exchangesRecycledLength,
  } = useContext(ApplicationContext);
  const { userDisabledLength, userEnabledLength, userPendingLength, userWebServicesLength } = useContext(ApplicationContext);
  //Translations
  const { t, i18n } = useTranslation();

  //Redux
  const dispatch = useDispatch();
  const { profile } = useSelector((state: IRootState) => state.profileReducer);
  const { nameRolSelected } = profile;

  const matches = useMediaQuery("(max-width:1150px)");

  return (
    <div>
      <Accordion defaultActiveKey="1" flush>
        <Accordion.Item
          eventKey="1"
          style={{
            backgroundColor: "var(--color-nav-option-bg)",
            color: "var(--color-nav-white)",
          }}
        >
          <Accordion.Header>
            {" "}
            <FontAwesomeIcon icon={faWrench} />
            &nbsp;{(matches ? !props.open : props.open) && t("HOME.MENU.CATALOG")}
          </Accordion.Header>
          <Accordion.Body>
            <Nav variant="pills" className="flex-column">
              <Link to="/brands-list" style={{ textDecoration: "none" }}>
                <Nav.Item>
                  <Nav.Link href="/brands-list" style={{ borderRadius: 0, color: "var(--color-nav-white)" }} eventKey="first">
                    <FontAwesomeIcon
                      icon={faBoxes}
                      style={{
                        marginRight: "10px",
                        cursor: "pointer !important",
                      }}
                    />
                    {(matches ? !props.open : props.open) && t("HOME.MENU.BRANDS")}
                  </Nav.Link>
                </Nav.Item>
              </Link>
              <Link to="/product" style={{ textDecoration: "none" }}>
                <Nav.Item>
                  <Nav.Link href="/product" style={{ borderRadius: 0, color: "var(--color-nav-white)" }} eventKey="second">
                    <FontAwesomeIcon icon={faWrench} style={{ marginRight: "10px" }} />
                    {(matches ? !props.open : props.open) && t("HOME.MENU.PRODUCTS")}
                  </Nav.Link>
                </Nav.Item>
              </Link>
            </Nav>
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item
          eventKey="2"
          style={{
            backgroundColor: "var(--color-nav-option-bg)",
            color: "var(--color-nav-white)",
          }}
        >
          <Accordion.Header>
            <FontAwesomeIcon icon={faExchangeAlt} />
            &nbsp;{(matches ? !props.open : props.open) && t("HOME.MENU.WARRANTY")}
          </Accordion.Header>
          <Accordion.Body>
            <Nav variant="pills" className="flex-column">
              {nameRolSelected == typeUserData.admin && (
                <Link to="/group" style={{ textDecoration: "none" }}>
                  <Nav.Item>
                    <Nav.Link
                      href="/group"
                      style={{
                        borderRadius: 0,
                        color: "var(--color-nav-white)",
                      }}
                      eventKey="first"
                    >
                      <FontAwesomeIcon icon={faHotel} style={{ marginRight: "10px" }} />
                      {(matches ? !props.open : props.open) && t("HOME.MENU.GROUP")}
                    </Nav.Link>
                  </Nav.Item>
                </Link>
              )}
              {nameRolSelected == typeUserData.afterSales && (
                <Link to="/group" style={{ textDecoration: "none" }}>
                  <Nav.Item>
                    <Nav.Link
                      href="/group"
                      style={{
                        borderRadius: 0,
                        color: "var(--color-nav-white)",
                      }}
                      eventKey="first"
                    >
                      <FontAwesomeIcon icon={faHotel} style={{ marginRight: "10px" }} />
                      {(matches ? !props.open : props.open) && t("HOME.MENU.GROUP")}
                    </Nav.Link>
                  </Nav.Item>
                </Link>
              )}
              <Link to="/distributor" style={{ textDecoration: "none" }}>
                <Nav.Item>
                  <Nav.Link href="/distributor" style={{ color: "var(--color-nav-white)" }} eventKey="second">
                    <FontAwesomeIcon icon={faBuilding} style={{ marginRight: "10px" }} />
                    {(matches ? !props.open : props.open) && t("HOME.MENU.DISTRIBUTOR")}
                  </Nav.Link>
                </Nav.Item>
              </Link>
              {nameRolSelected !== typeUserData.distributor && (
                <Link to="/distributors-set-list" style={{ textDecoration: "none" }}>
                  <Nav.Item>
                    <Nav.Link href="/distributors-set-lis" style={{ color: "var(--color-nav-white)" }} eventKey="third">
                      <FontAwesomeIcon icon={faCity} style={{ marginRight: "10px" }} />
                      {(matches ? !props.open : props.open) && t("HOME.MENU.DISTRIBUTOR-SETS")}
                    </Nav.Link>
                  </Nav.Item>
                </Link>
              )}
              <Link to="/customers" style={{ textDecoration: "none" }}>
                <Nav.Item>
                  <Nav.Link href="/customers" style={{ borderRadius: 0, color: "var(--color-nav-white)" }} eventKey="fourth">
                    <FontAwesomeIcon icon={faUser} style={{ marginRight: "10px" }} />
                    {(matches ? !props.open : props.open) && t("HOME.MENU.CUSTOMERS")}
                  </Nav.Link>
                </Nav.Item>
              </Link>
              <div id="head2">
                <Accordion defaultActiveKey="exchanges1">
                  <Accordion.Item
                    eventKey="exchanges0"
                    style={{
                      backgroundColor: "var(--color-nav-option-bg)",
                      color: "var(--color-nav-white)",
                    }}
                  >
                    <Accordion.Header>
                      <FontAwesomeIcon icon={faExchangeAlt} style={{ marginRight: "10px" }} />
                      {(matches ? !props.open : props.open) && t("HOME.MENU.EXCHANGES")}
                    </Accordion.Header>
                    <Accordion.Body style={{ marginTop: "-10px" }}>
                      <Nav variant="pills" className="flex-column head">
                        <Link to="/exchanges" style={{ textDecoration: "none" }}>
                          <Nav.Item>
                            <Nav.Link
                              href="/exchanges"
                              style={{
                                borderRadius: 0,
                                color: "var(--color-nav-white)",
                              }}
                              eventKey="exchanges3"
                            >
                              <FontAwesomeIcon icon={faCircle} style={{ marginRight: "10px" }} />
                              {(matches ? !props.open : props.open) && t("HOME.MENU.EXCHANGES-SHOW-ALL")}
                            </Nav.Link>
                          </Nav.Item>
                        </Link>
                        <Link to="/exchanges/Draft" style={{ textDecoration: "none" }}>
                          <Nav.Item>
                            <Nav.Link
                              href="/exchanges/Draft"
                              style={{
                                borderRadius: 0,
                                color: "var(--color-nav-white)",
                              }}
                              eventKey="exchanges4"
                            >
                              <img alt="" src={GrayCircle} style={{ width: "20px", marginRight: "7px" }} />
                              {(matches ? !props.open : props.open) && t("HOME.MENU.EXCHANGES-DRAFT")}{" "}
                              <span className="numberUsers">
                                <b>{exchangesDraftLength}</b>
                              </span>
                            </Nav.Link>
                          </Nav.Item>
                        </Link>
                        <Link to="/exchanges/Abandonned" style={{ textDecoration: "none" }}>
                          <Nav.Item>
                            <Nav.Link
                              href="/exchanges/Abandonned"
                              style={{
                                borderRadius: 0,
                                color: "var(--color-nav-white)",
                              }}
                              eventKey="exchanges5"
                            >
                              <img alt="" src={secondCircle} style={{ width: "20px", marginRight: "7px" }} />
                              {(matches ? !props.open : props.open) && t("HOME.MENU.EXCHANGES-ABANDONNED")}
                              <span className="numberUsers">
                                <b>{exchangesDAbandonnedength}</b>
                              </span>
                            </Nav.Link>
                          </Nav.Item>
                        </Link>
                        <Link to="/exchanges/Submitted" style={{ textDecoration: "none" }}>
                          <Nav.Item>
                            <Nav.Link
                              href="/exchanges/Submitted"
                              style={{
                                borderRadius: 0,
                                color: "var(--color-nav-white)",
                              }}
                              eventKey="exchanges6"
                            >
                              <img alt="" src={purpleCircle} style={{ width: "20px", marginRight: "7px" }} />
                              {(matches ? !props.open : props.open) && t("HOME.MENU.EXCHANGES-SUBMITTED")}
                              <span className="numberUsers">
                                <b>{exchangesSubmittedLength}</b>
                              </span>
                            </Nav.Link>
                          </Nav.Item>
                        </Link>
                        <Link to="/exchanges/Analysis" style={{ textDecoration: "none" }}>
                          <Nav.Item>
                            <Nav.Link
                              href="/exchanges/Analysis"
                              style={{
                                borderRadius: 0,
                                color: "var(--color-nav-white)",
                              }}
                              eventKey="exchanges7"
                            >
                              <img alt="" src={orangeCircle} style={{ width: "20px", marginRight: "7px" }} />
                              {(matches ? !props.open : props.open) && t("HOME.MENU.EXCHANGES-ANALYSIS")}
                              <span className="numberUsers">
                                <b>{exchangesAnalisisLength}</b>
                              </span>
                            </Nav.Link>
                          </Nav.Item>
                        </Link>
                        <Link to="/exchanges/Accepted" style={{ textDecoration: "none" }}>
                          <Nav.Item>
                            <Nav.Link
                              href="/exchanges/Accepted"
                              style={{
                                borderRadius: 0,
                                color: "var(--color-nav-white)",
                              }}
                              eventKey="exchanges8"
                            >
                              <img alt="" src={yellowCircle} style={{ width: "20px", marginRight: "7px" }} />
                              {(matches ? !props.open : props.open) && t("HOME.MENU.EXCHANGES-ACCEPTED")}
                              <span className="numberUsers">
                                <b>{exchangesAcceptedLength}</b>
                              </span>
                            </Nav.Link>
                          </Nav.Item>
                        </Link>
                        <Link to="/exchanges/Available" style={{ textDecoration: "none" }}>
                          <Nav.Item>
                            <Nav.Link
                              href="/exchanges/Available"
                              style={{
                                borderRadius: 0,
                                color: "var(--color-nav-white)",
                              }}
                              eventKey="exchanges9"
                            >
                              <img alt="" src={blueCircle} style={{ width: "20px", marginRight: "7px" }} />
                              {(matches ? !props.open : props.open) && t("HOME.MENU.EXCHANGES-AVAILABLE")}
                              <span className="numberUsers">
                                <b>{exchangesAviableLength}</b>
                              </span>
                            </Nav.Link>
                          </Nav.Item>
                        </Link>
                        <Link to="/exchanges/Exchanged" style={{ textDecoration: "none" }}>
                          <Nav.Item>
                            <Nav.Link
                              href="/exchanges/Exchanged"
                              style={{
                                borderRadius: 0,
                                color: "var(--color-nav-white)",
                              }}
                              eventKey="exchanges10"
                            >
                              <img alt="" src={greenCircle} style={{ width: "20px", marginRight: "7px" }} />
                              {(matches ? !props.open : props.open) && t("HOME.MENU.EXCHANGES-EXCHANGED")}
                              <span className="numberUsers">
                                <b>{exchangesExchangedLength}</b>
                              </span>
                            </Nav.Link>
                          </Nav.Item>
                        </Link>
                        <Link to="/exchanges/Refused" style={{ textDecoration: "none" }}>
                          <Nav.Item>
                            <Nav.Link
                              href="/exchanges/Refused"
                              style={{
                                borderRadius: 0,
                                color: "var(--color-nav-white)",
                              }}
                              eventKey="exchanges11"
                            >
                              <img alt="" src={redCircle} style={{ width: "20px", marginRight: "7px" }} />
                              {(matches ? !props.open : props.open) && t("HOME.MENU.EXCHANGES-REFUSED")}
                              <span className="numberUsers">
                                <b>{exchangesRefusedLength}</b>
                              </span>
                            </Nav.Link>
                          </Nav.Item>
                        </Link>
                        <Link to="/exchanges/Recycled" style={{ textDecoration: "none" }}>
                          <Nav.Item>
                            <Nav.Link
                              href="/exchanges/Recycled"
                              style={{
                                borderRadius: 0,
                                color: "var(--color-nav-white)",
                              }}
                              eventKey="exchanges12"
                            >
                              <img alt="" src={redCircle} style={{ width: "20px", marginRight: "7px" }} />
                              {(matches ? !props.open : props.open) && t("HOME.MENU.EXCHANGES-RECYCLED")}
                              <span className="numberUsers">
                                <b>{exchangesRecycledLength}</b>
                              </span>
                            </Nav.Link>
                          </Nav.Item>
                        </Link>
                      </Nav>
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
              </div>
            </Nav>
          </Accordion.Body>
        </Accordion.Item>
        {nameRolSelected === typeUserData.admin && (
          <Accordion.Item
            eventKey="3"
            style={{
              backgroundColor: "var(--color-nav-option-bg)",
              color: "var(--color-nav-white)",
            }}
          >
            <Accordion.Header>
              <FontAwesomeIcon icon={faCog} />
              &nbsp;{(matches ? !props.open : props.open) && t("HOME.MENU.OTHERS")}
            </Accordion.Header>
            <Accordion.Body>
              <Nav variant="pills" className="flex-column">
                <Link to="/content-list" style={{ textDecoration: "none" }}>
                  <Nav.Item>
                    <Nav.Link
                      href="/content-list"
                      style={{
                        borderRadius: 0,
                        color: "var(--color-nav-white)",
                      }}
                      eventKey="first"
                    >
                      <FontAwesomeIcon icon={faLaptopCode} style={{ marginRight: "10px" }} />
                      {(matches ? !props.open : props.open) && t("HOME.MENU.CONTENTS")}
                    </Nav.Link>
                  </Nav.Item>
                </Link>
                <Link to="/reasons-for-exchange" style={{ textDecoration: "none" }}>
                  <Nav.Item>
                    <Nav.Link style={{ color: "var(--color-nav-white)" }} href="/reasons-for-exchange" eventKey="second">
                      <FontAwesomeIcon icon={faHammer} style={{ marginRight: "10px" }} />
                      {(matches ? !props.open : props.open) && t("HOME.MENU.REASON-FOR-EXCHANGE")}
                    </Nav.Link>
                  </Nav.Item>
                </Link>

                <Link to="/refusal-reasons" style={{ textDecoration: "none" }}>
                  <Nav.Item>
                    <Nav.Link style={{ color: "var(--color-nav-white)" }} href="/refusal-reasons" eventKey="thirth">
                      <FontAwesomeIcon icon={faTimesCircle} style={{ marginRight: "10px" }} />
                      {(matches ? !props.open : props.open) && t("HOME.MENU.REFUSAL-REASONS")}
                    </Nav.Link>
                  </Nav.Item>
                </Link>
                <Link to="/change-log" style={{ textDecoration: "none" }}>
                  <Nav.Item>
                    <Nav.Link style={{ color: "var(--color-nav-white)" }} href="/change-log" eventKey="fourth">
                      <img alt="" src={changeLogIcon} style={{ width: "20px", marginRight: "7px" }} />
                      {(matches ? !props.open : props.open) && t("HOME.MENU.CHANGE-LOG")}
                    </Nav.Link>
                  </Nav.Item>
                </Link>
                <Link to="/users" style={{ textDecoration: "none" }}>
                  <Nav.Item>
                    <Nav.Link
                      href="/users"
                      style={{
                        borderRadius: 0,
                        color: "var(--color-nav-white)",
                      }}
                      eventKey="user3"
                    >
                      <img alt="" src={userIcon} style={{ width: "24px", marginRight: "7px" }} />
                      {(matches ? !props.open : props.open) && t("HOME.MENU.USERS")}
                    </Nav.Link>
                  </Nav.Item>
                </Link>
              </Nav>
            </Accordion.Body>
          </Accordion.Item>
        )}
      </Accordion>
    </div>
  );
}

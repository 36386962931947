import { Dispatch } from "redux";
import { SET_PROFILE, CLEAR_STATE, SET_ROLE} from ".";

export type Profile = {
  token?: string;
  languaje?:string;
  email?: string;
  full_name?: string;
  roles?: string[]
  roles_data?:RolesData
  sub?: string; //ID del usuario autenticado
  username?: string;
  nameRolSelected?:string;
};

export type RolesData = [{
  id?: string;
  name?: string;
  views_enabled?: string;
}]

const setProfile = (profile: Profile) => (dispatch: Dispatch) => {
  dispatch({
    type: SET_PROFILE,
    payload: profile,
  });
};

const clearState = () => (dispatch: Dispatch) => {
  dispatch({
    type: CLEAR_STATE,
  });
};

export { setProfile, clearState };

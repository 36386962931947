import { SET_PROFILE, CLEAR_STATE } from "../actions";
import { AnyAction } from "redux";
import { INITIAL_STATE } from ".";

const profileReducer = (state = INITIAL_STATE, action: AnyAction) => {
  switch (action.type) {
    case SET_PROFILE:
      return { ...state, profile: {...state.profile,...action.payload} };
    case CLEAR_STATE:
      return { ...INITIAL_STATE };
    default:
      return state;
  }
};


export { profileReducer };

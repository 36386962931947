let resources = {
  en: {
    translation: {
      //Global
      "HOVER.ALL.BUTTON-SEARCH": "Search",
      "HOVER.ALL.MENU-BARS-RELOAD": "Reload",
      "HOVER.ALL.MENU-BARS-COPY": "Copy link",
      "HOVER.ALL.MENU-BARS-DELETE": "Delete",
      "HOVER.ALL.MENU-BARS-SELECT": "Select",
      "HOVER.ALL.MENU-BARS-OPEN": "Open",
      "HOVER.ALL.MENU-BARS-CREATE": "Create",
      "LIST.ALL.BUTTON-CREATE": "Create",
      "LIST.ALL.BUTTON-SELECT": "Select",
      "CREATE.ALL.BUTTON-SAVE": "Save",
      "CREATE.ALL.BUTTON-SAVE-MENU": "Save & New",
      "CREATE.ALL.BUTTON-SAVE-CLOSE": "Save & Close",
      "CREATE.ALL.BUTTON-CLOSE": "Close",
      "COMMENTS.ALL.BUTTON-SHARE": "Share",
      "SEARCH.ALL.BUTTON-SEARCH": "Search",
      "SEARCH.ALL.BUTTON-RESET": "Reset",
      "SEARCH.ALL.BUTTON-CLOSE": "Close",
      "SEARCH.ALL.BUTTON-SORT-ORDER": "Sort Order",
      "LOGIN.ALL.USER": "User",
      "LOGIN.ALL.PASSWORD": "Password",
      "LOGIN.ALL.FORGOT-PASSWORD": "I forgot my password",
      "HOME.MAIN-NAV.QUIT": "Quit",
      "HOME.MAIN-NAV.SHARE-POSTS": "Share posts",
      "HOME.MAIN-NAV.FRONTEND": "Frontend",
      "HOME.MAIN-NAV.CVS-IMPORT": "CVS Import",
      "HOME.MAIN-NAV.ABOUT": "About",
      "HOME.MENU.CATALOG": "Catalog",
      "HOME.MENU.BRANDS": "Brands",
      "HOME.MENU.PRODUCTS": "Products",
      "HOME.MENU.WARRANTY": "Warranty",
      "HOME.MENU.GROUP": "Group",
      "HOME.MENU.DISTRIBUTOR": "Distributor",
      "HOME.MENU.DISTRIBUTOR-SETS": "Distributor sets",
      "HOME.MENU.DISTRIBUTOR-SETS-CREATE": "Create Distributor set",
      "HOME.MENU.CUSTOMERS": "Customers",
      "HOME.MENU.EXCHANGES": "Exchanges",
      "HOME.MENU.EXCHANGES-SHOW-ALL": "Show all",
      "HOME.MENU.EXCHANGES-DRAFT": "Draft",
      "HOME.MENU.EXCHANGES-ABANDONNED": "Abandonned",
      "HOME.MENU.EXCHANGES-SUBMITTED": "Submitted",
      "HOME.MENU.EXCHANGES-ANALYSIS": "Analysis",
      "HOME.MENU.EXCHANGES-ACCEPTED": "Accepted",
      "HOME.MENU.EXCHANGES-AVAILABLE": "Available",
      "HOME.MENU.EXCHANGES-EXCHANGED": "Exchanged",
      "HOME.MENU.EXCHANGES-REFUSED": "Refused",
      "HOME.MENU.EXCHANGES-RECYCLED": "Recycled",
      "HOME.MENU.CONTACTS": "Contacts",
      "HOME.MENU.OTHERS": "Others",
      "HOME.MENU.CONTENTS": "Contents",
      "HOME.MENU.REASON-FOR-EXCHANGE": "Reason for exchange",
      "HOME.MENU.REFUSAL-REASONS": "Refusal reasons",
      "HOME.MENU.CHANGE-LOG": "Change log",
      "HOME.MENU.USERS": "Users",
      "HOME.MENU.USERS-SHOW-ALL": "Show all",
      "HOME.MENU.USERS-DISABLED": "Disabled",
      "HOME.MENU.USERS-ENABLED": "Enabled",
      "HOME.MENU.USERS-PENDING": "Pending",
      "HOME.MENU.USERS-WEB-SERVICES": "Web services only",
      "ALL.MENU.DELETE": "Delete",
      "ALL.MENU.RELOAD": "Reload",
      "ALL.MENU.COPY-LINK": "Copy Link",
      "ALL.MENU.COPY": "Copy",
      "ALL-INFO.CONSOLE-LOG-TABLE": "Change log",
      "ALL-INFO.EXCHANGES-TABLE": "Exchanges",
      "ALL-INFO.DELETE-CONFIRM": "Do you confirm to delete all the selected items?",
      "ALL-BUTTON-COPY-ITEM": "Copy",
      "ALL-BUTTON-PREFERENCES": "Preferences",

      // CATALOG.BRANDS
      "TABLE.BRAND.TITLE.HEADER-LINK": "Brand",
      "TABLE.BRAND.TITLE.HEADER-SUB-LINK": "Create Brand",
      "TABLE.BRAND.TITLE-HEADER": "Brands",
      "TABLE.BRAND.HEADER-TOTAL": "Total",
      "TABLE.BRAND.HEADER-BUTTON-CREATE": "Create",
      "TABLE.BRAND.HEADER-BUTTON-UPDATE-THE-LIST": "Update the list",
      "TABLE.BRAND.HEADER-OPTIONS-LIST-RELOAD": "Reload",
      "TABLE.BRAND.HEADER-OPTIONS-LIST-DELETE": "Delete",
      "TABLE.BRAND.HEADER-OPTIONS-LIST-COPY-LINK": "Copy Link",
      "TABLE.BRAND-HEADER-TITLE-IDENTIFICATION": "Identification",
      "TABLE.BRAND-HEADER-TITLE-LOGO": "Logo",
      "TABLE.BRAND.TITLE-CODE-ON-LIST": "Code",
      "TABLE.BRAND.TITLE-NAME": "Name",
      "TABLE.BRAND.TITLE-WEBSITE": "Website",
      "TABLE.BRAND.TITLE-LOGO": "Logo",
      "SEARCH.BRAND.TITLE-SEARCH-BRANDS": "Search Brands",
      "SEARCH.BRAND.DEFINITION": "Definition",
      "SEARCH.BRAND.CODE": "Code",
      "SEARCH.BRAND.NAME": "Name",
      "SEARCH.BRAND.WEBSITE": "Website",
      "SEARCH.BRAND.SEARCH": "Search",
      "SEARCH.BRAND.RESET": "Reset",
      "SEARCH.BRAND.CLOSE": "Close",
      "SEARCH.BRAND.SORT-ORDER": "Sort Order",
      "SEARCH.BRAND.APPLY": "Apply",
      "CREATE.BRAND.CARD-HEADER-TITLE-IDENTIFICATION": "Identification",
      "CREATE.BRAND.INPUT-CODE": "Code",
      "CREATE.BRAND.INPUT-NAME": "Name",
      "CREATE.BRAND.INPUT-WEBSITE": "Website",
      "CREATE.BRAND.INPUT-DESCRIPTION": "Description",
      "CREATE.BRAND.CARD-HEADER-TITLE-LOGO": "Logo",
      "CREATE.BRAND.INPUT-LOGO": "Logo",
      "INFO.BRAND.PRODUCT-TABLE": "Supplier's products",
      "INFO.BRAND.CONSOLE-LOG": "Change log",

      //Product
      "TABLE.PRODUCT.TITLE.HEADER-LINK": "Product",
      "TABLE.PRODUCT.TITLE-HEADER": "Products",
      "TABLE.PRODUCT.HEADER-TOTAL": "Total",
      "ALL.GENERAL.PRODUCT-HEADER-TITLE-BRAND": "Brand",
      "ALL.GENERAL.PRODUCT-HEADER-TITLE-IDENTIFICATION": "Identification",
      "ALL.GENERAL.PRODUCT-HEADER-TITLE-STOCK-AND-PRICING": "Stock and pricing",
      "TABLE.PRODUCT.TITLE-CODE-ON-LIST": "Code (on list)",
      "ALL.GENERAL.PRODUCT.TITLE-BRAND-NAME": "Brand name",
      "ALL.GENERAL.PRODUCT.TITLE-REFERENCE": "Reference",
      "ALL.GENERAL.PRODUCT.TITLE-NAME": "Name",
      "ALL.GENERAL.PRODUCT.TITLE-TYPE": "Type",
      "ALL.GENERAL.PRODUCT.TITLE-PICTURE": "Picture",
      "ALL.GENERAL.PRODUCT.TITLE-UNIT-PRICE": "Unit price",
      "ALL.GENERAL.PRODUCT.TITLE-AVAILABLE": "Available",
      "ALL.GENERAL.PRODUCT.TITLE-WARRANTY": "Warranty",
      "CREATE.PRODUCT.HEADER-SECOND-LINK": "Create product",
      "CREATE.PRODUCT.TITLE-HEADER": "Create product",
      "CREATE.PRODUCT.INPUT-BRAND-CODE": "Brand Code",
      "CREATE.PRODUCT.INPUT-OLD-REFERENCE": "Old reference",
      "CREATE.PRODUCT.INPUT-DEFAULT": "Default",
      "CREATE.PRODUCT.CARD-HEADER-TITLE-MARKETING": "Marketing",
      "CREATE.PRODUCT.INPUT-DESCRIPTION": "Description",
      "CREATE.PRODUCT.INPUT-BROCHURE": "Brochure",
      "CREATE.PRODUCT.INPUT-ONLINE-DOC": "Online doc",
      "CREATE.PRODUCT.INPUT-EAN": "EAN",
      "CREATE.PRODUCT.INPUT-EAN-IMAGE": "EAN image",
      "SEARCH.PRODUCT.TITLE-SEARCH-PRODUCTS": "Search Products",
      "SEARCH.PRODUCT.BRAND": "Brand",
      "SEARCH.PRODUCT.INPUT-BRAND": "Brand",
      "SEARCH.PRODUCT.INPUT-BRAND-CODE": "Brand code",
      "SEARCH.BRAND.INPUT-OLD-REFERENCE": "Old reference",
      "SEARCH.PRODUCT.INPUT-DEFAULT": "Default",
      "SEARCH.PRODUCT.INPUT-BROCHURE": "Brochure",
      "SEARCH.PRODUCT.INPUT-EAN": "Ean",
      "SORT.PRODUCT.INPUT-CODE-BRAND": "Brand Code",
      "SORT.PRODUCT.INPUT-NAME-BRAND": "Brand Name",
      "SORT.PRODUCT.INPUT-REFERENCE": "Reference",
      "SORT.PRODUCT.INPUT-OLD-REFERENCE": "Old reference",
      "SORT.PRODUCT.INPUT-NAME": "Name",
      "SORT.PRODUCT.INPUT-TYPE": "Type",
      "SORT.PRODUCT.INPUT-UNIT-PRICE": "Unit Price",
      "SORT.PRODUCT.INPUT-AVAILABLE": "Available",
      "SORT.PRODUCT.INPUT-WARRANTY": "Warranty",

      // Group

      "TABLE.GROUP.TITLE.HEADER-LINK": "Group",
      "TABLE.GROUP.TITLE-HEADER": "Group",
      "TABLE.GROUP.HEADER-TOTAL": "Total",
      "ALL.GENERAL.TABLE.GROUP.TITLE-CODE": "Code",
      "ALL.GENERAL.TABLE.GROUP.TITLE-NAME": "Name",
      "ALL.GENERAL.TABLE.GROUP.TITLE-LOGO": "Logo",
      "CREATE.GROUP.HEADER-SECOND-LINK": "Create Group",
      "CREATE.GROUP.TITLE-HEADER": "Create Group",
      "CREATE.GROUP.INPUT-DESCRIPTION": "Description",
      "SEARCH.GROUP.TITLE-SEARCH-GROUP": "Search Group",
      "SEARCH.GROUP.TITLE-SEARCH-DISTRIBUTOR-SET": "Search Distributors sets",
      "SEARCH.GROUP.DEFINITION": "Definition",
      "SORT.GROUP.INPUT-CODE": "Code",
      "SORT.GROUP.INPUT-NAME": "Name",

      //Distributor set
      "ALL.GENERAL.TABLE.DIST-SET.TITLE-CODE": "Group Code",
      "ALL.GENERAL.TABLE.DIST-SET.TITLE-NAME-GROUP": "Group Name",
      "ALL.GENERAL.TABLE.DIST-SET.TITLE-NAME": "Name",
      "ALL.GENERAL.TABLE.DIST-SET.TITLE-ORIGIN": "Origin",
      "ALL.GENERAL.TABLE.DIST-SET.TITLE-MEMBERS": "Members of set",
      "ALL.GENERAL.TABLE.DIST-SET.TITLE-DESCRIPTION": "Description",
      "DISTRIBUTOR-SET-ALERT-1": "A set associated to a given origin restricts the access to the corresponding distributors.",
      "DISTRIBUTOR-SET-ALERT-2": "Otherwise, if no set is associated to a given origin, no restriction applies.",
      "ALERT-SUCCESS-CREATE": "Distributor sets has been Created",
      "ALERT-SUCCESS-UPDATE": "Distributor sets has been Updated",
      "ALERT-ERROR-CREATE": "Error creating distributor sets",
      "ALERT-ERROR-UPDATE": "Error updating distributor sets",
      "ALERT-ERROR-GET-DS": "Error getting distributor sets",
      "ALERT-ERROR-DIFFERENT-GROUP1": "Inconsistent distributor groups",
      "ALERT-ERROR-DIFFERENT-GROUP2": "Field is required: ",

      //Distributor

      "TABLE.DISTRIBUTOR.TITLE.HEADER-LINK": "Distributor",
      "TABLE.DISTRIBUTOR.TITLE-HEADER": "Distributor",
      "TABLE.DISTRIBUTOR.HEADER-TOTAL": "Total",
      "ALL.GENERAL.DISTRIBUTOR-HEADER-TITLE-IDENTIFICATION": "Identification",
      "ALL.GENERAL.DISTRIBUTOR.TITLE-GROUP-CODE": "Group Code",
      "ALL.GENERAL.DISTRIBUTOR.TITLE-CODE": "Code",
      "ALL.GENERAL.DISTRIBUTOR.TITLE-CLIENT-ACCOUNT-NUMBER": "Client account number",
      "ALL.GENERAL.DISTRIBUTOR.TITLE-DELIVERY-ACCOUNT-NUMBER": "Delivery account number",
      "ALL.GENERAL.DISTRIBUTOR.TITLE-NAME": "Name",
      "ALL.GENERAL.DISTRIBUTOR.TITLE-VISIBLE": "Visible",
      "ALL.GENERAL.DISTRIBUTOR.TITLE-LOGO": "Logo",
      "ALL.GENERAL.DISTRIBUTOR.TITLE-POSTAL-CODE": "Postal Code",
      "ALL.GENERAL.DISTRIBUTOR.TITLE-CITY": "City",
      "ALL.GENERAL.DISTRIBUTOR.TITLE-PHONE": "Phone",
      "ALL.GENERAL.DISTRIBUTOR.TITLE-EMAIL": "Distributor's e-mail address",
      "ALL.GENERAL.DISTRIBUTOR.TITLE-RESPONSIBLE-EMAIL": "Responsible's e-mail address",
      "ALL.GENERAL.DISTRIBUTOR.TITLE-EVALUATION": "Evaluation",
      "CREATE.DISTRIBUTOR.INPUT-ADDRESS": "Address",
      "CREATE.DISTRIBUTOR.INPUT-POSTAL-CODE": "Postal Code",
      "CREATE.DISTRIBUTOR.INPUT-OPENING-HOURS": "Opening Hours",
      "CREATE.DISTRIBUTOR.INPUT-GEOGRAPHICAL-COORDINATES": "Geographical coordinates",
      "CREATE.DISTRIBUTOR.INPUT-MAP-URL": "Map Url",
      "SEARCH.DISTRIBUTOR.TITLE-SEARCH-DISTRIBUTOR": "Search Distributor",
      "SEARCH.DISTRIBUTOR.TITLE-IDENTIFICATION": "Identification",
      "SEARCH.DISTRIBUTOR.INPUT-GROUP": "Group",
      "SEARCH.DISTRIBUTOR.INPUT-GROUP-NAME": "Group name",
      "SEARCH.DISTRIBUTOR.INPUT-ADDRESS": "Address",
      "SEARCH.DISTRIBUTOR.INPUT-GEOGRAPHICAL-COORDINATES": "Geographical coordinates",
      "SEARCH.DISTRIBUTOR.INPUT-MAP-URL": "Map Url",
      "SEARCH.DISTRIBUTOR.INPUT-LOOKUP": "Lookup",
      "CREATE.DISTRIBUTOR.HEADER-SECOND-LINK": "Create Distributor",
      "BREADCRUMB-DISTRIBUTOR-MAP": "Map Distributor",
      "DISTRIBUTOR-MAP-TITLE": "Map",

      // Customer

      "TABLE.CUSTOMER.TITLE.HEADER-LINK": "Customer",
      "TABLE.CUSTOMER.TITLE-HEADER": "Customers",
      "TABLE.CUSTOMER.HEADER-TOTAL": "Total",
      "TABLE.CUSTOMER.HEADER-BUTTON-GENERATE-A-NEW-PASSWORD": "Generate a new password",
      "ALL.GENERAL.CUSTOMER-HEADER-TITLE-IDENTIFICATION": "Identification",
      "ALL.GENERAL.CUSTOMER-HEADER-TITLE-ADDRESS": "Address",
      "ALL.GENERAL.CUSTOMER-HEADER-TITLE-MEANS-OF-CONTACT": "Means of contact",
      "ALL.GENERAL.CUSTOMER.TITLE-USERNAME": "Username",
      "ALL.GENERAL.CUSTOMER.TITLE-FIRST-NAME": "First name",
      "ALL.GENERAL.CUSTOMER.TITLE-LAST-NAME": "Last name",
      "ALL.GENERAL.CUSTOMER.TITLE-TYPE": "Type",
      "ALL.GENERAL.CUSTOMER.TITLE-BUSINESS": "Business",
      "ALL.GENERAL.CUSTOMER.TITLE-SIRET-CODE": "Company Name",
      "ALL.GENERAL.CUSTOMER.TITLE-ZIP-CODE": "Zip code",
      "ALL.GENERAL.CUSTOMER.TITLE-CITY": "City",
      "ALL.GENERAL.CUSTOMER.TITLE-COUNTRY": "Country",
      "ALL.GENERAL.CUSTOMER.TITLE-EMAIL": "Email",
      "ALL.GENERAL.CUSTOMER.TITLE-MOBILE-PHONE-NUMBER": "Mobile phone number",
      "CREATE.CUSTOMER.HEADER-SECOND-LINK": "Customer",
      "CREATE.CUSTOMER.TITLE-HEADER": "Create customer",
      "CREATE.CUSTOMER.NAV-HEADER-TITLE-OTHERS": "Others",
      "CREATE.CUSTOMER.NAV-INPUT-ADDRESS-COMPLEMENT": "Address complement",
      "CREATE.CUSTOMER.NAV-INPUT-WORK-PHONE-NUMBER": "Work phone number",
      "CREATE.CUSTOMER.NAV-INPUT-HOME-PHONE-NUMBER": "Home phone number",
      "CREATE.CUSTOMER.NAV-INPUT-FAX-NUMBER": "Fax number",
      "CREATE.CUSTOMER.NAV-INPUT-GENERAL-TERMS-ACCEPTED": "General terms accepted",
      "CREATE.CUSTOMER.NAV-INPUT-PRIVACY-POLICY-ACCEPTED": "Privacy policy accepted",
      "CREATE.CUSTOMER.NAV-INPUT-REGISTRATION-DATE": "Registration date",
      "CREATE.CUSTOMER.NAV-INPUT-COMMENTS": "Comments",
      "CREATE.CUSTOMER.NAV-INPUT-COORDINATES": "Coordinates",
      "CREATE.CUSTOMER.NAV-INPUT-MAP-URL": "Map url",
      "CREATE.CUSTOMER.CARD-INPUT-PASSWORD": "Password",
      "SEARCH.CUSTOMER.TITLE-SEARCH-CUSTOMERS": "Search Customers",
      "SEARCH.CUSTOMER.TITLE-IDENTIFICATION": "Identification",
      "SEARCH.CUSTOMER.TITLE-ADDRESS": "Address",
      "SEARCH.CUSTOMER.TITLE-OTHERS": "Others",
      "SEARCH.CUSTOMER.INPUT-MAP-URL": "Map Url",
      "SEARCH.CUSTOMER.INPUT-GENERAL-TERMS-ACCEPTED": "General terms accepted",
      "SEARCH.CUSTOMER.INPUT-PRIVACY-POLICY-ACCEPTED": "Privacy policy accepted",
      "SEARCH.CUSTOMER.INPUT-REGISTRATION-DATE": "Registration date",
      "MODAL.CUSTOMER-TITLE": "Confirm : Generate a new password",
      "MODAL.CUSTOMER-INPUT-EMAIL-ADDRESS": "Email address",
      "MODAL.CUSTOMER-BUTTON-CONFIRM": "Confirm",
      "MODAL.CUSTOMER-BUTTON-CANCEL": "Cancel",
      "MODAL.CUSTOMER-USER": "Users",
      "MODAL.CUSTOMER-EXCHANGES": "Customer's exchanges",
      "MODAL.CUSTOMER-CHANGELOG": " Change log",
      "SORT.CUSTOMER.INPUT-USERNAME": "Username",
      "SORT.CUSTOMER.INPUT-FIRST-NAME": "First name",
      "SORT.CUSTOMER.INPUT-LAST-NAME": "Last name",
      "SORT.CUSTOMER.INPUT-TYPE": "Type",
      "SORT.CUSTOMER.INPUT-BUSINESS": "Business",
      "SORT.CUSTOMER.INPUT-COMPANY-NAME": "Company name",
      "SORT.CUSTOMER.INPUT-ZIPCODE": "Zip code",
      "SORT.CUSTOMER.INPUT-CITY": "City",
      "SORT.CUSTOMER.INPUT-COUNTRY": "Country",
      "SORT.CUSTOMER.INPUT-EMAIL": "Email",
      "SORT.CUSTOMER.INPUT-MOBILE-PHONE": "Mobile phone",

      // Exchanges

      "TABLE.EXCHANGE.HEADER-LINK": "Exchange",
      "TABLE. EXCHANGE.TITLE-HEADER": "Exchanges",
      "TABLE.EXCHANGE.HEADER-TOTAL": "Total",
      "TABLE.EXCHANGE.HEADER-TITLE-IDENTIFICATION-AND-STATUS": "Identification and status",
      "TABLE.EXCHANGE.HEADER-TITLE-CUSTOMER": "Customer",
      "TABLE.EXCHANGE.HEADER-TITLE-PRODUCT": "Product",
      "TABLE.EXCHANGE.HEADER-TITLE-DISTRIBUTOR": "Distributor",
      "ALL.GENERAL-EXCHANGE.TITLE-DETAILS": "Details",
      "ALL.GENERAL-EXCHANGE.TITLE-NUMBER": "Number",
      "ALL.GENERAL-EXCHANGE.TITLE-DATE": "Date",
      "ALL.GENERAL-EXCHANGE.TITLE-STATUS": "Status",
      "ALL.GENERAL-EXCHANGE.TITLE-CUSTOMER-USERNAME": "Customer username",
      "ALL.GENERAL-EXCHANGE.TITLE-PRODUCT-REFERENCE": "Product reference",
      "ALL.GENERAL-EXCHANGE.TITLE-BRAND-NAME": "Brand Name",
      "ALL.GENERAL-EXCHANGE.TITLE-GIVEN-REASON": "Given reason",
      "TABLE.EXCHANGE.TITLE-EVALUATION": "Evaluation",
      "ALL.GENERAL-EXCHANGE.TITLE-QUANTITY": "Quantité",
      "ALL.GENERAL-EXCHANGE.TITLE-DISTRIBUTOR-CODE": "Distributor code",
      "ALL.GENERAL-EXCHANGE.TITLE-DISTRIBUTOR-GROUP-CODE": "Distributor group code",
      "ALL.GENERAL-EXCHANGE.TITLE-DISTRIBUTOR-CLIENT-ACCOUNT-NUMBER": "Distributor client account number",
      "CREATE.EXCHANGE.HEADER-SECOND-LINK": "Exchanges",
      "CREATE.EXCHANGE.TITLE-HEADER": "Create exchange",
      "CREATE.EXCHANGES.TITLE-IDENTIFICATION-AND-STATUS": "Identification and status",
      "CREATE.EXCHANGES.TITLE-CUSTOMER": "Customer",
      "CREATE.EXCHANGES.TITLE-DISTRIBUTOR": "Distributor",
      "CREATE.EXCHANGES.INPUT-CUSTOMER-FIRST-NAME": "First name",
      "CREATE.EXCHANGES.INPUT-CUSTOMER-EMAIL": "Email",
      "CREATE.EXCHANGES.INPUT-CUSTOMER-LAST-NAME": "Last name",
      "CREATE.EXCHANGES.INPUT-CUSTOMER-MOBILE-PHONE-NUMBER": "Customer mobile phone number",
      "CREATE.EXCHANGES.INPUT-CUSTOMER-DISTRIBUTOR-NAME": "Distributor name",
      "CREATE.EXCHANGES.INPUT-CUSTOMER-DISTRIBUTOR-GROUP-NAME": "Distributor group name",
      "CREATE.EXCHANGES.INPUT-CUSTOMER-CLIENT-ACCOUNT-NUMBER": "Distributor Client account number",
      "CREATE.EXCHANGES.INPUT-CUSTOMER-DISTRIBUTOR-PHONE": "Distributor phone",
      "CREATE.EXCHANGES.INPUT-CUSTOMER-DISTRIBUTOR-EMAIL": "Distributor email",
      "CREATE.EXCHANGES.INPUT-UNIT-PRICE": "Unit price",
      "CREATE.EXCHANGES.INPUT-TOTAL-COST": "Total cost",
      "CREATE.EXCHANGES.INPUT-GEOGRAPHICAL-COORDINATES": "Geographical coordinates",
      "CREATE.EXCHANGES.INPUT-PRODUCT-NAME": "Product name",
      "CREATE.EXCHANGES.INPUT-BRAND-CODE": "Brand code",
      "CUSTOMER-TABLE-EXCHANGES-INPUT-POSTAL.CODE": "Customer zip code",
      "CUSTOMER-TABLE-EXCHANGES-INPUT-CITY": "Customer city",
      "CUSTOMER-TABLE-EXCHANGES-INPUT-COUNTRY": "Customer country",
      "CUSTOMER-TABLE-EXCHANGES-INPUT-LANGUAGE": "Customer Language",

      // "CREATE.EXCHANGES.INPUT-BRAND-NAME": "Brand name",
      "CREATE.EXCHANGES.TITLE-PRODUCT": "Product",
      "CREATE.EXCHANGES.INPUT-PRODUCT-PICTURE": "Product picture",
      "CREATE.EXCHANGES.INPUT-PRODUCT-TYPE": "Product type",
      "CREATE.EXCHANGES.INPUT-CURRENT-UNIT-PRICE": "Current unit price",
      "CREATE.EXCHANGES.INPUT-DETAILS-COMMENTS": "Comments",
      "CREATE.EXCHANGES.INPUT-PICTURE-ONE": "Picture 1",
      "CREATE.EXCHANGES.INPUT-PICTURE-TWO": "Picture 2",
      "CREATE.EXCHANGES.INPUT-PICTURE-THREE": "Picture 3",
      "CREATE.EXCHANGES.INPUT-PICTURE-FOUR": "Picture 4",
      "CREATE.EXCHANGES.INPUT-QR-CODE": "QR code",
      "CREATE.EXCHANGES.INPUT-CODE-FOR-DIRECT-EVALUATION": "Code for direct evaluation",

      "SEARCH.EXCHANGES.TITLE-SEARCH-EXCHANGES": "Search Exchanges",
      "SEARCH.EXCHANGES.TITLE-IDENTIFICATION-AND-STATUS": "Identification and status",
      "SEARCH.EXCHANGES.INPUT-FROM": "From",
      "SEARCH.EXCHANGES.INPUT-TO": "To",
      "SEARCH.EXCHANGES.INPUT-FILTER-DRAFT": "Draft",
      "SEARCH.EXCHANGES.INPUT-FILTER-ABANDONNED": "Abandonned",
      "SEARCH.EXCHANGES.INPUT-FILTER-SUBMITTED": "Submitted",
      "SEARCH.EXCHANGES.INPUT-FILTER-ANALYSIS": "Analysis",
      "SEARCH.EXCHANGES.INPUT-FILTER-ACCEPTED": "Accepted",
      "SEARCH.EXCHANGES.INPUT-FILTER-AVAILABLE": "Available",
      "SEARCH.EXCHANGES.INPUT-FILTER-EXCHANGED": "Exchanged",
      "SEARCH.EXCHANGES.INPUT-FILTER-REFUSED": "Refused",
      "SEARCH.EXCHANGES.INPUT-FILTER-RECYCLED": "Recycled",
      "SEARCH.EXCHANGES.INPUT-REFUSAL-REASON": "Refusal reason",
      "SEARCH.EXCHANGES.TITLE-CUSTOMER": "Customer",
      "SEARCH.EXCHANGES.INPUT-CUSTOMER": "Customer",
      "SEARCH.EXCHANGES.INPUT-CUSTOMER-FIRST-NAME": "Customer first name",
      "SEARCH.EXCHANGES.INPUT-CUSTOMER-LAST-NAME": "Customer last name",
      "SEARCH.EXCHANGES.TITLE-PRODUCT": "Product",
      "SEARCH.EXCHANGES.INPUT-PRODUCT-REFERENCE": "Product reference",
      "SEARCH.EXCHANGES.INPUT-PRODUCT-NAME": "Product name",
      "SEARCH.EXCHANGES.INPUT-PRODUCT-TYPE": "Product type",
      "SEARCH.EXCHANGES.INPUT-BRAND-CODE": "Brand code",
      "SEARCH.EXCHANGES.INPUT-EVALUATION": "Evaluation",
      "SEARCH.EXCHANGES.INPUT-COMMENT": "Comments",
      "SEARCH.EXCHANGES.INPUT-MIN": "Min",
      "SEARCH.EXCHANGES.INPUT-MAX": "Max",
      "SEARCH.EXCHANGES.INPUT-TOTAL-COST": "Total cost",
      "SEARCH.EXCHANGES.INPUT-GEOGRAPHICAL-COORDINATES": "Geographical coordinates",
      "SEARCH.EXCHANGES.INPUT-CODE-FOR-DIRECT-EVALUATION": "Code for direct evaluation",
      "SEARCH.EXCHANGES.TITLE-DISTRIBUTOR": "Distributor",
      "SEARCH.EXCHANGES.INPUT-DISTRIBUTOR": "Distributor",
      "SEARCH.EXCHANGES.INPUT-DISTRIBUTOR-NAME": "Distributor name",
      "SEARCH.EXCHANGES.INPUT-DISTRIBUTOR-GROUP-NAME": "Distributor group name",

      //Reason for exchanges
      "TABLE.REASON-EXCHANGE.HEADER-LINK": "Reason for exchange",
      "TABLE.REASON-EXCHANGE.TITLE-HEADER": "Reason for exchanges",
      "TABLE.REASON-EXCHANGE.HEADER-TOTAL": "Total",
      "ALL.GENERAL-REASON-EXCHANGE.TITLE-CODE": "Code",
      "ALL.GENERAL-REASON-EXCHANGE.TITLE-LANGUAJE": "Language",
      "ALL.GENERAL-REASON-EXCHANGE.TITLE-DISPLAY-ORDER": "Display order",
      "ALL.GENERAL-REASON-EXCHANGE.TITLE-ELIGIBLE": "Eligible for exchange",
      "ALL.GENERAL-REASON-EXCHANGE.TITLE-LABEL": "Label",
      "ALL.GENERAL-REASON-EXCHANGE.TITLE-DESCRIPTION": "Description",
      "CREATE.REASON-EXCHANGE.HEADER-LINK": "Reason For exchange",
      "CREATE.REASON-EXCHANGE.HEADER-SECOND-LINK": "Create Reason For exchange",
      "CREATE.REASON-EXCHANGE.TITLE-HEADER": "Create Reason For exchange",
      "SEARCH.REASON-EXCHANGE.TITLE-HEADER": "Search Reason for exchange",
      "SEARCH.REASON-EXCHANGE.TITLE-BOX-DEFINITION": "Definition",

      //Refusal reason
      "TABLE.REFUSAL-REASON.HEADER-LINK": "Refusal reason",
      "TABLE.REFUSAL-REASON.TITLE-HEADER": "Refusal reasons",
      "TABLE.REFUSAL-REASON.HEADER-TOTAL": "Total",
      "ALL.GENERAL-REFUSAL-REASON.TITLE-CODE": "Code",
      "ALL.GENERAL-REFUSAL-REASON.TITLE-LANGUAJE": "Language",
      "ALL.GENERAL-REFUSAL-REASON.TITLE-DISPLAY-ORDER": "Display order",
      "ALL.GENERAL-REFUSAL-REASON.TITLE-LABEL": "Label",
      "ALL.GENERAL-REFUSAL-REASON.TITLE-DESCRIPTION": "Description",
      "CREATE.REFUSAL-REASON.HEADER-LINK": "Refusal reason",
      "CREATE.REFUSAL-REASON.HEADER-SECOND-LINK": "Create Refusal reason",
      "CREATE.REFUSAL-REASON.TITLE-HEADER": "Create Refusal reason",
      "SEARCH.REFUSAL-REASON.TITLE": "Search Refusal reasons",
      "SEARCH.REASON-EXCHANGE.TITLE-BOX": "FacRefusalReason",

      //Change log
      "TABLE.CHANGE-LOG.HEADER-LINK": "Change log",
      "TABLE.CHANGE-LOG.TITLE-HEADER": "Change log",
      "TABLE.CHANGE-LOG.HEADER-TOTAL": "Total",
      "TABLE.CHANGE-LOG.HEADER-TITLE-USER": "User session",
      "TABLE.CHANGE-LOG.HEADER-TITLE-ACTION": "Action",
      "ALL.GENERAL-CHANGE-LOG.TITLE-SESSION": "Index Session",
      "ALL.GENERAL-CHANGE-LOG.TITLE-LOGIN": "Session login",
      "ALL.GENERAL-CHANGE-LOG.TITLE-USER": "User",
      "ALL.GENERAL-CHANGE-LOG.TITLE-LAST-NAME": "Name",
      "ALL.GENERAL-CHANGE-LOG.TITLE-DATE": "Date",
      "ALL.GENERAL-CHANGE-LOG.TITLE-PRIMARY": "Primary login",
      "ALL.GENERAL-CHANGE-LOG.TITLE-OBJECT": "Object",
      "ALL.GENERAL-CHANGE-LOG.TITLE-ACTION": "Action",
      "ALL.GENERAL-CHANGE-LOG.TITLE-ACTIVITY": "Activity",
      "INFO.CHANGE-LOG.TITLE-HEADER": "Change log",
      "SEARCH-INFO.CHANGE-LOG.INPUT-SESSION": "Session Date",
      "SEARCH.CHANGE-LOG.INPUT-INDEX": "Index",
      "SEARCH.CHANGE-LOG.TITLE-HEADER": "Search Change log",
      "SEARCH.REASON-EXCHANGE.TITLE-BOX-USER": "User session",
      "SEARCH.REASON-EXCHANGE.TITLE-BOX-ACTION": "Action",

      //Users
      "TABLE.USER.HEADER-LINK": "User",
      "TABLE. USER.TITLE-HEADER": "User",
      "TABLE.USER.HEADER-": "Total",
      "TABLE. USER.HEADER-TITLE-PICTURE": "Picture",
      "TABLE. USER.HEADER-TITLE-PROPERTIES": "Properties",
      "TABLE. USER.HEADER-TITLE-CONTACT": "Contact",
      "TABLE. USER.HEADER-TITLE-PREFERENCES": "Preferences",
      "TABLE. USER.HEADER-TITLE-DISTRIBUTOR": "Facom distributor",
      "TABLE. USER.HEADER-TITLE-GROUP": "Facom distributor group",
      "ALL.GENERAL-USER.TITLE-PICTURE": "Picture",
      "ALL.GENERAL-USER.TITLE-LOGIN": "Username",
      "ALL.GENERAL-USER.TITLE-LANGUAJE": "Preferred language",
      "TABLE.USER.TITLE-LANGUAJE": "Language",
      "TABLE.USER.TITLE-HOME-PAGE": "Home page",
      "TABLE.USER.TITLE-MODULE-NAME": "Module name",
      "TABLE.USER.TITLE-LAST-LOGON": "Last Logon",
      "ALL.GENERAL-USER.TITLE-NAME": "Name",
      "ALL.GENERAL-USER.TITLE-FIRST-NAME": "First name",
      "ALL.GENERAL-USER.TITLE-LAST-NAME": "Last name",
      "ALL.GENERAL-USER.TITLE-EMAIL": "Email",
      "ALL.GENERAL-USER.TITLE-ACTIVE": "Active",
      "ALL.GENERAL-USER.TITLE-WORK-PHONE": "Work phone",
      "ALL.GENERAL-USER.TITLE-HOME-PHONE": "Home phone",
      "ALL.GENERAL-USER.TITLE-MOBILE-PHONE": "Mobile phone",
      "ALL.GENERAL-USER.TITLE-DISTRIBUTOR-CODE": "Distributor code",
      "ALL.GENERAL-USER.TITLE-DISTRIBUTOR-NAME": "Distributor name",
      "ALL.GENERAL-USER.TITLE-GROUP-CODE": "Group code",
      "ALL.GENERAL-USER.TITLE-GROUP-NAME": "Group name",
      "INFO. USER.TITLE-HEADER": "User",
      "INFO. USER.SUB-TITLE-HEADER": "User",
      "INFO. USER.BUTTON.RESET-PASSWORD": "Nouveau mot de passe",
      "INFO. USER.BUTTON.DISABLED": "Disabled",
      "INFO. USER.BUTTON.ENABLED": "Enabled",
      "INFO-CREATE.USER-INPUT-USER-TYPE": "User type",
      "INFO-CREATE.USER-INPUT-STATUS": "Status",
      "INFO-CREATE.USER-INPUT-ACTIVE-LANGUAJE": "Active language",
      "INFO-CREATE.USER-INPUT-VIEW": "View",
      "INFO-CREATE.USER-INPUT-TITLE": "Title",
      "INFO-CREATE.USER-INPUT-ADDRESS": "Address",
      "INFO-CREATE.USER-INPUT-ADDRESS-COMPLEMENT": "Address Complement",
      "INFO-CREATE.USER-INPUT-ZIPCODE": "Zip code",
      "INFO-CREATE.USER-INPUT-": "City",
      "INFO-CREATE.USER-INPUT-STATE": "State",
      "INFO-CREATE.USER-INPUT-COUNTRY": "Country",
      "INFO-CREATE.USER-CUSTOMER-BOX.TITLE": "Facom Customer",
      "INFO-CREATE.USER-CUSTOMER-BOX-INPUT-USERNAME": "Username",
      "INFO-CREATE.USER-CUSTOMER-BOX-INPUT-FIRST-NAME": "First name",
      "INFO-CREATE.USER-CUSTOMER-BOX-INPUT-LAST-NAME": "Last name",
      "INFO-CREATE.USER-DISTRIBUTOR-BOX.TITLE": "Facom Distributor",
      "INFO-CREATE.USER-GROUP-BOX.": "Facom Distributor group",
      "INFO-CREATE.USER-PICTURE-BOX.TITLE": "Picture",
      "INFO-CREATE.USER-PROPIERTIES-BOX.TITLE": "Propierties",
      "INFO-CREATE.USER-CONTACT-BOX.TITLE": "Contact",
      "INFO-CREATE.USER-ADDRESS-BOX.TITLE": "Address",
      "INFO-CREATE.USER-TITLE": "Create user",
      "INFO-CREATE.USER-TITLE-LINK": "Create user",
      "SEARCH.USER.TITLE-HEADER": "Search Users",
      "SEARCH.USER.TITLE-ACTIVE": "Active",
      "SEARCH.USER.TITLE-PENDING": "Pending",
      "SEARCH.USER.TITLE-WEB-SERVICES": "Web services only",
      "SEARCH.USER.TITLE-HOME-PAGE": "Home page",
      "SEARCH.USER.TITLE-FROM": "From",
      "SEARCH.USER.TITLE-TO": "To",
      "SEARCH.USER.DATE-FORMAT": "Date format",
      "SEARCH.USER.DATE-INTERFACE": "Interface",
      "SEARCH.USER.DATE-DEFAULT-DOMAIN": "Default opened domain",
      "SEARCH.USER.DISTRIBUTOR": "Distributor",
      "SEARCH.USER.CUSTOMER": "Customer",
      "SEARCH.USER.CUSTOMER-USERNAME": "Customer Username",
      "SEARCH.USER.CUSTOMER-FIRST-NAME": "Customer First name",
      "SEARCH.USER.CUSTOMER-LAST-NAME": "Customer Last name",
      "SEARCH.USER.GROUP": "Group",
      "CREATE.USER.ROLE": "Role",
      "CREATE.USER.MENU.VISIBLE": "Menu visible",
      "CREATE.USER.MENU.FACEOM-CUSTOMER-INFO": "Facom Customer Info",
      "CREATE.USER.MENU.BUSINESS": "Business",
      "CREATE.USER.SIRET CODE": "Company Name",
      "CREATE.USER.PASSWORD": "Password",
      "INFO.USER.RESET-PASSWORD": "Do you confirm to execute this action?: Reset password",
      "INFO.USER.RESET-CONFIRM": "Confirm",
      "INFO.USER.RESET-BUTTON-CONFIRM": "Yes",
      "INFO.USER.RESET-BUTTON-CANCEL": "No",
      "ALERT-USER-INVALID-ROLE": "Invalid role",

      //STATS
      "TABLE.PRODUCTS-STATS.TITLE-EXTRACTION": "Extraction",
      "TABLE.PRODUCTS-STATS.TITLE-HAMMERS": "Hammers Strike",
      "TABLE.PRODUCTS-STATS.TITLE-MALEWRENCHS": "Male Wrenchs",
      "TABLE.PRODUCTS-STATS.TITLE-TRACING": "Measuring Tracing",
      "TABLE.PRODUCTS-STATS.TITLE-OTHER": "Other",
      "TABLE.PRODUCTS-STATS.TITLE-PAWLS": "Pawls",
      "TABLE.PRODUCTS-STATS.TITLE-PLIERS": "Pliers vise pliers",
      "TABLE.PRODUCTS-STATS.TITLE-SAWING": "Sawing drilling cutting",
      "TABLE.PRODUCTS-STATS.TITLE-SCREWDRIVERS": "Screwdrivers",
      "TABLE.PRODUCTS-STATS.TITLE-SOCKETS": "Sockets",
      "TABLE.PRODUCTS-STATS.TITLE-OPENINGWRENCH": "Fixed or variable opening wrench",
      "CARDS.PRODUCTS-STATS.TYPE": "Type",
      "CARDS.PRODUCTS-STATS.BRAND-NAME": "Brand name",
      "ALL-STATS-COLOR": "Color",
      "ALL-STATS-HIDDEN": "Hidden",
      "ALL-STATS-WIDTH": "Page width (px|%)",
      "ALL-STATS-HEIGHT": "Page height (px|%)",
      "ALL-STATS-EXPORT-CSV": "CSV",
      "ALL-STATS-EXPORT-EXCEL": "Microsoft Excel",
      "PRODUCTS-STATS.HEADER.TITLE": "products",
      "STATS.HEADER.SUB-TITLE": "stats",
      "NAV-ALL-ITEM1": "Options",
      "NAV-ALL-ITEM2": "Data",
      "NAV-ALL-ITEM3": "Export",
      "DISTRIBUTORS-STATS.HEADER.TITLE": "Distributors",
      "TABLE.DISTRIBUTORS-TITLE-YES": "Yes",
      "TABLE.DISTRIBUTORS-TITLE-NO": "No",
      "CARDS.DISTRIBUTORS-STATS.VISIBLE": "Visible ? ",
      "CARDS.DISTRIBUTORS-STATS.GROUP-NAME": "Group Name",
      "CARDS.CUSTOMERS-STATS.TYPE": "Type",
      "CARDS.CUSTOMERS-STATS.REGISTRATION-DATE": "Registration date",
      "CARDS.CUSTOMERS-STATS.BUSINESS": "Business",
      "CUSTOMERS-STATS.HEADER.TITLE": "Customers",
      "TABLE-CUSTOMER-STATS-TITLE-INDIVIDUAL": "Individual",
      "TABLE-CUSTOMER-STATS-TITLE-COMPANY": "Company",
      "TABLE-CUSTOMER-STATS-TITLE-ARTISAN": "Professional",
      "TABLE-CUSTOMER-STATS-TITLE-TOTAL": "Total",
      "TABLE-CUSTOMER-STATS-TITLE-INDUSTRY": "Industry",
      "TABLE-CUSTOMER-STATS-TITLE-MECHANIC": "Mechanic",
      "TABLE-CUSTOMER-STATS-TITLE-EXTERIOR-REN": "Exterior renovation",
      "TABLE-CUSTOMER-STATS-TITLE-INTERIOR-REN": "Interior renovation",
      "TABLE-CUSTOMER-STATS-TITLE-COACHBUILDER": "Coachbuilder",
      "TABLE-CUSTOMER-STATS-TITLE-CARPENTER": "Carpenter",
      "TABLE-CUSTOMER-STATS-TITLE-COPPERSMITH": "Coppersmith",
      "TABLE-CUSTOMER-STATS-TITLE-ROOFER": "Roofer",
      "TABLE-CUSTOMER-STATS-TITLE-ELECTRICIAN": "Electrician",
      "TABLE-CUSTOMER-STATS-TITLE-IRONWORKER": "Ironworker",
      "TABLE-CUSTOMER-STATS-TITLE-BUILDER": "Builder",
      "TABLE-CUSTOMER-STATS-TITLE-WOOD-SHAPER": "Wood shaper",
      "TABLE-CUSTOMER-STATS-TITLE-TOOLMAKER": "Toolmaker",
      "TABLE-CUSTOMER-STATS-TITLE-BUILDINGWORKER": "Building worker",
      "TABLE-CUSTOMER-STATS-TITLE-HOUSE-PAINTER": "House painter",
      "TABLE-CUSTOMER-STATS-TITLE-PLUMER": "Plumer",
      "TABLE-CUSTOMER-STATS-TITLE-LOCKSMITH": "Locksmith",
      "TABLE-CUSTOMER-STATS-TITLE-WELDER": "Welder",
      "TABLE-CUSTOMER-STATS-TITLE-STONERUTTER": "Stonerutter",
      "TABLE-CUSTOMER-STATS-TITLE-ELECTRICIAN-TECHNICIAN": "Electrician technician",
      "TABLE-CUSTOMER-STATS-TITLE-LATHE-MILLING": "Lathe milling",
      "TABLE-CUSTOMER-STATS-TITLE-OTHER-CRAFTMAN": "Other craftman",
      "TABLE-CUSTOMER-STATS-TITLE-OTHERS": "Others",
      "ALL-STATS-BUTTON": "Stats",
      "CUSTOMER-STATS-BUTTON": "Registration stats",
      "EXCHANGES-STATS-BUTTON-NAV-1": "Activity",
      "EXCHANGES-STATS-BUTTON-NAV-2": "Products per distributors",
      "EXCHANGES-STATS-BUTTON-NAV-3": "Products per dates",
      "EXCHANGES-ACTIVITY-STATS-CARD-STATUS": "Status",
      "EXCHANGES-ACTIVITY-STATS-CARD-DATE": "Date",
      "EXCHANGES-TITLE-LINK": "Exchanges",
      "EXCHANGES-ACTIVITY-TITLE-SECOND-LINK": "Activity",
      "TABLE-EXCHANGES-ACTIVITY-TITLE-ANALYSIS": "Analysis",
      "TABLE-EXCHANGES-ACTIVITY-TITLE-ABANDONNED": "Abandonned",
      "TABLE-EXCHANGES-ACTIVITY-TITLE-REFUSED": "Refused",
      "TABLE-EXCHANGES-ACTIVITY-TITLE-AVAILABLE": "Available",
      "TABLE-EXCHANGES-ACTIVITY-TITLE-EXCHANGED": "Exchanged",
      "TABLE-EXCHANGES-ACTIVITY-TITLE-SUBMITTED": "Submitted",
      "TABLE-EXCHANGES-ACTIVITY-TITLE-RECYCLED": "Recycled",
      "TABLE-EXCHANGES-ACTIVITY-TITLE-ACCEPTED": "Accepted",
      "TABLE-EXCHANGES-ACTIVITY-TITLE-DRAFT": "Draft",
      "TABLE-EXCHANGES-ACTIVITY-TITLE-TOTAL": "Total",
      "CARDS.EXCHANGES-PER-DIST-STATS.GROUP": "Group",
      "CARDS.EXCHANGES-PER-DIST-STATS.DISTRIBUTOR": "Distributor",
      "CARDS.EXCHANGES-PER-DIST-STATS.TYPE": "Type",
      "CARDS.EXCHANGES-PER-DIST-STATS.QUANTITY": "Quantity",
      "CARDS.EXCHANGES-PER-DATE-STATS.BRAND-CODE": "Brand code",
      "CARDS.EXCHANGES-PER-DATE-STATS.PRODUCT": "Product reference",
      "CARDS.EXCHANGES-PER-DATE-STATS.DATE": "Date",
      "CARDS.EXCHANGES-PER-DATE-STATS.QUANTITY": "Quantity",
      "CARDS.EXCHANGES-PER-DATE-STATS.TOTAL-COST": "Total cost",
      "EXCHANGES-PER-DATES-TITLE-SECOND-LINK": "Products per dates",
      "EXCHANGES-PER-PRODUCTS-TITLE-SECOND-LINK": "Products per distributors",

      //Alerts
      "ALERT-GLOBAL-FILL-FIELDS": "Please fill in the required fields",
      "ALERT-BRAND-SUCCESS-DELETE": "Brand has been Deleted",
      "ALERT-BRAND-SUCCESS-UPDATED": "Brand has been Updated",
      "ALERT-BRAND-SUCCESS-CREATE": "Brand has been Created",
      "ALERT-BRAND-ERROR-GET-ONE": "Error getting brand information",
      "ALERT-BRAND-ERROR-GET": "Error getting brands",
      "ALERT-BRAND-ERROR-UPDATE": "Error update brand",
      "ALERT-BRAND-ERROR-CREATE": "Error creating brand",
      "ALERT-BRAND-ERROR-DELETE": "Error deleting brand",
      "ALERT-BRAND-ERROR-EXPORT": "Error exporting brand",

      //Alert Products
      "ALERT-PRODUCTS-SUCCESS-DELETE": "Product has been Deleted",
      "ALERT-PRODUCTS-SUCCESS-UPDATED": "Product has been Updated",
      "ALERT-PRODUCTS-SUCCESS-CREATE": "Product has been Created",
      "ALERT-PRODUCTS-ERROR-GET-ONE": "Error getting product information",
      "ALERT-PRODUCTS-ERROR-GET": "Error getting products",
      "ALERT-PRODUCTS-ERROR-UPDATE": "Error update product",
      "ALERT-PRODUCTS-ERROR-CREATE": "Error creating product",
      "ALERT-PRODUCTS-ERROR-DELETE": "Error deleting product",
      "ALERT-PRODUCTS-ERROR-EXPORT": "Error exporting product",

      //Alert Comments
      "ALERT-COMMENTS-SUCCESS-DELETE": "Comments has been Deleted",
      "ALERT-COMMENTS-SUCCESS-UPDATED": "Comments has been Updated",
      "ALERT-COMMENTS-SUCCESS-CREATE": "Comments has been Created",
      "ALERT-COMMENTS-ERROR-GET-ONE": "Error getting comments information",
      "ALERT-COMMENTS-ERROR-GET": "Error getting comments",
      "ALERT-COMMENTS-ERROR-UPDATE": "Error update comment",
      "ALERT-COMMENTS-ERROR-CREATE": "Error creating comment",
      "ALERT-COMMENTS-ERROR-DELETE": "Error deleting comment",
      "ALERT-COMMENTS-ERROR-EXPORT": "Error exporting comment",

      "CONTENTS-TITLE": "Content",
      "CONTENTS-ENGLISH-VARIANT": "English content",
      "CONTENTS-FRENCH-VARIANT": "French contents",
      "ALERT-CONTENT-SUCCESS-DELETE": "Content has been Deleted",
      "ALERT-CONTENT-SUCCESS-UPDATED": "Content has been Updated",
      "ALERT-CONTENT-SUCCESS-CREATED": "Content has been Created",
      "ALERT-CONTENT-SUCCESS-COPY": "Content has been Copy",
      "ALERT-CONTENT-ERROR-GET-ONE": "Error getting content information",
      "ALERT-CONTENT-ERROR-GET": "Error getting content",
      "ALERT-CONTENT-ERROR-UPDATE": "Error update content",
      "ALERT-CONTENT-ERROR-CREATE": "Error creating content",
      "ALERT-CONTENT-ERROR-DELETE": "Error deleting content",
      "ALERT-CONTENT-ERROR-EXPORT": "Error exporting content",

      //Alerts Reason For Exchange

      //Alert Distributors
      "ALERT-DISTRIBUTORS-SUCCESS-DELETE": "Distributor has been Deleted",
      "ALERT-DISTRIBUTORS-SUCCESS-UPDATED": "Distributor has been Updated",
      "ALERT-DISTRIBUTORS-SUCCESS-CREATE": "Distributor has been Created",
      "ALERT-DISTRIBUTORS-ERROR-GET-ONE": "Error getting distributor information",
      "ALERT-DISTRIBUTORS-ERROR-GET": "Error getting distributors",
      "ALERT-DISTRIBUTORS-ERROR-UPDATE": "Error update distributor",
      "ALERT-DISTRIBUTORS-ERROR-CREATE": "Error creating distributor",
      "ALERT-DISTRIBUTORS-ERROR-DELETE": "Error deleting distributor",
      "ALERT-DISTRIBUTORS-ERROR-EXPORT": "Error exporting distributor",

      //Alert Groups
      "ALERT-GROUPS-SUCCESS-DELETE": "Group has been Deleted",
      "ALERT-GROUPS-SUCCESS-UPDATED": "Group has been Updated",
      "ALERT-GROUPS-SUCCESS-CREATE": "Group has been Created",
      "ALERT-GROUPS-ERROR-GET-ONE": "Error getting group information",
      "ALERT-GROUPS-ERROR-GET": "Error getting groups",
      "ALERT-GROUPS-ERROR-UPDATE": "Error update group",
      "ALERT-GROUPS-ERROR-CREATE": "Error creating group",
      "ALERT-GROUPS-ERROR-DELETE": "Error deleting group",
      "ALERT-GROUPS-ERROR-EXPORT": "Error exporting group",

      "ALERT-REASON-FOR-EXCHANGE-SUCCESS-DELETE": "Reason For Exchange has been Deleted",
      "ALERT-REASON-FOR-EXCHANGE-SUCCESS-UPDATED": "Reason For Exchange has been Updated",
      "ALERT-REASON-FOR-EXCHANGE-SUCCESS-CREATE": "Reason For Exchange has been Created",
      "ALERT-REASON-FOR-EXCHANGE-ERROR-GET-ONE": "Error getting Reason For Exchange information",
      "ALERT-REASON-FOR-EXCHANGE-ERROR-GET": "Error getting Reason For Exchange",
      "ALERT-REASON-FOR-EXCHANGE-ERROR-UPDATE": "Error update Reason For Exchange",
      "ALERT-REASON-FOR-EXCHANGE-ERROR-CREATE": "Error creating Reason For Exchange",
      "ALERT-REASON-FOR-EXCHANGE-ERROR-DELETE": "Error deleting Reason For Exchange",
      "ALERT-REASON-FOR-EXCHANGE-ERROR-EXPORT": "Error exporting Reason For Exchange",

      //Alerts Refusal Reason
      "ALERT-REFUSAL-REASON-SUCCESS-DELETE": "Refusal Reason has been Deleted",
      "ALERT-REFUSAL-REASON-SUCCESS-UPDATED": "Refusal Reason has been Updated",
      "ALERT-REFUSAL-REASON-SUCCESS-CREATE": "Refusal Reason has been Created",
      "ALERT-REFUSAL-REASON-ERROR-GET-ONE": "Error getting Refusal Reason information",
      "ALERT-REFUSAL-REASON-ERROR-GET": "Error getting Refusal Reason",
      "ALERT-REFUSAL-REASON-ERROR-UPDATE": "Error update Refusal Reason",
      "ALERT-REFUSAL-REASON-ERROR-CREATE": "Error creating Refusal Reason",
      "ALERT-REFUSAL-REASON-ERROR-DELETE": "Error deleting Refusal Reason",
      "ALERT-REFUSAL-REASON-ERROR-EXPORT": "Error exporting Refusal Reason",

      //Alerts ChangeLog
      "ALERT-CHANGE-LOG-ERROR-GET-ONE": "Error getting Change log information",
      "ALERT-CHANGE-LOG-ERROR-GET": "Error getting Change log",

      //Alerts Customers
      "ALERT-CUSTOMERS-SUCCESS-DELETE": "Customers has been Deleted",
      "ALERT-CUSTOMERS-SUCCESS-UPDATED": "Customers has been Updated",
      "ALERT-CUSTOMERS-SUCCESS-CREATE": "Customers has been Created",
      "ALERT-CUSTOMERS-ERROR-GET-ONE": "Error getting Customers information",
      "ALERT-CUSTOMERS-ERROR-GET": "Error getting Customers",
      "ALERT-CUSTOMERS-ERROR-UPDATE": "Error update Customers",
      "ALERT-CUSTOMERS-ERROR-CREATE": "Error creating Customers",
      "ALERT-CUSTOMERS-ERROR-DELETE": "Error deleting Customers",
      "ALERT-CUSTOMERS-ERROR-EXPORT": "Error exporting Customers",

      "ALERT-ERROR-EMAIL-EXIST": "This email has already been registered",
      "ALERT-SIRET-CODE": "It must be a 10 digit number",
      "ALERT-GENERATE-PASSWORD": "Error generate password",

      //Alert Exchanges
      "ALERT-EXCHANGES-SUCCESS-DELETE": "Exchange has been Deleted",
      "ALERT-EXCHANGES-SUCCESS-UPDATED": "Exchange has been Updated",
      "ALERT-EXCHANGES-SUCCESS-CREATE": "Exchange has been Created",
      "ALERT-EXCHANGES-ERROR-GET-ONE": "Error getting Exchange information",
      "ALERT-EXCHANGES-ERROR-GET": "Error getting Exchanges",
      "ALERT-EXCHANGES-ERROR-UPDATE": "Error update Exchange",
      "ALERT-EXCHANGES-ERROR-UPDATE-STATUS": "Error update Exchange by Status",
      "ALERT-EXCHANGES-ERROR-CREATE": "Error creating Exchange",
      "ALERT-EXCHANGES-ERROR-DELETE": "Error deleting Exchange",
      "ALERT-EXCHANGES-ERROR-EXPORT": "Error exporting Exchange",

      //Alert Exchanges
      "ALERT-USERS-SUCCESS-DELETE": "User has been Deleted",
      "ALERT-USERS-SUCCESS-UPDATED": "User has been Updated",
      "ALERT-USERS-SUCCESS-UPDATED-STATUS": "User status has been Updated",
      "ALERT-USERS-SUCCESS-CREATE": "User has been Created",
      "ALERT-USERS-SUCCESS-CREATE-ROL": "User has been Created",
      "ALERT-USERS-SUCCESS-CREATE-DISTRIBUTOR": "User distributor has been Created",
      "ALERT-USERS-SUCCESS-CREATE-GROUP": "User group has been Created",
      "ALERT-USERS-ERROR-CREATE-ROL": "Error create user",
      "ALERT-USERS-ERROR-CREATE-DISTRIBUTOR": "Error create user distributor",
      "ALERT-USERS-ERROR-CREATE-GROUP": "Error create user group",
      "ALERT-USERS-ERROR-GET-ONE": "Error getting User information",
      "ALERT-USERS-ERROR-GET": "Error getting Users",
      "ALERT-USERS-ERROR-GET-STATUS": "Error getting Users Status",
      "ALERT-USERS-ERROR-UPDATE": "Error update User",
      "ALERT-USERS-ERROR-UPDATE-STATUS": "Error update User Status",
      "ALERT-USERS-ERROR-CREATE": "Error creating User",
      "ALERT-USERS-ERROR-DELETE": "Error deleting User",
      "ALERT-USERS-ERROR-EXPORT": "Error exporting User",
      "ALERT-USERS-WARNING-GROUP": "Select Group",
      "ALERT-USERS-WARNING-DISTRIBUTOR": "Select Distributor",

      "ALERT-GLOBAL-MATCH-PASSWORD": "Passwords doesn't match",
      "ALERT-ERROR-IMAGE-SIZE": "Image must be less than 3MB",
      "ALERT-LOADING-IMAGE": "Loading image...",

      //LOGIN AND OTHERS
      "LOGIN-INPUT-USER": "Email",
      "LOGIN-INPUT-PASSWORD": "Password",
      "LOGIN-BUTTON": "Login",
      "LOGIN-FORGOT-PASSWORD": "Forgot password",
      "LOGIN-FORGOT-PASSWORD-INPUT": "EMAIL",
      "LOGIN-FORGOT-PASSWORD-LABEL": "Please enter your email to verify your identity and receive your password renewal.",
      "LOGIN-FORGOT-PASSWORD-BUTTON": "Send",
      "LOGIN-FORGOT-PASSWORD-BUTTON-RESEND": "Resend",
      "CONFIRM-EMAIL-MAIN-TEXT": "PASSWORD SETUP",
      "CONFIRM-EMAIL-MAIN-DESCRIPTION": "Before you can login you will need to reset your password.",
      "CONFIRM-EMAIL-MAIN-STEP-1": "Register your account",
      "CONFIRM-EMAIL-MAIN-STEP-2": "Activate account via email",
      "CONFIRM-EMAIL-MAIN-STEP-3": "Setup password via email link",
      "CONFIRM-EMAIL-BUTTON": "Go to Login",
      "ERROR-EMAIL-CONFIRM-LABEL": "Write your email and password to resend activation link",

      "RESET-PASSWORD-INPUT-PASSWORD": "Password",
      "RESET-PASSWORD-INPUT-PASSWORD-CONFIRM": "Confirm Password",
      "RESET-PASSWORD-PASSWORD-BUTTON": "Send",
      "RESET-PASSWORD-TITLE": "New Password",
      "ALERT-GLOBAL-MATCH-PASSWORD-CHARACTERS":
        "The password must contain: / 6 - 16 characters and a capital letter / A number / A special character",
      "RESET-PASSWORD-SUCCES-ALERT": "Your password has been changed",

      //EXCHANGES STATUS BUTTONS
      "EXCHANGES-ANALYZED-BUTTON": "To be Analyzed",
      "EXCHANGES-ACCEPT-BUTTON": "Accept",
      "EXCHANGES-REFUSE-BUTTON": "Refuse",
      "EXCHANGES-SUBMITTED-BUTTON": "Submitted",
      "EXCHANGES-DRAFT-BUTTON": "Draft",
      "EXCHANGES-AVAILABLE-BUTTON": "Available",
      "EXCHANGES-EXCHANGE-BUTTON": "Exchange",
      "EXCHANGES-RECYCLE-BUTTON": "Recycle",
      "EXCHANGES-ABANDONED-BUTTON": "Abandon",

      "FILER-TO-DOCK": "Detach the search",
      "FILER-TO-COLUMNS": "Pin to columns",
      "ALERT-CHECK-SESSION": "Your session has expired",

      "ROLES-DISTRIBUTOR": "Distributor",
      "ROLES-GROUP": "Distributor group",
      "ROLES-AFTERSALES": "Post sale",
      "ROLES-ADMIN": "Administrator",
      "ROLES-CUSTOMERS": "Customer",

      "MODAL-CONFIRM-CHANGE-STATUS-DESCRIPTION": "Do you confirm to execute this action?:",
      "MODAL-CONFIRM-CHANGE-STATUS-CONFIRM": "Yes",
      "MODAL-CONFIRM-CHANGE-STATUS-CANCEL": "No",

      "FILE-BUTTON-REMOVE": "Remove",
      "FILE-DESCRIPTION": "File",

      //Copy

      "BRAND-COPY-TITLE": "Copy Brand",
      "ALERT-BRAND-COPY": "Brand has been copied",
      "ALERT-BRAND-COPY-ERROR": "Failed to copy the brand",

      "PRODUCT-COPY-TITLE": "Copy Product",
      "ALERT-PRODUCT-COPY": "Product has been copied",
      "ALERT-PRODUCT-COPY-ERROR": "Failed to copy the Product",

      "DISTRIBUTOR-COPY-TITLE": "Copy Distributor",
      "ALERT-DISTRIBUTOR-COPY": "Distributor has been copied",
      "ALERT-DISTRIBUTOR-COPY-ERROR": "Failed to copy the Distributor",

      "DISTRIBUTOR-SET-COPY-TITLE": "Copy Distributor set",
      "ALERT-DISTRIBUTOR-SET-COPY": "Distributor Set has been copied",
      "ALERT-DISTRIBUTOR-SET-COPY-ERROR": "Failed to copy the Distributor Set",

      "GROUP-COPY-TITLE": "Copy Group",
      "ALERT-GROUP-COPY": "Group has been copied",
      "ALERT-GROUP-COPY-ERROR": "Failed to copy the Group",

      "CUSTOMERS-COPY-TITLE": "Copy Customers",
      "ALERT-CUSTOMERS-COPY": "Customers has been copied",
      "ALERT-CUSTOMERS-COPY-ERROR": "Failed to copy the Customers",

      "EXCHANGES-COPY-TITLE": "Copy Exhanges",
      "ALERT-EXCHANGES-COPY": "Exhanges has been copied",
      "ALERT-EXCHANGES-COPY-ERROR": "Failed to copy the Exhanges",

      "CONTENTS-COPY-TITLE": "Copy Content",
      "ALERT-CONTENTS-COPY": "Content has been copied",
      "ALERT-CONTENTS-COPY-ERROR": "Failed to copy the Content",

      "REASON-FOR-EXCHANGE-COPY-TITLE": "Copy Reason for exchange",
      "ALERT-REASON-FOR-EXCHANGE-COPY": "Reason for exchange has been copied",
      "ALERT-REASON-FOR-EXCHANGE-COPY-ERROR": "Failed to copy the Reason for exchange",

      "REASON-FOR-REFUSAL-COPY-TITLE": "Copy Reason for refusal",
      "ALERT-REASON-FOR-REFUSAL-COPY": "Reason for refusal has been copied",
      "ALERT-REASON-FOR-REFUSAL-COPY-ERROR": "Failed to copy the Reason for refusal",

      "USER-COPY-TITLE": "Copy User",
      "ALERT-USER-COPY": "User has been copied",
      "ALERT-USER-COPY-ERROR": "Failed to copy the User",

      "VERIFY-IDENTITY-TITLE": "Verify Identity",
      "VERIFY-IDENTITY-DESCRIPTION-1": "Before you can login we need to verify your identity.",
      "VERIFY-IDENTITY-DESCRIPTION-2": " After clicking on the submit button please check your email to validate your identity. ",
      "VERIFY-IDENTITY-DESCRIPTION-3": "Once you verify your identity, this is the email you will use to login.",
      "VERIFY-IDENTITY-DESCRIPTION-4": "Please provide your email.",
      "SUBMIT-BUTTON": "Submit",

      "BULK-BRAND-SECOND-TITLE": "Bulk update: Brand",
      "BULK-DESCRIPTION-1": "Select the fields to update",
      "BULK-DESCRIPTION-2": "Enter the value of each selected field",
      "BULK-DESCRIPTION-3": "Save",
      "BULK-UPDATE-TITLE": "Update",
      "BULK-VALUE-TITLE": "Value",
      "TABLE.HEADER-OPTIONS-LIST-BULK": "Bulk update",
      "ALERT-NOT-EMAIL": "Please use an email",

      "LANGUAGE-LIST-FRENCH": "French",
      "LANGUAGE-LIST-ENGLISH": "English",
    },
  },
  fr: {
    translation: {
      //Global
      "HOVER.ALL.BUTTON-SEARCH": "Rechercher",
      "HOVER.ALL.MENU-BARS-RELOAD": "Recharger",
      "HOVER.ALL.MENU-BARS-COPY": "Copier le lien",
      "HOVER.ALL.MENU-BARS-DELETE": "Supprimer",
      "HOVER.ALL.MENU-BARS-SELECT": "Sélectionner",
      "HOVER.ALL.MENU-BARS-OPEN": "Ouvrir",
      "HOVER.ALL.MENU-BARS-CREATE": "Créer",
      "LIST.ALL.BUTTON-CREATE": "Créer",
      "LIST.ALL.BUTTON-SELECT": "Sélectionner",
      "CREATE.ALL.BUTTON-SAVE": "Enregistrer",
      "CREATE.ALL.BUTTON-SAVE-MENU": "Enregistrer & nouveau",
      "CREATE.ALL.BUTTON-SAVE-CLOSE": "Enregistrer & Fermer",
      "CREATE.ALL.BUTTON-CLOSE": "Fermer",
      "COMMENTS.ALL.BUTTON-SHARE": "Partager",
      "SEARCH.ALL.BUTTON-SEARCH": "Rechercher",
      "SEARCH.ALL.BUTTON-RESET": "Effacer",
      "SEARCH.ALL.BUTTON-CLOSE": "Fermer",
      "SEARCH.ALL.BUTTON-SORT-ORDER": "Ordre de tri",
      "LOGIN.ALL.USER": "Utilisateur",
      "LOGIN.ALL.PASSWORD": "Mot de passe",
      "LOGIN.ALL.FORGOT-PASSWORD": "J'ai oublié mon mot de passe",
      "HOME.MAIN-NAV.QUIT": "Quitter",
      "HOME.MAIN-NAV.SHARE-POSTS": "Messages partagés",
      "HOME.MAIN-NAV.FRONTEND": "Frontend",
      "HOME.MAIN-NAV.CVS-IMPORT": "CVS Import",
      "HOME.MAIN-NAV.ABOUT": "A propos",
      "HOME.MENU.CATALOG": "Catalogue",
      "HOME.MENU.BRANDS": "Marques",
      "HOME.MENU.PRODUCTS": "Produits",
      "HOME.MENU.WARRANTY": "Garantie",
      "HOME.MENU.GROUP": "Groupe",
      "HOME.MENU.DISTRIBUTOR": "Distributeur",
      "HOME.MENU.DISTRIBUTOR-SETS": "Ensembles distributeurs",
      "HOME.MENU.DISTRIBUTOR-SETS-CREATE": "Créer Ensemble distributeurs",
      "HOME.MENU.CUSTOMERS": "Clients",
      "HOME.MENU.EXCHANGES": "Echanges",
      "HOME.MENU.EXCHANGES-SHOW-ALL": "Tout voir",
      "HOME.MENU.EXCHANGES-DRAFT": "Brouillon",
      "HOME.MENU.EXCHANGES-ABANDONNED": "Abandonné",
      "HOME.MENU.EXCHANGES-SUBMITTED": "Soumis",
      "HOME.MENU.EXCHANGES-ANALYSIS": "Analyse",
      "HOME.MENU.EXCHANGES-ACCEPTED": "Accepté",
      "HOME.MENU.EXCHANGES-AVAILABLE": "Disponible",
      "HOME.MENU.EXCHANGES-EXCHANGED": "Echangé",
      "HOME.MENU.EXCHANGES-REFUSED": "Refusé",
      "HOME.MENU.EXCHANGES-RECYCLED": "Recyclé",
      "HOME.MENU.CONTACTS": "Contacts",
      "HOME.MENU.OTHERS": "Autres",
      "HOME.MENU.CONTENTS": "Contenus",
      "HOME.MENU.REASON-FOR-EXCHANGE": "Raison de l'échange",
      "HOME.MENU.REFUSAL-REASONS": "Raisons du refus",
      "HOME.MENU.CHANGE-LOG": "Journal des modifications",
      "HOME.MENU.USERS": "Utilisateurs",
      "HOME.MENU.USERS-SHOW-ALL": "Tout voir",
      "HOME.MENU.USERS-DISABLED": "Inactif",
      "HOME.MENU.USERS-ENABLED": "Actif",
      "HOME.MENU.USERS-PENDING": "En attente",
      "HOME.MENU.USERS-WEB-SERVICES": "Web services seulement",
      "ALL.MENU.DELETE": "Supprimer",
      "ALL.MENU.RELOAD": "Recharger",
      "ALL.MENU.COPY-LINK": "Copier le lien",
      "ALL.MENU.COPY": "Copier",
      "ALL-INFO.CONSOLE-LOG-TABLE": "Journal des modifications",
      "ALL-INFO.EXCHANGES-TABLE": "Exchanges",
      "ALL-INFO.DELETE-CONFIRM": "Etes-vous sûr de vouloir supprimer tous les éléments sélectionnés ?",
      "ALL-BUTTON-COPY-ITEM": "Copier",
      "ALL-BUTTON-PREFERENCES": "Préférences",

      // CATALOG.BRANDS
      "TABLE.BRAND.TITLE.HEADER-LINK": "Marque",
      "TABLE.BRAND.TITLE.HEADER-SUB-LINK": "Créer Marque",
      "TABLE.BRAND.TITLE-HEADER": "Marques",
      "TABLE.BRAND.HEADER-TOTAL": "Total",
      "TABLE.BRAND.HEADER-BUTTON-CREATE": "Créer",
      "TABLE.BRAND.HEADER-BUTTON-UPDATE-THE-LIST": "Modifier la liste",
      "TABLE.BRAND.HEADER-OPTIONS-LIST-RELOAD": "Recharger",
      "TABLE.BRAND.HEADER-OPTIONS-LIST-DELETE": "Supprimer",
      "TABLE.BRAND.HEADER-OPTIONS-LIST-COPY-LINK": "Copier le lien",
      "TABLE.BRAND-HEADER-TITLE-IDENTIFICATION": "Identification",
      "TABLE.BRAND-HEADER-TITLE-LOGO": "Logo",
      "TABLE.BRAND.TITLE-CODE-ON-LIST": "Code",
      "TABLE.BRAND.TITLE-NAME": "Nom",
      "TABLE.BRAND.TITLE-WEBSITE": "Site web",
      "TABLE.BRAND.TITLE-LOGO": "Logo",
      "SEARCH.BRAND.TITLE-SEARCH-BRANDS": "Rechercher Marques",
      "SEARCH.BRAND.DEFINITION": "Definition",
      "SEARCH.BRAND.CODE": "Code",
      "SEARCH.BRAND.NAME": "Nom",
      "SEARCH.BRAND.WEBSITE": "Site Web",
      "SEARCH.BRAND.SEARCH": "Rechercher",
      "SEARCH.BRAND.RESET": "Effacer",
      "SEARCH.BRAND.CLOSE": "Fermer",
      "SEARCH.BRAND.SORT-ORDER": "Ordre de tri",
      "SEARCH.BRAND.APPLY": "Appliquer",
      "CREATE.BRAND.CARD-HEADER-TITLE-IDENTIFICATION": "Identification",
      "CREATE.BRAND.INPUT-CODE": "Code",
      "CREATE.BRAND.INPUT-NAME": "Nom",
      "CREATE.BRAND.INPUT-WEBSITE": "Site web",
      "CREATE.BRAND.INPUT-DESCRIPTION": "Description",
      "CREATE.BRAND.CARD-HEADER-TITLE-LOGO": "Logo",
      "CREATE.BRAND.INPUT-LOGO": "Logo",
      "INFO.BRAND.PRODUCT-TABLE": "Produits du fournisseur",
      "INFO.BRAND.CONSOLE-LOG": "Journal des modifications",

      //Product
      "TABLE.PRODUCT.TITLE.HEADER-LINK": "Produits",
      "TABLE.PRODUCT.TITLE-HEADER": "Produits",
      "TABLE.PRODUCT.HEADER-TOTAL": "Total",
      "ALL.GENERAL.PRODUCT-HEADER-TITLE-BRAND": "Marque",
      "ALL.GENERAL.PRODUCT-HEADER-TITLE-IDENTIFICATION": "Identification",
      "ALL.GENERAL.PRODUCT-HEADER-TITLE-STOCK-AND-PRICING": "Prix et garantie",
      "TABLE.PRODUCT.TITLE-CODE-ON-LIST": "Code marque",
      "ALL.GENERAL.PRODUCT.TITLE-BRAND-NAME": "Nom marque",
      "ALL.GENERAL.PRODUCT.TITLE-REFERENCE": "Référence",
      "ALL.GENERAL.PRODUCT.TITLE-NAME": "Nom",
      "ALL.GENERAL.PRODUCT.TITLE-TYPE": "Type",
      "ALL.GENERAL.PRODUCT.TITLE-PICTURE": "Photo",
      "ALL.GENERAL.PRODUCT.TITLE-UNIT-PRICE": "Prix unitaire",
      "ALL.GENERAL.PRODUCT.TITLE-AVAILABLE": "Disponible",
      "ALL.GENERAL.PRODUCT.TITLE-WARRANTY": "Type de garantie",
      "CREATE.PRODUCT.HEADER-SECOND-LINK": "Créer Produit",
      "CREATE.PRODUCT.TITLE-HEADER": "Créer Produit",
      "CREATE.PRODUCT.INPUT-BRAND-CODE": "Code marque",
      "CREATE.PRODUCT.INPUT-OLD-REFERENCE": "Ancienne référence",
      "CREATE.PRODUCT.INPUT-DEFAULT": "Par défaut",
      "CREATE.PRODUCT.CARD-HEADER-TITLE-MARKETING": "Détails",
      "CREATE.PRODUCT.INPUT-DESCRIPTION": "Description",
      "CREATE.PRODUCT.INPUT-BROCHURE": "Brochure",
      "CREATE.PRODUCT.INPUT-ONLINE-DOC": "Documentation en ligne",
      "CREATE.PRODUCT.INPUT-EAN": "EAN",
      "CREATE.PRODUCT.INPUT-EAN-IMAGE": "EAN code barre",
      "SEARCH.PRODUCT.TITLE-SEARCH-PRODUCTS": "Rechercher Produits",
      "SEARCH.PRODUCT.BRAND": "Marque",
      "SEARCH.PRODUCT.INPUT-BRAND": "Marque",
      "SEARCH.PRODUCT.INPUT-BRAND-CODE": "Code marque",
      "SEARCH.BRAND.INPUT-OLD-REFERENCE": "Ancienne référence",
      "SEARCH.PRODUCT.INPUT-DEFAULT": "Par défaut",
      "SEARCH.PRODUCT.INPUT-BROCHURE": "Brochure",
      "SEARCH.PRODUCT.INPUT-EAN": "Ean",
      "SORT.PRODUCT.INPUT-CODE-BRAND": "Code de la marque",
      "SORT.PRODUCT.INPUT-NAME-BRAND": "Marque",
      "SORT.PRODUCT.INPUT-REFERENCE": "Référence",
      "SORT.PRODUCT.INPUT-OLD-REFERENCE": "Ancienne référence",
      "SORT.PRODUCT.INPUT-NAME": "Nom",
      "SORT.PRODUCT.INPUT-TYPE": "Taper",
      "SORT.PRODUCT.INPUT-UNIT-PRICE": "Prix ​​unitaire",
      "SORT.PRODUCT.INPUT-AVAILABLE": "Disponible",
      "SORT.PRODUCT.INPUT-WARRANTY": "Garantie",

      // Group

      "TABLE.GROUP.TITLE.HEADER-LINK": "Groupe",
      "TABLE.GROUP.TITLE-HEADER": "Groupe",
      "TABLE.GROUP.HEADER-TOTAL": "Total",
      "ALL.GENERAL.TABLE.GROUP.TITLE-CODE": "Code",
      "ALL.GENERAL.TABLE.GROUP.TITLE-NAME": "Nom",
      "ALL.GENERAL.TABLE.GROUP.TITLE-LOGO": "Logo",
      "CREATE.GROUP.HEADER-SECOND-LINK": "Créer Groupe",
      "CREATE.GROUP.TITLE-HEADER": "Créer Groupe",
      "CREATE.GROUP.INPUT-DESCRIPTION": "Description",
      "SEARCH.GROUP.TITLE-SEARCH-GROUP": "Rechercher Groupe",
      "SEARCH.GROUP.TITLE-SEARCH-DISTRIBUTOR-SET": "Rechercher Ensembles distributeurs",
      "SEARCH.GROUP.DEFINITION": "Définition",
      "SORT.GROUP.INPUT-CODE": "Code",
      "SORT.GROUP.INPUT-NAME": "Nom",

      //Distrubutor ser
      "ALL.GENERAL.TABLE.DIST-SET.TITLE-CODE": "Code Distributor group",
      "ALL.GENERAL.TABLE.DIST-SET.TITLE-NAME-GROUP": "Nom Distributor group",
      "ALL.GENERAL.TABLE.DIST-SET.TITLE-NAME": "Nom",
      "ALL.GENERAL.TABLE.DIST-SET.TITLE-ORIGIN": "Origine",
      "ALL.GENERAL.TABLE.DIST-SET.TITLE-MEMBERS": "Membres",
      "ALL.GENERAL.TABLE.DIST-SET.TITLE-DESCRIPTION": "Description",
      "DISTRIBUTOR-SET-ALERT-1": "Un ensemble associé à une origine permet de restreindre l'accès aux distributeurs depuis cette origine.",
      "DISTRIBUTOR-SET-ALERT-2": "Sinon, si aucun ensemble ne correspond à l'origine, il n'y a pas de restriction.",

      "ALERT-SUCCESS-CREATE": "Des ensembles de distribution ont été créés",
      "ALERT-SUCCESS-UPDATE": "Les ensembles de distribution ont été mis à jour",
      "ALERT-ERROR-CREATE": "Erreur lors de la création des ensembles de distributeurs",
      "ALERT-ERROR-UPDATE": "Erreur lors de la mise à jour des ensembles de distribution",
      "ALERT-ERROR-GET-DS": "Erreur lors de la récupération des ensembles de distribution",
      "ALERT-ERROR-DIFFERENT-GROUP1": "Groupes distributeurs incohérents",
      "ALERT-ERROR-DIFFERENT-GROUP2": "Champ obligatoire: ",

      //Distributor

      "TABLE.DISTRIBUTOR.TITLE.HEADER-LINK": "Distributeur",
      "TABLE.DISTRIBUTOR.TITLE-HEADER": "Distributeur",
      "TABLE.DISTRIBUTOR.HEADER-TOTAL": "Total",
      "ALL.GENERAL.DISTRIBUTOR-HEADER-TITLE-IDENTIFICATION": "Identification",
      "ALL.GENERAL.DISTRIBUTOR.TITLE-GROUP-CODE": "Code du groupe",
      "ALL.GENERAL.DISTRIBUTOR.TITLE-CODE": "Code",
      "ALL.GENERAL.DISTRIBUTOR.TITLE-CLIENT-ACCOUNT-NUMBER": "Numéro compte client",
      "ALL.GENERAL.DISTRIBUTOR.TITLE-DELIVERY-ACCOUNT-NUMBER": "Numéro compte livraison",
      "ALL.GENERAL.DISTRIBUTOR.TITLE-NAME": "Nom",
      "ALL.GENERAL.DISTRIBUTOR.TITLE-VISIBLE": "Visible",
      "ALL.GENERAL.DISTRIBUTOR.TITLE-LOGO": "Logo",
      "ALL.GENERAL.DISTRIBUTOR.TITLE-POSTAL-CODE": "Code postal",
      "ALL.GENERAL.DISTRIBUTOR.TITLE-CITY": "Ville",
      "ALL.GENERAL.DISTRIBUTOR.TITLE-PHONE": "Téléphone",
      "ALL.GENERAL.DISTRIBUTOR.TITLE-EMAIL": "Adresse e-mail du distributeur",
      "ALL.GENERAL.DISTRIBUTOR.TITLE-RESPONSIBLE-EMAIL": "Adresse e-mail du responsable FACOM",
      "ALL.GENERAL.DISTRIBUTOR.TITLE-EVALUATION": "Evaluation",
      "CREATE.DISTRIBUTOR.INPUT-ADDRESS": "Adresse",
      "CREATE.DISTRIBUTOR.INPUT-POSTAL-CODE": "Code postal",
      "CREATE.DISTRIBUTOR.INPUT-OPENING-HOURS": "Horaires d'ouverture",
      "CREATE.DISTRIBUTOR.INPUT-GEOGRAPHICAL-COORDINATES": "Coordonnées géographiques",
      "CREATE.DISTRIBUTOR.INPUT-MAP-URL": "URL carte",
      "SEARCH.DISTRIBUTOR.TITLE-SEARCH-DISTRIBUTOR": "Rechercher Distributeur",
      "SEARCH.DISTRIBUTOR.TITLE-IDENTIFICATION": "Identification",
      "SEARCH.DISTRIBUTOR.INPUT-GROUP": "Groupe",
      "SEARCH.DISTRIBUTOR.INPUT-GROUP-NAME": "Nom du groupe",
      "SEARCH.DISTRIBUTOR.INPUT-ADDRESS": "Adresse",
      "SEARCH.DISTRIBUTOR.INPUT-GEOGRAPHICAL-COORDINATES": "Coordonnées géographiques",
      "SEARCH.DISTRIBUTOR.INPUT-MAP-URL": "URL carte",
      "SEARCH.DISTRIBUTOR.INPUT-LOOKUP": "Lookup",
      "CREATE.DISTRIBUTOR.HEADER-SECOND-LINK": "Créer distributeur",
      "BREADCRUMB-DISTRIBUTOR-MAP": "Carte Distributeur",
      "DISTRIBUTOR-MAP-TITLE": "Carte",
      // Customer

      "TABLE.CUSTOMER.TITLE.HEADER-LINK": "Client",
      "TABLE.CUSTOMER.TITLE-HEADER": "Clients",
      "TABLE.CUSTOMER.HEADER-TOTAL": "Total",
      "TABLE.CUSTOMER.HEADER-BUTTON-GENERATE-A-NEW-PASSWORD": "Generer un mot de passe",
      "ALL.GENERAL.CUSTOMER-HEADER-TITLE-IDENTIFICATION": "Identification",
      "ALL.GENERAL.CUSTOMER-HEADER-TITLE-ADDRESS": "Adresse",
      "ALL.GENERAL.CUSTOMER-HEADER-TITLE-MEANS-OF-CONTACT": "Moyens de contact",
      "ALL.GENERAL.CUSTOMER.TITLE-USERNAME": "Nom d'utilisateur",
      "ALL.GENERAL.CUSTOMER.TITLE-FIRST-NAME": "Prénom",
      "ALL.GENERAL.CUSTOMER.TITLE-LAST-NAME": "Nom",
      "ALL.GENERAL.CUSTOMER.TITLE-TYPE": "Type",
      "ALL.GENERAL.CUSTOMER.TITLE-BUSINESS": "Métier",
      "ALL.GENERAL.CUSTOMER.TITLE-SIRET-CODE": "Nom de l'entreprise",
      "ALL.GENERAL.CUSTOMER.TITLE-ZIP-CODE": "Code postal",
      "ALL.GENERAL.CUSTOMER.TITLE-CITY": "Ville",
      "ALL.GENERAL.CUSTOMER.TITLE-COUNTRY": "Pays",
      "ALL.GENERAL.CUSTOMER.TITLE-EMAIL": "Email",
      "ALL.GENERAL.CUSTOMER.TITLE-MOBILE-PHONE-NUMBER": "Téléphone mobile",
      "CREATE.CUSTOMER.HEADER-SECOND-LINK": "Client",
      "CREATE.CUSTOMER.TITLE-HEADER": "Créer Client",
      "CREATE.CUSTOMER.NAV-HEADER-TITLE-OTHERS": "Autres",
      "CREATE.CUSTOMER.NAV-INPUT-ADDRESS-COMPLEMENT": "Complément d'adresse",
      "CREATE.CUSTOMER.NAV-INPUT-WORK-PHONE-NUMBER": "Téléphone travail",
      "CREATE.CUSTOMER.NAV-INPUT-HOME-PHONE-NUMBER": "Téléphone domicile",
      "CREATE.CUSTOMER.NAV-INPUT-FAX-NUMBER": "Fax",
      "CREATE.CUSTOMER.NAV-INPUT-GENERAL-TERMS-ACCEPTED": "CGU acceptées",
      "CREATE.CUSTOMER.NAV-INPUT-PRIVACY-POLICY-ACCEPTED": "Politique vie privée acceptée",
      "CREATE.CUSTOMER.NAV-INPUT-REGISTRATION-DATE": "Date d'inscription",
      "CREATE.CUSTOMER.NAV-INPUT-COMMENTS": "Commentaires",
      "CREATE.CUSTOMER.NAV-INPUT-COORDINATES": "Coordonnées",
      "CREATE.CUSTOMER.NAV-INPUT-MAP-URL": "URL carte",
      "CREATE.CUSTOMER.CARD-INPUT-PASSWORD": "Mot de passe",
      "SEARCH.CUSTOMER.TITLE-SEARCH-CUSTOMERS": "Rechercher Clients",
      "SEARCH.CUSTOMER.TITLE-IDENTIFICATION": "Identification",
      "SEARCH.CUSTOMER.TITLE-ADDRESS": "Adresse",
      "SEARCH.CUSTOMER.TITLE-OTHERS": "Autres",
      "SEARCH.CUSTOMER.INPUT-MAP-URL": "URL carte",
      "SEARCH.CUSTOMER.INPUT-GENERAL-TERMS-ACCEPTED": "CGU acceptées",
      "SEARCH.CUSTOMER.INPUT-PRIVACY-POLICY-ACCEPTED": "Politique vie privée acceptée (RGPD)",
      "SEARCH.CUSTOMER.INPUT-REGISTRATION-DATE": "Date d'inscription",
      "MODAL.CUSTOMER-TITLE": "Confirmer : Generer un mot de passe",
      "MODAL.CUSTOMER-INPUT-EMAIL-ADDRESS": "Adresse email",
      "MODAL.CUSTOMER-BUTTON-CONFIRM": "Confirmer",
      "MODAL.CUSTOMER-BUTTON-CANCEL": "Annuler",
      "MODAL.CUSTOMER-USER": "Utilisateurs",
      "MODAL.CUSTOMER-EXCHANGES": "Echanges du client",
      "MODAL.CUSTOMER-CHANGELOG": " Journal des modifications",

      "SORT.CUSTOMER.INPUT-USERNAME": "Nom d'utilisateur",
      "SORT.CUSTOMER.INPUT-FIRST-NAME": "Prénom",
      "SORT.CUSTOMER.INPUT-LAST-NAME": "Nom",
      "SORT.CUSTOMER.INPUT-TYPE": "Type",
      "SORT.CUSTOMER.INPUT-BUSINESS": "Métier",
      "SORT.CUSTOMER.INPUT-COMPANY-NAME": "Nom de l'entreprise",
      "SORT.CUSTOMER.INPUT-ZIPCODE": "Code postal",
      "SORT.CUSTOMER.INPUT-CITY": "Ville",
      "SORT.CUSTOMER.INPUT-COUNTRY": "Pays",
      "SORT.CUSTOMER.INPUT-EMAIL": "E-mail",
      "SORT.CUSTOMER.INPUT-MOBILE-PHONE": "Téléphone mobile",

      // Exchanges

      "TABLE.EXCHANGE.HEADER-LINK": "Echange",
      "TABLE. EXCHANGE.TITLE-HEADER": "Echanges",
      "TABLE.EXCHANGE.HEADER-TOTAL": "Total",
      "TABLE.EXCHANGE.HEADER-TITLE-IDENTIFICATION-AND-STATUS": "	Identification et état",
      "SEARCH.EXCHANGES.INPUT-FROM": "Depuis",
      "SEARCH.EXCHANGES.INPUT-TO": "Jusqu'à",
      "SEARCH.EXCHANGES.INPUT-FILTER-DRAFT": "Brouillon",
      "SEARCH.EXCHANGES.INPUT-FILTER-ABANDONNED": "Soumis",
      "SEARCH.EXCHANGES.INPUT-FILTER-SUBMITTED": "Submitted",
      "SEARCH.EXCHANGES.INPUT-FILTER-ANALYSIS": "Analyse",
      "SEARCH.EXCHANGES.INPUT-FILTER-ACCEPTED": "Accepté",
      "SEARCH.EXCHANGES.INPUT-FILTER-AVAILABLE": "Disponible",
      "SEARCH.EXCHANGES.INPUT-FILTER-EXCHANGED": "Echangé",
      "SEARCH.EXCHANGES.INPUT-FILTER-REFUSED": "Refusé",
      "SEARCH.EXCHANGES.INPUT-FILTER-RECYCLED": "Recyclé",
      "TABLE.EXCHANGE.HEADER-TITLE-CUSTOMER": "Client",
      "TABLE.EXCHANGE.HEADER-TITLE-PRODUCT": "Produit",
      "TABLE.EXCHANGE.HEADER-TITLE-DISTRIBUTOR": "Distributeur",
      "ALL.GENERAL-EXCHANGE.TITLE-DETAILS": "Echange",
      "ALL.GENERAL-EXCHANGE.TITLE-NUMBER": "Numéro",
      "ALL.GENERAL-EXCHANGE.TITLE-DATE": "Date",
      "ALL.GENERAL-EXCHANGE.TITLE-STATUS": "Etat",
      "ALL.GENERAL-EXCHANGE.TITLE-CUSTOMER-USERNAME": "Nom d'utilisateur client",
      "ALL.GENERAL-EXCHANGE.TITLE-PRODUCT-REFERENCE": "Référence produit",
      "ALL.GENERAL-EXCHANGE.TITLE-BRAND-NAME": "Nom marque",
      "ALL.GENERAL-EXCHANGE.TITLE-GIVEN-REASON": "Raison donnée",
      "TABLE.EXCHANGE.TITLE-EVALUATION": "Evaluation",
      "ALL.GENERAL-EXCHANGE.TITLE-QUANTITY": "Quantity",
      "ALL.GENERAL-EXCHANGE.TITLE-DISTRIBUTOR-CODE": "Code Distributeur",
      "ALL.GENERAL-EXCHANGE.TITLE-DISTRIBUTOR-GROUP-CODE": "Code Groupe Distributeur",
      "ALL.GENERAL-EXCHANGE.TITLE-DISTRIBUTOR-CLIENT-ACCOUNT-NUMBER": "Numéro compte client Distributeur",
      "CREATE.EXCHANGE.HEADER-SECOND-LINK": "Echange",
      "CREATE.EXCHANGE.TITLE-HEADER": "Créer Echange",
      "CREATE.EXCHANGES.TITLE-IDENTIFICATION-AND-STATUS": "Identification et état",
      "CREATE.EXCHANGES.TITLE-CUSTOMER": "Client",
      "CREATE.EXCHANGES.TITLE-DISTRIBUTOR": "Distributeur",
      "CREATE.EXCHANGES.INPUT-CUSTOMER-FIRST-NAME": "Prénom client",
      "CREATE.EXCHANGES.INPUT-CUSTOMER-EMAIL": "Email client",
      "CREATE.EXCHANGES.INPUT-CUSTOMER-LAST-NAME": "Nom client",
      "CREATE.EXCHANGES.INPUT-CUSTOMER-MOBILE-PHONE-NUMBER": "Téléphone mobile Client",
      "CREATE.EXCHANGES.INPUT-CUSTOMER-DISTRIBUTOR-NAME": "Nom Distributeur",
      "CREATE.EXCHANGES.INPUT-CUSTOMER-DISTRIBUTOR-GROUP-NAME": "Nom Groupe Distributeur",
      "CREATE.EXCHANGES.INPUT-CUSTOMER-CLIENT-ACCOUNT-NUMBER": "Numéro compte client Distributeur",
      "CREATE.EXCHANGES.INPUT-CUSTOMER-DISTRIBUTOR-PHONE": "Téléphone Distributeur",
      "CREATE.EXCHANGES.INPUT-CUSTOMER-DISTRIBUTOR-EMAIL": "Email Distributeur",
      "CREATE.EXCHANGES.INPUT-UNIT-PRICE": "Prix unitaire",
      "CREATE.EXCHANGES.INPUT-TOTAL-COST": "Coût total",
      "CREATE.EXCHANGES.INPUT-GEOGRAPHICAL-COORDINATES": "Coordonnées géographiques",
      "CREATE.EXCHANGES.INPUT-PRODUCT-NAME": "Nom produit",
      "CREATE.EXCHANGES.INPUT-BRAND-CODE": "Code marque",
      "CUSTOMER-TABLE-EXCHANGES-INPUT-POSTAL.CODE": "Code postal client",
      "CUSTOMER-TABLE-EXCHANGES-INPUT-CITY": "Ville client",
      "CUSTOMER-TABLE-EXCHANGES-INPUT-COUNTRY": "Pays client",
      "CUSTOMER-TABLE-EXCHANGES-INPUT-LANGUAGE": "Langue Client",

      // "CREATE.EXCHANGES.INPUT-BRAND-NAME": "Nom marque",
      "CREATE.EXCHANGES.TITLE-PRODUCT": "Produit",
      "CREATE.EXCHANGES.INPUT-PRODUCT-PICTURE": "Image du produit",
      "CREATE.EXCHANGES.INPUT-PRODUCT-TYPE": "Type Produit",
      "CREATE.EXCHANGES.INPUT-CURRENT-UNIT-PRICE": "Prix unitaire courant",
      "CREATE.EXCHANGES.INPUT-DETAILS-COMMENTS": "Commentaires",
      "CREATE.EXCHANGES.INPUT-PICTURE-ONE": "Photo 1",
      "CREATE.EXCHANGES.INPUT-PICTURE-TWO": "Photo 2",
      "CREATE.EXCHANGES.INPUT-PICTURE-THREE": "Photo 3",
      "CREATE.EXCHANGES.INPUT-PICTURE-FOUR": "Photo 4",
      "CREATE.EXCHANGES.INPUT-QR-CODE": "QR code",
      "CREATE.EXCHANGES.INPUT-CODE-FOR-DIRECT-EVALUATION": "Code pour évaluation directe",

      "SEARCH.EXCHANGES.TITLE-SEARCH-EXCHANGES": "Rechercher Echanges",
      "SEARCH.EXCHANGES.TITLE-IDENTIFICATION-AND-STATUS": "Identification et état",
      "SEARCH.EXCHANGES.INPUT-REFUSAL-REASON": "Raison du refus",
      "SEARCH.EXCHANGES.TITLE-CUSTOMER": "Client",
      "SEARCH.EXCHANGES.INPUT-CUSTOMER": "Client",
      "SEARCH.EXCHANGES.INPUT-CUSTOMER-FIRST-NAME": "Prénom client",
      "SEARCH.EXCHANGES.INPUT-CUSTOMER-LAST-NAME": "Nom client",
      "SEARCH.EXCHANGES.TITLE-PRODUCT": "Produit",
      "SEARCH.EXCHANGES.INPUT-PRODUCT-REFERENCE": "Référence produit",
      "SEARCH.EXCHANGES.INPUT-PRODUCT-NAME": "Nom produit",
      "SEARCH.EXCHANGES.INPUT-PRODUCT-TYPE": "Type Produit",
      "SEARCH.EXCHANGES.INPUT-BRAND-CODE": "Code marque",
      "SEARCH.EXCHANGES.INPUT-EVALUATION": "Evaluation",
      "SEARCH.EXCHANGES.INPUT-COMMENT": "Commentaires",
      "SEARCH.EXCHANGES.INPUT-MIN": "Min",
      "SEARCH.EXCHANGES.INPUT-MAX": "Max",
      "SEARCH.EXCHANGES.INPUT-TOTAL-COST": "Coût total",
      "SEARCH.EXCHANGES.INPUT-GEOGRAPHICAL-COORDINATES": "Coordonnées géographiques",
      "SEARCH.EXCHANGES.INPUT-CODE-FOR-DIRECT-EVALUATION": "Code pour évaluation directe",
      "SEARCH.EXCHANGES.TITLE-DISTRIBUTOR": "Distributeur",
      "SEARCH.EXCHANGES.INPUT-DISTRIBUTOR": "Distributeur",
      "SEARCH.EXCHANGES.INPUT-DISTRIBUTOR-NAME": "Nom Distributeur",
      "SEARCH.EXCHANGES.INPUT-DISTRIBUTOR-GROUP-NAME": "Nom Groupe Distributeur",

      //Reason for exchanges
      "TABLE.REASON-EXCHANGE.HEADER-LINK": "Raison de l'échange",
      "TABLE.REASON-EXCHANGE.TITLE-HEADER": "Raison de l'échange",
      "TABLE.REASON-EXCHANGE.HEADER-TOTAL": "Total",
      "ALL.GENERAL-REASON-EXCHANGE.TITLE-CODE": "Code",
      "ALL.GENERAL-REASON-EXCHANGE.TITLE-LANGUAJE": "Langue",
      "ALL.GENERAL-REASON-EXCHANGE.TITLE-DISPLAY-ORDER": "Ordre d'affichage",
      "ALL.GENERAL-REASON-EXCHANGE.TITLE-ELIGIBLE": "Eligible à l'échange",
      "ALL.GENERAL-REASON-EXCHANGE.TITLE-LABEL": "Libellé",
      "ALL.GENERAL-REASON-EXCHANGE.TITLE-DESCRIPTION": "Description",
      "CREATE.REASON-EXCHANGE.HEADER-LINK": "Raison de l'échange",
      "CREATE.REASON-EXCHANGE.HEADER-SECOND-LINK": "Créer Raison de l'échange",
      "CREATE.REASON-EXCHANGE.TITLE-HEADER": "Créer Raison de l'échange",
      "SEARCH.REASON-EXCHANGE.TITLE-HEADER": "Rechercher Raison de l'échange",
      "SEARCH.REASON-EXCHANGE.TITLE-BOX-DEFINITION": "Définition",

      //Refusal reason
      "TABLE.REFUSAL-REASON.HEADER-LINK": "Raisons du refus",
      "TABLE.REFUSAL-REASON.TITLE-HEADER": "Raisons du refus",
      "TABLE.REFUSAL-REASON.HEADER-TOTAL": "Total",
      "ALL.GENERAL-REFUSAL-REASON.TITLE-CODE": "Code",
      "ALL.GENERAL-REFUSAL-REASON.TITLE-LANGUAJE": "Langue",
      "ALL.GENERAL-REFUSAL-REASON.TITLE-DISPLAY-ORDER": "Ordre d'affichage",
      "ALL.GENERAL-REFUSAL-REASON.TITLE-LABEL": "Label",
      "ALL.GENERAL-REFUSAL-REASON.TITLE-DESCRIPTION": "fac rre description",
      "CREATE.REFUSAL-REASON.HEADER-LINK": "Raisons du refus",
      "CREATE.REFUSAL-REASON.HEADER-SECOND-LINK": "Créer Raison du refus",
      "CREATE.REFUSAL-REASON.TITLE-HEADER": "Créer Raison du refus",
      "SEARCH.REFUSAL-REASON.TITLE": "Rechercher Raisons du refus",
      "SEARCH.REASON-EXCHANGE.TITLE-BOX": "Définition",

      //Change log
      "TABLE.CHANGE-LOG.HEADER-LINK": "Journal des modifications",
      "TABLE.CHANGE-LOG.TITLE-HEADER": "Journal des modifications",
      "TABLE.CHANGE-LOG.HEADER-TOTAL": "Total",
      "TABLE.CHANGE-LOG.HEADER-TITLE-USER": "Session utilisateur",
      "TABLE.CHANGE-LOG.HEADER-TITLE-ACTION": "Action",
      "ALL.GENERAL-CHANGE-LOG.TITLE-SESSION": "Index Session",
      "ALL.GENERAL-CHANGE-LOG.TITLE-LOGIN": "Login de session",
      "ALL.GENERAL-CHANGE-LOG.TITLE-USER": "Utilisateur",
      "ALL.GENERAL-CHANGE-LOG.TITLE-LAST-NAME": "Nom",
      "ALL.GENERAL-CHANGE-LOG.TITLE-DATE": "Date",
      "ALL.GENERAL-CHANGE-LOG.TITLE-PRIMARY": "Login principal",
      "ALL.GENERAL-CHANGE-LOG.TITLE-OBJECT": "Object",
      "ALL.GENERAL-CHANGE-LOG.TITLE-ACTION": "Action",
      "ALL.GENERAL-CHANGE-LOG.TITLE-ACTIVITY": "Activité",
      "INFO.CHANGE-LOG.TITLE-HEADER": "Journal des modifications",
      "SEARCH-INFO.CHANGE-LOG.INPUT-SESSION": "Date de session",
      "SEARCH.CHANGE-LOG.INPUT-INDEX": "Index",
      "SEARCH.CHANGE-LOG.TITLE-HEADER": "Rechercher Journal des modifications",
      "SEARCH.REASON-EXCHANGE.TITLE-BOX-USER": "Session utilisateur",
      "SEARCH.REASON-EXCHANGE.TITLE-BOX-ACTION": "Action",

      //Users
      "TABLE.USER.HEADER-LINK": "Utilisateurs",
      "TABLE. USER.TITLE-HEADER": "Utilisateurs",
      "TABLE.USER.HEADER-": "Total",
      "TABLE. USER.HEADER-TITLE-PICTURE": "Photo",
      "TABLE. USER.HEADER-TITLE-CONTACT": "Coordonnées",
      "TABLE. USER.HEADER-TITLE-PROPERTIES": "Préférences",
      "TABLE. USER.HEADER-TITLE-DISTRIBUTOR": "Distributeur Facom",
      "TABLE. USER.HEADER-TITLE-GROUP": "Groupe distributeur Facom",
      "ALL.GENERAL-USER.TITLE-PICTURE": "Photo",
      "ALL.GENERAL-USER.TITLE-LOGIN": "Nom d'utilisateur Client",
      "ALL.GENERAL-USER.TITLE-LANGUAJE": "Preferred languaje",
      "TABLE.USER.TITLE-LANGUAJE": "Langue",
      "ALL.GENERAL-USER.TITLE-ACTIVE": "Actif",
      "TABLE.USER.TITLE-HOME-PAGE": "Page d'accueil",
      "TABLE.USER.TITLE-MODULE-NAME": "Nom Module",
      "TABLE.USER.TITLE-LAST-LOGON": "Dernière connexion",
      "ALL.GENERAL-USER.TITLE-NAME": "Nom",
      "ALL.GENERAL-USER.TITLE-FIRST-NAME": "Prénom",
      "ALL.GENERAL-USER.TITLE-LAST-NAME": "Nom",
      "ALL.GENERAL-USER.TITLE-EMAIL": "Email",
      "ALL.GENERAL-USER.TITLE-WORK-PHONE": "Tél. bureau",
      "ALL.GENERAL-USER.TITLE-HOME-PHONE": "Tél. domicile",
      "ALL.GENERAL-USER.TITLE-MOBILE-PHONE": "Tél. portable",
      "ALL.GENERAL-USER.TITLE-DISTRIBUTOR-CODE": "Code Distributeur",
      "ALL.GENERAL-USER.TITLE-DISTRIBUTOR-NAME": "Nom Distributeur",
      "ALL.GENERAL-USER.TITLE-GROUP-CODE": "Code Groupe",
      "ALL.GENERAL-USER.TITLE-GROUP-NAME": "Nom Groupe",
      "INFO. USER.TITLE-HEADER": "Utilisateurs",
      "INFO. USER.SUB-TITLE-HEADER": "Utilisateurs",
      "INFO. USER.BUTTON.RESET-PASSWORD": "Réinitialiser le mot de passe",
      "INFO. USER.BUTTON.DISABLED": "Inactif",
      "INFO. USER.BUTTON.ENABLED": "actif",
      "INFO-CREATE.USER-INPUT-USER-TYPE": "Rôle d'utilisateur",
      "INFO-CREATE.USER-INPUT-STATUS": "Statut",
      "INFO-CREATE.USER-INPUT-ACTIVE-LANGUAJE": "Langue active",
      "INFO-CREATE.USER-INPUT-VIEW": "Page d'accueil",
      "INFO-CREATE.USER-INPUT-TITLE": "Titre",
      "INFO-CREATE.USER-INPUT-ADDRESS": "Adresse",
      "INFO-CREATE.USER-INPUT-ADDRESS-COMPLEMENT": "Complément",
      "INFO-CREATE.USER-INPUT-ZIPCODE": "Code postal",
      "INFO-CREATE.USER-INPUT-": "Ville",
      "INFO-CREATE.USER-INPUT-STATE": "Etat / Région",
      "INFO-CREATE.USER-INPUT-COUNTRY": "Pays",
      "INFO-CREATE.USER-CUSTOMER-BOX.TITLE": "Client Facom",
      "INFO-CREATE.USER-TITLE": "Créer Utilisateur",
      "INFO-CREATE.USER-TITLE-LINK": "Créer Utilisateur",
      "INFO-CREATE.USER-CUSTOMER-BOX-INPUT-USERNAME": "Nom d'utilisateur",
      "INFO-CREATE.USER-CUSTOMER-BOX-INPUT-FIRST-NAME": "Prénom",
      "INFO-CREATE.USER-CUSTOMER-BOX-INPUT-LAST-NAME": "Nóm",
      "INFO-CREATE.USER-DISTRIBUTOR-BOX.TITLE": "Distributeur Facom",
      "INFO-CREATE.USER-GROUP-BOX.": "Groupe distributeur Facom",
      "INFO-CREATE.USER-PICTURE-BOX.TITLE": "Photo",
      "INFO-CREATE.USER-PROPIERTIES-BOX.TITLE": "Préférences",
      "INFO-CREATE.USER-CONTACT-BOX.TITLE": "Coordonnées",
      "INFO-CREATE.USER-ADDRESS-BOX.TITLE": "Adresse",
      "SEARCH.USER.TITLE-HEADER": "Rechercher Utilisateurs",
      "SEARCH.USER.TITLE-ACTIVE": "Actif",
      "SEARCH.USER.TITLE-PENDING": "En attente",
      "SEARCH.USER.TITLE-WEB-SERVICES": "Web services seulement",
      "SEARCH.USER.TITLE-HOME-PAGE": "page d'accueil",
      "SEARCH.USER.TITLE-FROM": "Depuis",
      "SEARCH.USER.TITLE-TO": "Jusqu'à",
      "SEARCH.USER.DATE-FORMAT": "Format de date",
      "SEARCH.USER.DATE-INTERFACE": "Interface",
      "SEARCH.USER.DATE-DEFAULT-DOMAIN": "Domaine ouvert par défaut",
      "SEARCH.USER.DISTRIBUTOR": "Distributeur",
      "SEARCH.USER.CUSTOMER": "Client",
      "SEARCH.USER.CUSTOMER-USERNAME": "Nom d'utilisateur Client",
      "SEARCH.USER.CUSTOMER-FIRST-NAME": "Prénom Client",
      "SEARCH.USER.CUSTOMER-LAST-NAME": "Nom Client",
      "SEARCH.USER.GROUP": "Groupe",
      "CREATE.USER.ROLE": "Rôle d'utilisateur",
      "CREATE.USER.MENU.VISIBLE": "Menu visible",
      "CREATE.USER.MENU.FACEOM-CUSTOMER-INFO": "Informations client Facom",
      "CREATE.USER.MENU.BUSINESS": "Entreprise",
      "CREATE.USER.SIRET CODE": "Nom de l'entreprise",
      "CREATE.USER.PASSWORD": "Mot de passe",
      "INFO.USER.RESET-PASSWORD": "Etes-vous sûr de vouloir exécuter cette action ?: Regenerer mot de passe",
      "INFO.USER.RESET-CONFIRM": "Confirmer",
      "INFO.USER.RESET-BUTTON-CONFIRM": "Qui",
      "INFO.USER.RESET-BUTTON-CANCEL": "Nom",

      "ALERT-USER-INVALID-ROLE": "Rôle invalide",

      //STATS
      "TABLE.PRODUCTS-STATS.TITLE-EXTRACTION": "Extraction",
      "TABLE.PRODUCTS-STATS.TITLE-HAMMERS": "Martellerie / Frappe",
      "TABLE.PRODUCTS-STATS.TITLE-MALEWRENCHS": "Clés mâles",
      "TABLE.PRODUCTS-STATS.TITLE-TRACING": "Mesurage / Traçage",
      "TABLE.PRODUCTS-STATS.TITLE-OTHER": "Autre",
      "TABLE.PRODUCTS-STATS.TITLE-PAWLS": "Cliquets",
      "TABLE.PRODUCTS-STATS.TITLE-PLIERS": "Pinces / Pinces étaux",
      "TABLE.PRODUCTS-STATS.TITLE-SAWING": "Sciage / Perçage / Coupe",
      "TABLE.PRODUCTS-STATS.TITLE-SCREWDRIVERS": "Tournevis",
      "TABLE.PRODUCTS-STATS.TITLE-SOCKETS": "Douilles",
      "TABLE.PRODUCTS-STATS.TITLE-OPENINGWRENCH": "Clés à ouverture fixe ou variable",
      "CARDS.PRODUCTS-STATS.TYPE": "Type",
      "CARDS.PRODUCTS-STATS.BRAND-NAME": "Nom marque",
      "ALL-STATS-COLOR": "Couleur",
      "ALL-STATS-HIDDEN": "Masqué",
      "ALL-STATS-WIDTH": "Largeur de la page (px|%)",
      "ALL-STATS-HEIGHT": "Hauteur de la page (px|%)",
      "ALL-STATS-EXPORT-CSV": "CSV",
      "ALL-STATS-EXPORT-EXCEL": "Microsoft Excel",
      "PRODUCTS-STATS.HEADER.TITLE": "Produits",
      "STATS.HEADER.SUB-TITLE": "Stats",
      "NAV-ALL-ITEM1": "Options",
      "NAV-ALL-ITEM2": "Graphique",
      "NAV-ALL-ITEM3": "Exporter",
      "DISTRIBUTORS-STATS.HEADER.TITLE": "Distributeur",
      "TABLE.DISTRIBUTORS-TITLE-YES": "Oui",
      "TABLE.DISTRIBUTORS-TITLE-NO": "Non",
      "CARDS.DISTRIBUTORS-STATS.VISIBLE": "Visible ? ",
      "CARDS.DISTRIBUTORS-STATS.GROUP-NAME": "Nom du groupe",
      "CARDS.CUSTOMERS-STATS.TYPE": "Type",
      "CARDS.CUSTOMERS-STATS.REGISTRATION-DATE": "Date d'inscription",
      "CARDS.CUSTOMERS-STATS.BUSINESS": "Métier",
      "CUSTOMERS-STATS.HEADER.TITLE": "Client",
      "TABLE-CUSTOMER-STATS-TITLE-INDIVIDUAL": "Particulier",
      "TABLE-CUSTOMER-STATS-TITLE-COMPANY": "Entreprise",
      "TABLE-CUSTOMER-STATS-TITLE-ARTISAN": "Professionnel",
      "TABLE-CUSTOMER-STATS-TITLE-TOTAL": "Total",
      "TABLE-CUSTOMER-STATS-TITLE-INDUSTRY": "Professionnel Industrie",
      "TABLE-CUSTOMER-STATS-TITLE-MECHANIC": "Professionnel Auto-Moto",
      "TABLE-CUSTOMER-STATS-TITLE-EXTERIOR-REN": "Artisan rénovation d'extérieur",
      "TABLE-CUSTOMER-STATS-TITLE-INTERIOR-REN": "Artisan rénovation d'intérieur",
      "TABLE-CUSTOMER-STATS-TITLE-COACHBUILDER": "Artisan carrossier",
      "TABLE-CUSTOMER-STATS-TITLE-CARPENTER": "Artisan charpentier",
      "TABLE-CUSTOMER-STATS-TITLE-COPPERSMITH": "Artisan chaudronnier",
      "TABLE-CUSTOMER-STATS-TITLE-ROOFER": "Artisan couvreur",
      "TABLE-CUSTOMER-STATS-TITLE-ELECTRICIAN": "Electricien",
      "TABLE-CUSTOMER-STATS-TITLE-IRONWORKER": "Artisan ferronnier",
      "TABLE-CUSTOMER-STATS-TITLE-BUILDER": "Artisan maçon / plaquiste",
      "TABLE-CUSTOMER-STATS-TITLE-WOOD-SHAPER": "Artisan menuisier",
      "TABLE-CUSTOMER-STATS-TITLE-TOOLMAKER": "Artisan outilleur",
      "TABLE-CUSTOMER-STATS-TITLE-BUILDINGWORKER": "Artisan ouvrier en construction / bâtiment",
      "TABLE-CUSTOMER-STATS-TITLE-HOUSE-PAINTER": "Artisan peintre",
      "TABLE-CUSTOMER-STATS-TITLE-PLUMER": "Plombier",
      "TABLE-CUSTOMER-STATS-TITLE-LOCKSMITH": "Artisan serrurier",
      "TABLE-CUSTOMER-STATS-TITLE-WELDER": "Artisan soudeur",
      "TABLE-CUSTOMER-STATS-TITLE-STONERUTTER": "Artisan taille de pierre",
      "TABLE-CUSTOMER-STATS-TITLE-ELECTRICIAN-TECHNICIAN": "Artisan technicien électricien",
      "TABLE-CUSTOMER-STATS-TITLE-LATHE-MILLING": "Artisan tourneur-fraiseur",
      "TABLE-CUSTOMER-STATS-TITLE-OTHER-CRAFTMAN": "Artisan autre",
      "TABLE-CUSTOMER-STATS-TITLE-OTHERS": "Autres métiers",
      "ALL-STATS-BUTTON": "Stats",
      "CUSTOMER-STATS-BUTTON": "Stats inscriptions",
      "EXCHANGES-STATS-BUTTON-NAV-1": "Activité",
      "EXCHANGES-STATS-BUTTON-NAV-2": "Produits par distributeurs",
      "EXCHANGES-STATS-BUTTON-NAV-3": "Produits par dates",
      "EXCHANGES-ACTIVITY-STATS-CARD-STATUS": "Etat",
      "EXCHANGES-ACTIVITY-STATS-CARD-DATE": "Date",
      "EXCHANGES-TITLE-LINK": "Echange",
      "EXCHANGES-ACTIVITY-TITLE-SECOND-LINK": "Activité",
      "TABLE-EXCHANGES-ACTIVITY-TITLE-ANALYSIS": "Analyse",
      "TABLE-EXCHANGES-ACTIVITY-TITLE-ABANDONNED": "Abandonné",
      "TABLE-EXCHANGES-ACTIVITY-TITLE-REFUSED": "Refusé",
      "TABLE-EXCHANGES-ACTIVITY-TITLE-AVAILABLE": "Disponible",
      "TABLE-EXCHANGES-ACTIVITY-TITLE-EXCHANGED": "Echangé",
      "TABLE-EXCHANGES-ACTIVITY-TITLE-SUBMITTED": "Soumis",
      "TABLE-EXCHANGES-ACTIVITY-TITLE-RECYCLED": "Recyclé",
      "TABLE-EXCHANGES-ACTIVITY-TITLE-ACCEPTED": "Accepté",
      "TABLE-EXCHANGES-ACTIVITY-TITLE-DRAFT": "Brouillon",
      "TABLE-EXCHANGES-ACTIVITY-TITLE-TOTAL": "Total",
      "CARDS.EXCHANGES-PER-DIST-STATS.GROUP": "Groupe",
      "CARDS.EXCHANGES-PER-DIST-STATS.DISTRIBUTOR": "Distributeur",
      "CARDS.EXCHANGES-PER-DIST-STATS.TYPE": "Type",
      "CARDS.EXCHANGES-PER-DIST-STATS.QUANTITY": "Quantité",
      "CARDS.EXCHANGES-PER-DATE-STATS.BRAND-CODE": "Code marque",
      "CARDS.EXCHANGES-PER-DATE-STATS.PRODUCT": "Référence produit",
      "CARDS.EXCHANGES-PER-DATE-STATS.DATE": "Date",
      "CARDS.EXCHANGES-PER-DATE-STATS.QUANTITY": "Quantité",
      "CARDS.EXCHANGES-PER-DATE-STATS.TOTAL-COST": "Coût total",
      "EXCHANGES-PER-DATES-TITLE-SECOND-LINK": "Produits par dates",
      "EXCHANGES-PER-PRODUCTS-TITLE-SECOND-LINK": "Produits par distributeurs",

      //Alerts
      "ALERT-GLOBAL-FILL-FIELDS": "Veuillez remplir les champs obligatoires",
      "ALERT-BRAND-SUCCESS-DELETE": "La marque a été supprimée",
      "ALERT-BRAND-SUCCESS-UPDATED": "La marque a été mise à jour",
      "ALERT-BRAND-SUCCESS-CREATE": "La marque a été créée",
      "ALERT-BRAND-ERROR-GET-ONE": "Erreur lors de l'obtention des informations sur la marque",
      "ALERT-BRAND-ERROR-GET": "Erreur lors de la récupération des marques",
      "ALERT-BRAND-ERROR-UPDATE": "Erreur de mise à jour de la marque",
      "ALERT-BRAND-ERROR-CREATE": "Erreur lors de la création de la marque",
      "ALERT-BRAND-ERROR-DELETE": "Erreur lors de la suppression de la marque",
      "ALERT-BRAND-ERROR-EXPORT": "Erreur lors de l'exportation de la marque",

      //Alert Products
      "ALERT-PRODUCTS-SUCCESS-DELETE": "Le produit a été supprimé",
      "ALERT-PRODUCTS-SUCCESS-UPDATED": "Le produit a été mis à jour",
      "ALERT-PRODUCTS-SUCCESS-CREATE": "Le produit a été créé",
      "ALERT-PRODUCTS-ERROR-GET-ONE": "Erreur lors de l'obtention des informations sur le produit",
      "ALERT-PRODUCTS-ERROR-GET": "Erreur dans l'obtention des produits",
      "ALERT-PRODUCTS-ERROR-UPDATE": "Produit de mise à jour des erreurs",
      "ALERT-PRODUCTS-ERROR-CREATE": "Erreur de création de produit",
      "ALERT-PRODUCTS-ERROR-DELETE": "Erreur de suppression de produit",
      "ALERT-PRODUCTS-ERROR-EXPORT": "Erreur d'exportation de produit",

      //Alert Comments
      "ALERT-COMMENTS-SUCCESS-DELETE": "Les commentaires ont été supprimés",
      "ALERT-COMMENTS-SUCCESS-UPDATED": "Les commentaires ont été mis à jour",
      "ALERT-COMMENTS-SUCCESS-CREATE": "Les commentaires ont été créés",
      "ALERT-COMMENTS-ERROR-GET-ONE": "Erreur dans l'obtention d'informations sur les commentaires",
      "ALERT-COMMENTS-ERROR-GET": "Erreur dans l'obtention des commentaires",
      "ALERT-COMMENTS-ERROR-UPDATE": "Erreur de mise à jour du commentaire",
      "ALERT-COMMENTS-ERROR-CREATE": "Erreur de création de commentaire",
      "ALERT-COMMENTS-ERROR-DELETE": "Erreur de suppression de commentaire",
      "ALERT-COMMENTS-ERROR-EXPORT": "Erreur d'exportation de commentaire",

      "CONTENTS-TITLE": "Contenu",
      "CONTENTS-ENGLISH-VARIANT": "Contenu en anglais",
      "CONTENTS-FRENCH-VARIANT": "Contenu français",
      "ALERT-CONTENT-SUCCESS-DELETE": "Le contenu a été supprimé",
      "ALERT-CONTENT-SUCCESS-UPDATED": "Le contenu a été mis à jou`r",
      "ALERT-CONTENT-SUCCESS-CREATED": "Le contenu a été créé",
      "ALERT-CONTENT-SUCCESS-COPY": "Le contenu a été copié",
      "ALERT-CONTENT-ERROR-GET-ONE": "Erreur dans l'obtention d'informations sur le contenu",
      "ALERT-CONTENT-ERROR-GET": "Erreur dans l'obtention du contenu",
      "ALERT-CONTENT-ERROR-UPDATE": "Erreur de mise à jour du contenu",
      "ALERT-CONTENT-ERROR-CREATE": "Erreur de création de contenu",
      "ALERT-CONTENT-ERROR-DELETE": "Erreur de suppression de contenu",
      "ALERT-CONTENT-ERROR-EXPORT": "Erreur d'exportation de contenu",

      //Alerts Reason For Exchange

      //Alert Distributors
      "ALERT-DISTRIBUTORS-SUCCESS-DELETE": "Le distributeur a été supprimé",
      "ALERT-DISTRIBUTORS-SUCCESS-UPDATED": "Un distributeur a été mis à jour",
      "ALERT-DISTRIBUTORS-SUCCESS-CREATE": "Un distributeur a été créé",
      "ALERT-DISTRIBUTORS-ERROR-GET-ONE": "Erreur dans l'obtention des informations sur le distributeur",
      "ALERT-DISTRIBUTORS-ERROR-GET": "Erreur dans l'obtention des distributeurs",
      "ALERT-DISTRIBUTORS-ERROR-UPDATE": "Erreur lors de la mise à jour d'un distributeur",
      "ALERT-DISTRIBUTORS-ERROR-CREATE": "Erreur lors de la création d'un distributeur",
      "ALERT-DISTRIBUTORS-ERROR-DELETE": "Erreur lors de la suppression d'un distributeur",
      "ALERT-DISTRIBUTORS-ERROR-EXPORT": "Erreur lors de l'exportation d'un distributeur",

      //Alert Groups
      "ALERT-GROUPS-SUCCESS-DELETE": "Le groupe a été supprimé",
      "ALERT-GROUPS-SUCCESS-UPDATED": "Le groupe a été mis à jour",
      "ALERT-GROUPS-SUCCESS-CREATE": "Le groupe a été créé",
      "ALERT-GROUPS-ERROR-GET-ONE": "Erreur dans l'obtention des informations sur les groupes",
      "ALERT-GROUPS-ERROR-GET": "Erreur dans l'obtention des groupes",
      "ALERT-GROUPS-ERROR-UPDATE": "Erreur lors de la mise à jour d'un groupe",
      "ALERT-GROUPS-ERROR-CREATE": "Erreur de création de groupe",
      "ALERT-GROUPS-ERROR-DELETE": "Erreur lors de la suppression d'un groupe",
      "ALERT-GROUPS-ERROR-EXPORT": "Erreur d'exportation de groupe",

      "ALERT-REASON-FOR-EXCHANGE-SUCCESS-DELETE": "Raison pour laquelle la bourse a été supprimée",
      "ALERT-REASON-FOR-EXCHANGE-SUCCESS-UPDATED": "Raison pour laquelle la bourse a été mise à jour",
      "ALERT-REASON-FOR-EXCHANGE-SUCCESS-CREATE": "Raison pour laquelle la bourse a été créée",
      "ALERT-REASON-FOR-EXCHANGE-ERROR-GET-ONE": "Erreur dans l'obtention des informations sur la raison de l'échange",
      "ALERT-REASON-FOR-EXCHANGE-ERROR-GET": "Erreur dans l'obtention de la raison d'être d'un échange",
      "ALERT-REASON-FOR-EXCHANGE-ERROR-UPDATE": "Erreur de mise à jour de la raison d'être d'un échange",
      "ALERT-REASON-FOR-EXCHANGE-ERROR-CREATE": "Erreur lors de la création d'un motif d'échange",
      "ALERT-REASON-FOR-EXCHANGE-ERROR-DELETE": "Erreur lors de la suppression de la raison d'être d'un échange",
      "ALERT-REASON-FOR-EXCHANGE-ERROR-EXPORT": "Erreur lors de l'exportation d'un motif d'échange",

      //Alerts Refusal Reason
      "ALERT-REFUSAL-REASON-SUCCESS-DELETE": "La raison du refus a été supprimée",
      "ALERT-REFUSAL-REASON-SUCCESS-UPDATED": "Le motif de refus a été mis à jour",
      "ALERT-REFUSAL-REASON-SUCCESS-CREATE": "Le motif de refus a été créé",
      "ALERT-REFUSAL-REASON-ERROR-GET-ONE": "Erreur dans l'obtention des informations sur le motif de refus",
      "ALERT-REFUSAL-REASON-ERROR-GET": "Erreur dans l'obtention de la raison de refus",
      "ALERT-REFUSAL-REASON-ERROR-UPDATE": "Erreur de mise à jour de la raison de refus",
      "ALERT-REFUSAL-REASON-ERROR-CREATE": "Erreur lors de la création d'un motif de refus",
      "ALERT-REFUSAL-REASON-ERROR-DELETE": "Erreur lors de la suppression d'un motif de refus",
      "ALERT-REFUSAL-REASON-ERROR-EXPORT": "Erreur lors de l'exportation d'un motif de refus",

      //Alerts ChangeLog
      "ALERT-CHANGE-LOG-ERROR-GET-ONE": "Erreur dans l'obtention des informations du journal des modifications",
      "ALERT-CHANGE-LOG-ERROR-GET": "Erreur dans l'obtention du journal des modifications",

      //Alerts Customers
      "ALERT-CUSTOMERS-SUCCESS-DELETE": "Les clients ont été supprimés",
      "ALERT-CUSTOMERS-SUCCESS-UPDATED": "Des clients ont été mis à jour",
      "ALERT-CUSTOMERS-SUCCESS-CREATE": "Un client a été créé",
      "ALERT-CUSTOMERS-ERROR-GET-ONE": "Erreur dans l'obtention des informations sur les clients",
      "ALERT-CUSTOMERS-ERROR-GET": "Erreur dans l'obtention des clients",
      "ALERT-CUSTOMERS-ERROR-UPDATE": "Erreur lors de la mise à jour des clients",
      "ALERT-CUSTOMERS-ERROR-CREATE": "Erreur lors de la création de clients",
      "ALERT-CUSTOMERS-ERROR-DELETE": "Erreur lors de la suppression de clients",
      "ALERT-CUSTOMERS-ERROR-EXPORT": "Erreur lors de l'exportation de clients",

      "ALERT-ERROR-EMAIL-EXIST": "Cet email a déjà été enregistré",
      "ALERT-SIRET-CODE": "Il doit être un numéro à 10 chiffres",
      "ALERT-GENERATE-PASSWORD": "Erreur lors de la génération du mot de passe",

      //Alert Exchanges
      "ALERT-EXCHANGES-SUCCESS-DELETE": "L'échange a été supprimé",
      "ALERT-EXCHANGES-SUCCESS-UPDATED": "L'échange a été mis à jour",
      "ALERT-EXCHANGES-SUCCESS-CREATE": "L'échange a été créé",
      "ALERT-EXCHANGES-ERROR-GET-ONE": "Erreur dans l'obtention des informations sur les échanges",
      "ALERT-EXCHANGES-ERROR-GET": "Erreur dans la récupération des échanges",
      "ALERT-EXCHANGES-ERROR-UPDATE": "Erreur de mise à jour des échanges",
      "ALERT-EXCHANGES-ERROR-UPDATE-STATUS": "Erreur de mise à jour des échanges par statut",
      "ALERT-EXCHANGES-ERROR-CREATE": "Erreur lors de la création d'un échange",
      "ALERT-EXCHANGES-ERROR-DELETE": "Erreur lors de la suppression d'un échange",
      "ALERT-EXCHANGES-ERROR-EXPORT": "Erreur lors de l'exportation d'un échange",

      //Alert Exchanges
      "ALERT-USERS-SUCCESS-DELETE": "L'utilisateur a été supprimé",
      "ALERT-USERS-SUCCESS-UPDATED": "L'utilisateur a été mis à jour",
      "ALERT-USERS-SUCCESS-UPDATED-STATUS": "Le statut de l'utilisateur a été mis à jour",
      "ALERT-USERS-SUCCESS-CREATE": "L'utilisateur a été créé",
      "ALERT-USERS-SUCCESS-CREATE-ROL": "L'utilisateur a été créé",
      "ALERT-USERS-SUCCESS-CREATE-DISTRIBUTOR": "Le distributeur d'utilisateur a été créé",
      "ALERT-USERS-SUCCESS-CREATE-GROUP": "Le groupe d'utilisateurs a été créé",
      "ALERT-USERS-ERROR-CREATE-ROL": "Erreur de création d'utilisateur",
      "ALERT-USERS-ERROR-CREATE-DISTRIBUTOR": "Erreur lors de la création d'un utilisateur distributeur",
      "ALERT-USERS-ERROR-CREATE-GROUP": "Erreur lors de la création d'un groupe d'utilisateurs",
      "ALERT-USERS-ERROR-GET-ONE": "Error getting User information",
      "ALERT-USERS-ERROR-GET": "Erreur lors de l'obtention des utilisateurs",
      "ALERT-USERS-ERROR-GET-STATUS": "Erreur lors de l'obtention du statut des utilisateurs",
      "ALERT-USERS-ERROR-UPDATE": "Erreur lors de la mise à jour des utilisateurs",
      "ALERT-USERS-ERROR-UPDATE-STATUS": "Erreur lors de la mise à jour du statut de l'utilisateur",
      "ALERT-USERS-ERROR-CREATE": "Erreur lors de la création d'un utilisateur",
      "ALERT-USERS-ERROR-DELETE": "Erreur lors de la suppression d'un utilisateur",
      "ALERT-USERS-ERROR-EXPORT": "Erreur d'exportation d'un utilisateur",
      "ALERT-USERS-WARNING-GROUP": "Sélectionner le groupe",
      "ALERT-USERS-WARNING-DISTRIBUTOR": "Sélectionner un distributeur",

      "ALERT-GLOBAL-MATCH-PASSWORD": "Les mots de passe ne correspondent pas",
      "ALERT-GLOBAL-MATCH-PASSWORD-CHARACTERS":
        "Le mot de passe doit contenir : / 6 - 16 caractères et une majuscule / Un chiffre / Un caractère spécial",
      "ALERT-ERROR-IMAGE-SIZE": "L'image doit faire moins de 3 MB",
      "ALERT-LOADING-IMAGE": "Chargement de l'image...",
      //LOGIN AND OTHERS
      "LOGIN-INPUT-USER": "Utilisateur",
      "LOGIN-INPUT-PASSWORD": "Mot de passe",
      "LOGIN-BUTTON": "Connexion",
      "LOGIN-FORGOT-PASSWORD": "Mot de passe oublié",
      "LOGIN-FORGOT-PASSWORD-INPUT": "EMAIL",
      "LOGIN-FORGOT-PASSWORD-LABEL":
        "Veuillez saisir votre email pour vérifier votre identité et recevoir le renouvellement de votre mot de passe.",
      "LOGIN-FORGOT-PASSWORD-BUTTON": "Envoyer",
      "LOGIN-FORGOT-PASSWORD-BUTTON-RESEND": "Renvoyer",
      "CONFIRM-EMAIL-MAIN-TEXT": "CONFIGURATION DU MOT DE PASSE:",
      "CONFIRM-EMAIL-MAIN-DESCRIPTION": "Avant de pouvoir vous connecter, vous devez réinitialiser votre mot de passe",
      "CONFIRM-EMAIL-MAIN-STEP-1": "Demande de nouveau compte",
      "CONFIRM-EMAIL-MAIN-STEP-2": "Compte activé par le lien d’activation",
      "CONFIRM-EMAIL-MAIN-STEP-3": "Réinitialiser votre mot de passe par lien email",
      "CONFIRM-EMAIL-BUTTON": "Se connecter",
      "ERROR-EMAIL-CONFIRM-LABEL": "Écrivez votre email et votre mot de passe pour renvoyer le lien d'activation",

      "RESET-PASSWORD-INPUT-PASSWORD": "Mot de passe",
      "RESET-PASSWORD-INPUT-PASSWORD-CONFIRM": "Confirmer votre mot de passe",
      "RESET-PASSWORD-PASSWORD-BUTTON": "Envoyer",
      "RESET-PASSWORD-TITLE": "Réinitialiser votre mot de passe",
      "RESET-PASSWORD-SUCCES-ALERT": "Votre mot de passe a été changé",

      "EXCHANGES-ANALYZED-BUTTON": "A analyser",
      "EXCHANGES-ACCEPT-BUTTON": "Accepter",
      "EXCHANGES-REFUSE-BUTTON": "Refuser",
      "EXCHANGES-SUBMITTED-BUTTON": "Soumis",
      "EXCHANGES-DRAFT-BUTTON": "Brouillon",
      "EXCHANGES-AVAILABLE-BUTTON": "Disponible",
      "EXCHANGES-EXCHANGE-BUTTON": "Échange",
      "EXCHANGES-RECYCLE-BUTTON": "Recycler",
      "EXCHANGES-ABANDONED-BUTTON": "Abandonner",

      "FILER-TO-DOCK": "Détacher la recherche",
      "FILER-TO-COLUMNS": "Epingler en colonnes",
      "ALERT-CHECK-SESSION": "Votre session a expiré",

      "ROLES-DISTRIBUTOR": "Distributeur",
      "ROLES-GROUP": "Groupe distributeur",
      "ROLES-AFTERSALES": "SAV",
      "ROLES-ADMIN": "Administrateur",
      "ROLES-CUSTOMERS": "Client",

      "MODAL-CONFIRM-CHANGE-STATUS-DESCRIPTION": "Etes-vous sûr de vouloir exécuter cette action ?:",
      "MODAL-CONFIRM-CHANGE-STATUS-CONFIRM": "Oui",
      "MODAL-CONFIRM-CHANGE-STATUS-CANCEL": "Non",

      "FILE-BUTTON-REMOVE": "Retirer",
      "FILE-DESCRIPTION": "Déposer",

      //Copy

      "BRAND-COPY-TITLE": "Copier Marque",
      "ALERT-BRAND-COPY": "la marque a été copiée",
      "ALERT-BRAND-COPY-ERROR": "Échec de la copie de la marque",

      "PRODUCT-COPY-TITLE": "Copier Produit",
      "ALERT-PRODUCT-COPY": "le produit a été copiée",
      "ALERT-PRODUCT-COPY-ERROR": "Échec de la copie du produit",

      "DISTRIBUTOR-COPY-TITLE": "Copier Distributeur",
      "ALERT-DISTRIBUTOR-COPY": "le Distributeur a été copiée",
      "ALERT-DISTRIBUTOR-COPY-ERROR": "Échec de la copie du Distributeur",

      "DISTRIBUTOR-SET-COPY-TITLE": "Copier Ensembles Distributeur",
      "ALERT-DISTRIBUTOR-SET-COPY": "le Ensembles Distributeur a été copiée",
      "ALERT-DISTRIBUTOR-SET-COPY-ERROR": "Échec de la copie du Ensembles Distributeur",

      "GROUP-COPY-TITLE": "Copier Groupe",
      "ALERT-GROUP-COPY": "le Groupe a été copiée",
      "ALERT-GROUP-COPY-ERROR": "Échec de la copie du Groupe",

      "CUSTOMERS-COPY-TITLE": "Copier Clients",
      "ALERT-CUSTOMERS-COPY": "le Clients Groupe a été copiée",
      "ALERT-CUSTOMERS-COPY-ERROR": "Échec de la copie des Clients",

      "EXCHANGES-COPY-TITLE": "Copy des échanges",
      "ALERT-EXCHANGES-COPY": "Des Échanges s Groupe a été copiée",
      "ALERT-EXCHANGES-COPY-ERROR": "Échec de la copie des échanges",

      "CONTENTS-COPY-TITLE": "Copier le Contenu",
      "ALERT-CONTENTS-COPY": "le Contenu Groupe a été copiée",
      "ALERT-CONTENTS-COPY-ERROR": "Échec de la copie le Contenu",

      "REASON-FOR-EXCHANGE-COPY-TITLE": "Copier la raison de l'échange",
      "ALERT-REASON-FOR-EXCHANGE-COPY": "la raison de l'échange a été copiée",
      "ALERT-REASON-FOR-EXCHANGE-COPY-ERROR": "Échec de la copie la raison de l'échange",

      "REASON-FOR-REFUSAL-COPY-TITLE": "Copier le motif du refus",
      "ALERT-REASON-FOR-REFUSAL-COPY": "le motif du refus a été copiée",
      "ALERT-REASON-FOR-REFUSAL-COPY-ERROR": "Échec de la copie le motif du refus",

      "USER-COPY-TITLE": "Copier l'utilisateur",
      "ALERT-USER-COPY": "L'utilisateur a été copié",
      "ALERT-USER-COPY-ERROR": "Échec de la copie de l'utilisateur",

      "VERIFY-IDENTITY-TITLE": "Vérifier l'identité",
      "SUBMIT-BUTTON": "Soumettre",

      "VERIFY-IDENTITY-DESCRIPTION-1": "Avant de pouvoir vous connecter, nous devons vérifier votre identité.",
      "VERIFY-IDENTITY-DESCRIPTION-2":
        "Après avoir cliqué sur le bouton Soumettre, veuillez vérifier votre courrier électronique pour valider votre identité.",
      "VERIFY-IDENTITY-DESCRIPTION-3":
        "Une fois que vous avez vérifié votre identité, c'est l'e-mail que vous utiliserez pour vous connecter.",
      "VERIFY-IDENTITY-DESCRIPTION-4": "Veuillez fournir votre e-mail.",

      "BULK-BRAND-SECOND-TITLE": "Modifier en masse: Marque",
      "BULK-DESCRIPTION-1": "Sélectionner les champs à modifier",
      "BULK-DESCRIPTION-2": "Entrer la valeur de chaque champ à modifier",
      "BULK-DESCRIPTION-3": "Enregistrer",
      "BULK-UPDATE-TITLE": "Modifier",
      "BULK-VALUE-TITLE": "Valeur",
      "TABLE.HEADER-OPTIONS-LIST-BULK": "Modifier en masse",
      "ALERT-NOT-EMAIL": "veuillez utiliser un e-mail",

      "LANGUAGE-LIST-FRENCH": "Français",
      "LANGUAGE-LIST-ENGLISH": "Anglais",
    },
  },
};

export default resources;

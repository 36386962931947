import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import MenuEnglish from "../../../images/MenuEnglish.png";
import MenuFrench from "../../../images/MenuFrance.png";
import { setProfile } from "../../../store/redux/actions/profile_actions";
export default function LanguageHandler() {
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();

  const changelanguage = (language: string) => {
    i18n.changeLanguage(language);
    dispatch(
      setProfile({
        languaje: language,
      })
    );
  };
  return (
    <div style={{ display: "flex", justifyContent: "flex-end" }}>
      <img
        alt=""
        className="mx-3"
        onClick={() => changelanguage("en")}
        src={MenuEnglish}
        style={
          i18n.language === "en"
            ? {
                cursor: "pointer",
                width: "25px",
                height: "25px",
                borderBottom: "solid 1px #333",
              }
            : { width: "25px", height: "25px", cursor: "pointer" }
        }
      ></img>
      <img
        alt=""
        onClick={() => changelanguage("fr")}
        src={MenuFrench}
        style={
          i18n.language === "en"
            ? { width: "25px", height: "25px", cursor: "pointer" }
            : {
                width: "25px",
                height: "25px",
                borderBottom: "solid 1px #333",
                cursor: "pointer",
              }
        }
      ></img>
    </div>
  );
}

import { useMediaQuery } from "@mui/material";
import { AnyRecord } from "dns";
import React, { useState, createContext, useEffect } from "react";

/*
The idea of this context it is allow a mechanism to pass the ticket and the any other information
(AKA data or formValues) between the pages.
*/

type contextValues = {
  login: any;
  setLogin(login: any): void;
  language: string;
  setLanguage(language: string): void;
  typeUser: string;
  setTypeUser(typeUser: string): void;
  loading: boolean;
  setLoading(loading: boolean): void;

  //POP UPS windos
  openBrandTable: boolean;
  setOpenBrandTable(openBrandTable: boolean): void;
  openCreateBrand: boolean;
  setOpenCreateBrand(openCreateBrand: boolean): void;
  openGroupTable: boolean;
  setOpenGroupTable(openGroupTable: boolean): void;
  openCreateGroup: boolean;
  setOpenCreateGroup(openCreateGroup: boolean): void;
  openDistributorTable: boolean;
  setOpenDistributorTable(openDistributorTable: boolean): void;
  openCustomerTable: boolean;
  setOpenCustomerTable(openCustomerTable: boolean): void;
  openProductTable: boolean;
  setOpenProductTable(openProductTable: boolean): void;
  openReasonExchangeTable: boolean;
  setOpenReasonExchangeTable(openReasonExchangeTable: boolean): void;

  openCreateDistributor: boolean;
  setOpenCreateDistributor(openCreateDistributor: boolean): void;
  openCreateCustomer: boolean;
  setOpenCreateCustomer(openCreateCustomer: boolean): void;
  openCreateProduct: boolean;
  setOpenCreateProduct(openCreateProduct: boolean): void;
  openCreateReasonExchange: boolean;
  setOpenCreateReasonExchange(openCreateReasonExchange: boolean): void;

  //Brands
  brandId: string;
  setBrandId(brandId: string): void;
  brandCode: string;
  setBrandCode(brandCode: string): void;
  brandName: string;
  setBrandName(brandName: string): void;
  brandToDelete: string;
  setBrandToDelete(brandToDelete: string): void;
  role: any;
  setRole(role: any): void;
  brandProductLength: number;
  setBrandProductLength(brandProductLength: number): void;

  //Distributor set
  distributorSetListId: string[];
  setDistributorSetListId(distributorSetListId: string[]): void;
  distributorSetListIdSelect: string[];
  setDistributorSetListIdSelect(distributorSetListIdSelect: string[]): void;
  distributorSetLength: number;
  setDistributorSetLength(distributorSetLength: number): void;
  alertDeleteDistributorSet: boolean;
  setAlertDeleteDistributorSet(alertDeleteDistributorSet: boolean): void;

  //Groups
  groupId: string;
  setGroupId(groupId: string): void;
  groupCode: string;
  setGroupCode(groupCode: string): void;
  groupName: string;
  setGroupName(groupName: string): void;
  groupToDelete: string;
  setGroupToDelete(groupToDelete: string): void;

  productToDelete: string;
  setProductToDelete(productToDelete: string): void;
  distributorToDelete: string;
  setDistributorToDelete(distributorToDelete: string): void;
  customerToDelete: string;
  setCustomerToDelete(customerToDelete: string): void;
  userToDelete: string;
  setUserToDelete(userToDelete: string): void;

  //Lengths of tables
  brandLength: number;
  setBrandLength(brandLength: number): void;
  productLength: number;
  setProductLength(productLength: number): void;
  groupLength: number;
  setGroupLength(groupLength: number): void;
  distributorLength: number;
  setDistributorLength(distributorLength: number): void;
  customersLength: number;
  setCustomersLength(customersLength: number): void;
  exchangesLength: number;
  setExchangesLength(productLength: number): void;
  reasonExchangeLength: number;
  setReasonExchangeLength(productLength: number): void;
  refusalReasonLength: number;
  setRefusalReasonLength(productLength: number): void;
  userLength: number;
  setUserLength(productLength: number): void;

  exchangesDraftLength: number;
  setExchangesDraftLength(exchangesDraftLength: number): void;
  exchangesDAbandonnedength: number;
  setExchangesAbandonnedLength(exchangesDAbandonnedength: number): void;
  exchangesSubmittedLength: number;
  setExchSubmittedDraftLength(exchangesSubmittedLength: number): void;
  exchangesAnalisisLength: number;
  setExchangesAnalisisLength(exchangesAnalisisLength: number): void;
  exchangesAcceptedLength: number;
  setExchangesAcceptedLength(exchangesAcceptedLength: number): void;
  exchangesAviableLength: number;
  setExchangesAviableLength(exchangesAviableLength: number): void;
  exchangesExchangedLength: number;
  setExchangeExchangeddLength(exchangesExchangedLength: number): void;
  exchangesRefusedLength: number;
  setExchangesRefusedLength(exchangesRefusedLength: number): void;
  exchangesRecycledLength: number;
  setExchangesRecycledLength(exchangesRecycledLength: number): void;
  contentLength: number;
  setContentLength(contentLength: number): void;
  changeLogLength: number;
  setChangeLogLength(changeLogLength: number): void;

  brandsListID: string[];
  setBrandsListID(brandsListID: string[]): void;

  productsListID: string[];
  setProductsListID(brandsListID: string[]): void;
  groupsListID: string[];
  setGroupsListID(groupsListID: string[]): void;
  distributorListID: string[];
  setDistributorListID(distributorListID: string[]): void;
  distributorListMembers: any[];
  setDistributorListMembers(distributorListMembers: any[]): void;
  customerListID: string[];
  setCustomerListID(customerListID: string[]): void;
  exchangesListID: string[];
  setExchangesListID(exchangesListID: string[]): void;
  reasonForExchangesListID: string[];
  setReasonForExchangesListID(reasonForExchangesListID: string[]): void;
  refusalReasonListID: string[];
  setRefusalReasonListID(refusalReasonListID: string[]): void;
  changeLogListID: string[];
  setChangeLogListID(changeLogListID: string[]): void;
  usersListID: string[];
  setUsersListID(usersListID: string[]): void;
  contentsListID: string[];
  setContentsListID(contentsListID: string[]): void;
  //EXCHANGES
  //Customer and user
  customerId: string;
  setCustomerId(customerId: string): void;
  customerUserName: string;
  setCustomerUserName(customerUserName: string): void;
  customerFirstName: string;
  setCustomerFirstName(customerFirstName: string): void;
  customerLastName: string;
  setCustomerLastName(customerLastName: string): void;
  customerEmail: string;
  setCustomerEmail(customerEmail: string): void;
  customerMobilePhoneNumber: string;
  setCustomerMobilePhoneNumber(customerMobilePhoneNumber: string): void;

  customerZipCode: string;
  setCustomerZipCode(customerZipCode: string): void;
  customerCity: string;
  setCustomerCity(customerCity: string): void;
  customerCountry: string;
  setCustomerCountry(customerCountry: string): void;
  customerLanguage: string;
  setCustomerLanguage(customerLanguage: string): void;

  ExchangeToDelete: string;
  setExchangeToDelete(ExchangeToDelete: string): void;

  exchangesDraft: any;
  setGroupExchangesDraft(exchangesDraft: any): void;
  exchangesAbandonned: any;
  setGroupExchangesAbandonned(exchangesAbandonned: any): void;
  exchangesSubmitted: any;
  setGroupExchangesSubmitted(exchangesSubmitted: any): void;
  exchangesAnalysis: any;
  setGroupExchangesAnalysis(exchangesAnalysis: any): void;
  exchangesAccepted: any;
  setGroupExchangesAccepted(exchangesAccepted: any): void;
  exchangesAvailable: any;
  setGroupExchangesAvailable(exchangesAvailable: any): void;
  exchangesExchanged: any;
  setGroupExchangesExchanged(exchangesExchanged: any): void;
  exchangesRefused: any;
  setGroupExchangesRefused(exchangesRefused: any): void;
  exchangesRecycled: any;
  setGroupExchangesRecycled(exchangesRecycled: any): void;

  //Dristributor and group
  distributorId: string;
  setDistributorId(distributorId: string): void;
  distributorCode: string;
  setDistributorCode(distributorCode: string): void;
  distributorName: string;
  setDistributorName(distributorName: string): void;
  distributorGroupCode: string;
  setDistributorGroupCode(distributorGroupCode: string): void;
  distributorGroupName: string;
  setDistributorGroupName(distributorGroupName: string): void;
  distributorClientAccountNumer: string;
  setDistributorClientAccountNumer(distributorClientAccountNumer: string): void;
  distributorDeliveryAccountNumber: string;
  setDistributorDeliveryAccountNumber(distributorDeliveryAccountNumber: string): void;
  distributorPhone: string;
  setDistributorPhone(distributorPhone: string): void;
  distributorEmail: string;
  setDistributorEmail(distributorEmail: string): void;
  distributorResponsiveEmail: string;
  setDistributorResponsiveEmail(distributorEmail: string): void;

  //Product and brand
  productId: string;
  setProductId(productId: string): void;
  productReference: string;
  setProductReference(productReference: string): void;
  productName: string;
  setProductName(productName: string): void;
  productBrandCode: string;
  setProductBrandCode(productBrandCode: string): void;
  productBrandName: string;
  setProductBrandName(productBrandName: string): void;
  productPicture: any;
  setProductPicture(productPicture: any): void;
  productType: string;
  setProductType(productType: string): void;
  productUnitPrice: string;
  setProductUnitPrice(productUnitPrice: string): void;

  reasonExchangeToDelete: string;
  setReasonExchangeToDelete(reasonExchangeToDelete: string): void;
  reasonExchangeId: string;
  setReasonExchangeId(reasonExchangeId: string): void;
  reasonExchangeGivenReason: string;
  setReasonExchangeGivenReason(reasonExchangeGivenReason: string): void;

  openRefusalReasonTable: boolean;
  setOpenRefusalReasonTable(openRefusalReasonTable: boolean): void;
  openCreateRefusalReason: boolean;
  setOpenCreateRefusalReason(openCreateRefusalReason: boolean): void;
  refusalReasonToDelete: string;
  setRefusalReasonToDelete(refusalReasonToDelete: string): void;
  refusalReasonId: string;
  setRefusalReasonId(refusalReasonId: string): void;
  refusalReasonLabel: string;
  setRefusalReasonGivenLabel(refusalReasonLabel: string): void;

  //USERS BY STATUS
  userDisabled: any;
  setGroupUserDisabled(userDisabled: any): void;
  userEnabled: any;
  setGroupUserEnabled(userEnabled: any): void;
  userPending: any;
  setGroupUserPending(userPending: any): void;
  userWebServices: any;
  setGroupUserWebServices(userWebServices: any): void;
  userDisabledLength: number;
  setUserDisabledLength(userDisabledLength: number): void;
  userEnabledLength: number;
  setUserEnabledLength(userEnabledLength: number): void;
  userPendingLength: number;
  setUserPendingLength(userPendingLength: number): void;
  userWebServicesLength: number;
  setUserWebServicesLength(userWebServicesLength: number): void;

  setAlertDeleteBrand(alertDeleteBrand: boolean): void;
  alertDeleteBrand: boolean;

  setAlertDeleteReasonExchange(alertDeleteReasonExchange: boolean): void;
  alertDeleteReasonExchange: boolean;

  setAlertDeleteRefusalReason(alertDeleteRefusalReason: boolean): void;
  alertDeleteRefusalReason: boolean;

  setAlertDeleteProduct(alertDeleteProduct: boolean): void;
  alertDeleteProduct: boolean;

  setAlertDeleteExchange(alertDeleteExchange: boolean): void;
  alertDeleteExchange: boolean;

  setAlertDeleteGroup(alertDeleteGroup: boolean): void;
  alertDeleteGroup: boolean;

  alertDeleteContent: boolean;
  setAlertDeleteContent(alertDeleteContent: boolean): void;

  setAlertDeleteDistributor(alertDeleteDistributor: boolean): void;
  alertDeleteDistributor: boolean;

  setAlertDeleteCustomer(alertDeleteCustomer: boolean): void;
  alertDeleteCustomer: boolean;

  setAlertDeleteExchanges(alertDeleteExchanges: boolean): void;
  alertDeleteExchanges: boolean;

  setAlertDeleteUser(alertDeleteUser: boolean): void;
  alertDeleteUser: boolean;

  successResetPassword: boolean;
  setSuccessResetPassword(alertDeleteUser: boolean): void;

  typeOfSearch: number;
  setTypeOfSearch(typeOfSearch: number): void;
  errorCheckSession: boolean;
  setErrorCheckSession(errorCheckSession: boolean): void;

  //pages
  contentPage: number;
  setContentPage(contentPage: number): void;

  brandPage: number;
  setBrandPage(brandPage: number): void;
  productsPage: number;
  setProductsPage(productsPage: number): void;
  groupsPage: number;
  setGroupsPage(groupsPage: number): void;
  distributorsPage: number;
  setDistributorsPage(distributorsPage: number): void;
  distributorsSetPage: number;
  setDistributorsSetPage(distributorsSetPage: number): void;
  customerPage: number;
  setCustomerPage(customerPage: number): void;
  exchangePage: number;
  setExchangePage(exchangePage: number): void;
  reasonForExchangePage: number;
  setReasonForExchangePage(reasonForExchangePage: number): void;
  reasonForRefusalPage: number;
  setReasonForRefusalPage(reasonForRefusalPage: number): void;
  userPage: number;
  setUserPage(userPage: number): void;
  changeLogPage: number;
  setChangeLogPage(changeLogPage: number): void;

  openDistributorSelect: boolean;
  setOpenDistributorSelect(openDistributorSelect: boolean): void;

  openMembersOfSetSelect: boolean;
  setOpenMembersOfSetSelect(openMembersOfSetSelect: boolean): void;
  membersOfSetListMembers: any[];
  setMembersOfSetListMembers(membersOfSetListMembers: any[]): void;
  membersOfSetListIdSelect: any[];
  setMembersOfSetListIdSelect(membersOfSetListIdSelect: any[]): void;
  brandDeleteToggle: boolean;
  setBrandDeleteToggle(brandDeleteToggle: boolean): void;

  brandTableTransferData: string[];
  setBrandTableTransferData(brandTableTransferData: string[]): void;

  productTableTransferData: string[];
  setProductTableTransferData(productTableTransferData: string[]): void;
  groupTableTransferData: string[];
  setGroupTableTransferData(groupTableTransferData: string[]): void;
  distributorTableTransferData: string[];
  setDistributorTableTransferData(distributorTableTransferData: string[]): void;
  distributorSetTableTransferData: string[];
  setDistributorSetTableTransferData(distributorSetTableTransferData: string[]): void;
  customerTableTransferData: string[];
  setCustomerTableTransferData(customerTableTransferData: string[]): void;
  exchangesTableTransferData: string[];
  setExchangesTableTransferData(exchangesTableTransferData: string[]): void;
  contentsTableTransferData: string[];
  setContentsTableTransferData(contentsTableTransferData: string[]): void;
  reasonForExchangeTableTransferData: string[];
  setReasonForExchangeTableTransferData(reasonForExchangeTableTransferData: string[]): void;
  reasonForRefusalTableTransferData: string[];
  setReasonForRefusalTableTransferData(reasonForRefusalTableTransferData: string[]): void;
  changeLogsTableTransferData: string[];
  setChangeLogsTableTransferData(changeLogsTableTransferData: string[]): void;
  usersTableTransferData: string[];
  setUsersTableTransferData(usersTableTransferData: string[]): void;
  changeLanguage: boolean;
  setChangeLanguage: any;
};

const defaultValues = {
  // login:()=>{return window.sessionStorage.getItem('token')},
  login: () => {
    return window.localStorage.getItem("token");
  },
  setLogin: () => {},
  language: "english",
  setLanguage: () => {},
  typeUser: "english",
  setTypeUser: () => {},
  loading: false,
  setLoading: () => {},

  //pop ups windows
  openBrandTable: false,
  setOpenBrandTable: () => {},
  openCreateBrand: false,
  setOpenCreateBrand: () => {},
  openGroupTable: false,
  setOpenGroupTable: () => {},
  openCreateGroup: false,
  setOpenCreateGroup: () => {},
  openDistributorTable: false,
  setOpenDistributorTable: () => {},
  openCustomerTable: false,
  setOpenCustomerTable: () => {},
  openProductTable: false,
  setOpenProductTable: () => {},
  openReasonExchangeTable: false,
  setOpenReasonExchangeTable: () => {},

  openCreateDistributor: false,
  setOpenCreateDistributor: () => {},
  openCreateCustomer: false,
  setOpenCreateCustomer: () => {},
  openCreateProduct: false,
  setOpenCreateProduct: () => {},
  openCreateReasonExchange: false,
  setOpenCreateReasonExchange: () => {},

  //BRANDS
  brandId: "",
  setBrandId: () => {},
  brandCode: "",
  setBrandCode: () => {},
  brandName: "",
  setBrandName: () => {},
  brandToDelete: "",
  setBrandToDelete: () => {},
  role: () => {
    return window.localStorage.getItem("rol");
  },
  setRole: () => {},
  brandProductLength: 0,
  setBrandProductLength: () => {},

  //Dsitrubutor sets

  distributorSetListId: [],
  setDistributorSetListId: () => {},
  distributorSetListIdSelect: [],
  setDistributorSetListIdSelect: () => {},
  distributorSetLength: 0,
  setDistributorSetLength: () => {},
  alertDeleteDistributorSet: false,
  setAlertDeleteDistributorSet: () => {},

  //GROUPS
  groupId: "",
  setGroupId: () => {},
  groupCode: "",
  setGroupCode: () => {},
  groupName: "",
  setGroupName: () => {},
  groupToDelete: "",
  setGroupToDelete: () => {},

  productToDelete: "",
  setProductToDelete: () => {},

  distributorToDelete: "",
  setDistributorToDelete: () => {},

  customerToDelete: "",
  setCustomerToDelete: () => {},

  //Lengths of tables
  brandLength: 0,
  setBrandLength: () => {},
  productLength: 0,
  setProductLength: () => {},
  groupLength: 0,
  setGroupLength: () => {},
  distributorLength: 0,
  setDistributorLength: () => {},
  customersLength: 0,
  setCustomersLength: () => {},
  exchangesLength: 0,
  setExchangesLength: () => {},
  reasonExchangeLength: 0,
  setReasonExchangeLength: () => {},
  refusalReasonLength: 0,
  setRefusalReasonLength: () => {},
  userLength: 0,
  setUserLength: () => {},
  exchangesDraftLength: 0,
  setExchangesDraftLength: () => {},
  exchangesDAbandonnedength: 0,
  setExchangesAbandonnedLength: () => {},
  exchangesSubmittedLength: 0,
  setExchSubmittedDraftLength: () => {},
  exchangesAnalisisLength: 0,
  setExchangesAnalisisLength: () => {},
  exchangesAcceptedLength: 0,
  setExchangesAcceptedLength: () => {},
  exchangesAviableLength: 0,
  setExchangesAviableLength: () => {},
  exchangesExchangedLength: 0,
  setExchangeExchangeddLength: () => {},
  exchangesRefusedLength: 0,
  setExchangesRefusedLength: () => {},
  exchangesRecycledLength: 0,
  setExchangesRecycledLength: () => {},
  contentLength: 0,
  setContentLength: () => {},
  changeLogLength: 0,
  setChangeLogLength: () => {},

  //EXCHANGES
  //Customer and user
  customerId: "",
  setCustomerId: () => {},
  customerUserName: "",
  setCustomerUserName: () => {},
  customerFirstName: "",
  setCustomerFirstName: () => {},
  customerLastName: "",
  setCustomerLastName: () => {},
  customerEmail: "",
  setCustomerEmail: () => {},
  customerMobilePhoneNumber: "",
  setCustomerMobilePhoneNumber: () => {},

  customerZipCode: "",
  setCustomerZipCode: () => {},
  customerCity: "",
  setCustomerCity: () => {},
  customerCountry: "",
  setCustomerCountry: () => {},
  customerLanguage: "",
  setCustomerLanguage: () => {},

  exchangesDraft: [{}],
  setGroupExchangesDraft: () => {},
  exchangesAbandonned: [{}],
  setGroupExchangesAbandonned: () => {},
  exchangesSubmitted: [{}],
  setGroupExchangesSubmitted: () => {},
  exchangesAnalysis: [{}],
  setGroupExchangesAnalysis: () => {},
  exchangesAccepted: [{}],
  setGroupExchangesAccepted: () => {},
  exchangesAvailable: [{}],
  setGroupExchangesAvailable: () => {},
  exchangesExchanged: [{}],
  setGroupExchangesExchanged: () => {},
  exchangesRefused: [{}],
  setGroupExchangesRefused: () => {},
  exchangesRecycled: [{}],
  setGroupExchangesRecycled: () => {},
  ExchangeToDelete: "",
  setExchangeToDelete: () => {},
  userToDelete: "",
  setUserToDelete: () => {},

  brandsListID: [],
  setBrandsListID: () => {},

  productsListID: [],
  setProductsListID: () => {},
  groupsListID: [],
  setGroupsListID: () => {},
  distributorListID: [],
  setDistributorListID: () => {},
  distributorListMembers: [],
  setDistributorListMembers: () => {},

  customerListID: [],
  setCustomerListID: () => {},
  exchangesListID: [],
  setExchangesListID: () => {},
  reasonForExchangesListID: [],
  setReasonForExchangesListID: () => {},
  refusalReasonListID: [],
  setRefusalReasonListID: () => {},
  changeLogListID: [],
  setChangeLogListID: () => {},
  usersListID: [],
  setUsersListID: () => {},
  contentsListID: [],
  setContentsListID: () => {},
  //Dristributor and group
  distributorId: "",
  setDistributorId: () => {},
  distributorCode: "",
  setDistributorCode: () => {},
  distributorName: "",
  setDistributorName: () => {},
  distributorGroupCode: "",
  setDistributorGroupCode: () => {},
  distributorGroupName: "",
  setDistributorGroupName: () => {},
  distributorClientAccountNumer: "",
  setDistributorClientAccountNumer: () => {},
  distributorDeliveryAccountNumber: "",
  setDistributorDeliveryAccountNumber: () => {},
  distributorPhone: "",
  setDistributorPhone: () => {},
  distributorEmail: "",
  setDistributorEmail: () => {},
  distributorResponsiveEmail: "",
  setDistributorResponsiveEmail: () => {},

  //Product and brand
  productId: "",
  setProductId: () => {},
  productReference: "",
  setProductReference: () => {},
  productName: "",
  setProductName: () => {},
  productBrandCode: "",
  setProductBrandCode: () => {},
  productBrandName: "",
  setProductBrandName: () => {},
  productPicture: "",
  setProductPicture: () => {},
  productType: "",
  setProductType: () => {},
  productUnitPrice: "",
  setProductUnitPrice: () => {},

  reasonExchangeToDelete: "",
  setReasonExchangeToDelete: () => {},
  reasonExchangeId: "",
  setReasonExchangeId: () => {},
  reasonExchangeGivenReason: "",
  setReasonExchangeGivenReason: () => {},

  openRefusalReasonTable: false,
  setOpenRefusalReasonTable: () => {},
  openCreateRefusalReason: false,
  setOpenCreateRefusalReason: () => {},
  refusalReasonToDelete: "",
  setRefusalReasonToDelete: () => {},
  refusalReasonId: "",
  setRefusalReasonId: () => {},
  refusalReasonLabel: "",
  setRefusalReasonGivenLabel: () => {},

  //USERS BY STATUS
  userDisabled: [{}],
  setGroupUserDisabled: () => {},
  userEnabled: [{}],
  setGroupUserEnabled: () => {},
  userPending: [{}],
  setGroupUserPending: () => {},
  userWebServices: [{}],
  setGroupUserWebServices: () => {},
  userDisabledLength: 0,
  setUserDisabledLength: () => {},
  userEnabledLength: 0,
  setUserEnabledLength: () => {},
  userPendingLength: 0,
  setUserPendingLength: () => {},
  userWebServicesLength: 0,
  setUserWebServicesLength: () => {},

  //Alert
  alertDeleteBrand: false,
  setAlertDeleteBrand: () => {},

  alertDeleteReasonExchange: false,
  setAlertDeleteReasonExchange: () => {},

  alertDeleteRefusalReason: false,
  setAlertDeleteRefusalReason: () => {},

  alertDeleteProduct: false,
  setAlertDeleteProduct: () => {},

  alertDeleteExchange: false,
  setAlertDeleteExchange: () => {},

  alertDeleteGroup: false,
  setAlertDeleteGroup: () => {},

  alertDeleteContent: false,
  setAlertDeleteContent: () => {},

  alertDeleteDistributor: false,
  setAlertDeleteDistributor: () => {},

  alertDeleteCustomer: false,
  setAlertDeleteCustomer: () => {},

  alertDeleteExchanges: false,
  setAlertDeleteExchanges: () => {},

  alertDeleteUser: false,
  setAlertDeleteUser: () => {},

  successResetPassword: false,
  setSuccessResetPassword: () => {},
  typeOfSearch: 0,
  setTypeOfSearch: () => {},
  errorCheckSession: false,
  setErrorCheckSession: () => {},

  //pages
  contentPage: 1,
  setContentPage: () => {},

  brandPage: 1,
  setBrandPage: () => {},
  productsPage: 1,
  setProductsPage: () => {},
  groupsPage: 1,
  setGroupsPage: () => {},
  distributorsPage: 1,
  setDistributorsPage: () => {},
  distributorsSetPage: 1,
  setDistributorsSetPage: () => {},
  customerPage: 1,
  setCustomerPage: () => {},
  exchangePage: 1,
  setExchangePage: () => {},
  reasonForExchangePage: 1,
  setReasonForExchangePage: () => {},
  reasonForRefusalPage: 1,
  setReasonForRefusalPage: () => {},
  userPage: 1,
  setUserPage: () => {},
  changeLogPage: 1,
  setChangeLogPage: () => {},

  openDistributorSelect: false,
  setOpenDistributorSelect: () => {},

  openMembersOfSetSelect: false,
  setOpenMembersOfSetSelect: () => {},
  membersOfSetListMembers: [],
  setMembersOfSetListMembers: () => {},
  membersOfSetListIdSelect: [],
  setMembersOfSetListIdSelect: () => {},
  brandDeleteToggle: false,
  setBrandDeleteToggle: () => {},

  brandTableTransferData: [],
  setBrandTableTransferData: () => {},
  productTableTransferData: [],
  setProductTableTransferData: () => {},

  groupTableTransferData: [],
  setGroupTableTransferData: () => {},
  distributorTableTransferData: [],
  setDistributorTableTransferData: () => {},
  distributorSetTableTransferData: [],
  setDistributorSetTableTransferData: () => {},
  customerTableTransferData: [],
  setCustomerTableTransferData: () => {},
  exchangesTableTransferData: [],
  setExchangesTableTransferData: () => {},
  contentsTableTransferData: [],
  setContentsTableTransferData: () => {},
  reasonForExchangeTableTransferData: [],
  setReasonForExchangeTableTransferData: () => {},
  reasonForRefusalTableTransferData: [],
  setReasonForRefusalTableTransferData: () => {},
  changeLogsTableTransferData: [],
  setChangeLogsTableTransferData: () => {},
  usersTableTransferData: [],
  setUsersTableTransferData: () => {},
  changeLanguage: false,
  setChangeLanguage: () => {},
};

const ApplicationContext = createContext<contextValues>(defaultValues);

const ApplicationContextProvider = (props: any) => {
  const { data } = props;
  const [login, setLogin] = useState<any>(() => {
    return window.localStorage.getItem("token");
  });

  const [typeUser, setTypeUser] = useState<string>("");
  const [language, setLanguage] = useState<string>("");
  //User
  const [role, setRole] = useState<any>(() => {
    return window.localStorage.getItem("rol");
  });

  //Brand
  const [brandId, setBrandId] = useState<string>("");
  const [brandCode, setBrandCode] = useState<string>("");
  const [brandName, setBrandName] = useState<string>("");
  const [brandToDelete, setBrandToDelete] = useState<string>("");
  const [brandProductLength, setBrandProductLength] = useState<number>(0);

  //Distributor Set
  const [distributorSetListId, setDistributorSetListId] = useState<string[]>([]);
  const [distributorSetListIdSelect, setDistributorSetListIdSelect] = useState<string[]>([]);
  const [distributorSetLength, setDistributorSetLength] = useState<number>(0);
  const [alertDeleteDistributorSet, setAlertDeleteDistributorSet] = useState<boolean>(false);

  //Delete
  const [productToDelete, setProductToDelete] = useState<string>("");
  const [groupToDelete, setGroupToDelete] = useState<string>("");
  const [distributorToDelete, setDistributorToDelete] = useState<string>("");
  const [customerToDelete, setCustomerToDelete] = useState<string>("");
  const [userToDelete, setUserToDelete] = useState<string>("");

  const [loading, setLoading] = useState<boolean>(false);
  const [openBrandTable, setOpenBrandTable] = useState<boolean>(false);
  const [openCreateBrand, setOpenCreateBrand] = useState<boolean>(false);

  //Group
  const [groupId, setGroupId] = useState<string>("");
  const [groupCode, setGroupCode] = useState<string>("");
  const [groupName, setGroupName] = useState<string>("");

  const [openGroupTable, setOpenGroupTable] = useState<boolean>(false);
  const [openCreateGroup, setOpenCreateGroup] = useState<boolean>(false);

  //Counters
  const [brandLength, setBrandLength] = useState<number>(0);
  const [productLength, setProductLength] = useState<number>(0);
  const [groupLength, setGroupLength] = useState<number>(0);
  const [distributorLength, setDistributorLength] = useState<number>(0);
  const [customersLength, setCustomersLength] = useState<number>(0);
  const [exchangesLength, setExchangesLength] = useState<number>(0);
  const [exchangesDraftLength, setExchangesDraftLength] = useState<number>(0);
  const [exchangesDAbandonnedength, setExchangesAbandonnedLength] = useState<number>(0);
  const [exchangesSubmittedLength, setExchSubmittedDraftLength] = useState<number>(0);
  const [exchangesAnalisisLength, setExchangesAnalisisLength] = useState<number>(0);
  const [exchangesAcceptedLength, setExchangesAcceptedLength] = useState<number>(0);
  const [exchangesAviableLength, setExchangesAviableLength] = useState<number>(0);
  const [exchangesExchangedLength, setExchangeExchangeddLength] = useState<number>(0);
  const [exchangesRefusedLength, setExchangesRefusedLength] = useState<number>(0);
  const [exchangesRecycledLength, setExchangesRecycledLength] = useState<number>(0);
  const [reasonExchangeLength, setReasonExchangeLength] = useState<number>(0);
  const [refusalReasonLength, setRefusalReasonLength] = useState<number>(0);
  const [userLength, setUserLength] = useState<number>(0);
  const [contentLength, setContentLength] = useState<number>(0);
  const [changeLogLength, setChangeLogLength] = useState<number>(0);

  const [openDistributorTable, setOpenDistributorTable] = useState<boolean>(false);
  const [openCustomerTable, setOpenCustomerTable] = useState<boolean>(false);
  const [openProductTable, setOpenProductTable] = useState<boolean>(false);
  const [openReasonExchangeTable, setOpenReasonExchangeTable] = useState<boolean>(false);

  const [openCreateDistributor, setOpenCreateDistributor] = useState<boolean>(false);
  const [openCreateCustomer, setOpenCreateCustomer] = useState<boolean>(false);
  const [openCreateProduct, setOpenCreateProduct] = useState<boolean>(false);
  const [openCreateReasonExchange, setOpenCreateReasonExchange] = useState<boolean>(false);

  const [reasonExchangeToDelete, setReasonExchangeToDelete] = useState<string>("");
  const [ExchangeToDelete, setExchangeToDelete] = useState<string>("");
  const [reasonExchangeId, setReasonExchangeId] = useState<string>("");
  const [reasonExchangeGivenReason, setReasonExchangeGivenReason] = useState<string>("");

  const [openRefusalReasonTable, setOpenRefusalReasonTable] = useState<boolean>(false);
  const [openCreateRefusalReason, setOpenCreateRefusalReason] = useState<boolean>(false);
  const [refusalReasonToDelete, setRefusalReasonToDelete] = useState<string>("");
  const [refusalReasonId, setRefusalReasonId] = useState<string>("");
  const [refusalReasonLabel, setRefusalReasonGivenLabel] = useState<string>("");

  //ARRAYS IDS
  const [brandsListID, setBrandsListID] = useState<string[]>([]);
  const [productsListID, setProductsListID] = useState<string[]>([]);
  const [groupsListID, setGroupsListID] = useState<string[]>([]);
  const [distributorListID, setDistributorListID] = useState<string[]>([]);
  const [distributorListMembers, setDistributorListMembers] = useState<any[]>([]);
  const [customerListID, setCustomerListID] = useState<string[]>([]);
  const [exchangesListID, setExchangesListID] = useState<string[]>([]);
  const [reasonForExchangesListID, setReasonForExchangesListID] = useState<string[]>([]);
  const [refusalReasonListID, setRefusalReasonListID] = useState<string[]>([]);
  const [changeLogListID, setChangeLogListID] = useState<string[]>([]);
  const [usersListID, setUsersListID] = useState<string[]>([]);
  const [contentsListID, setContentsListID] = useState<string[]>([]);

  //EXCHANGES
  //Customer and user
  const [customerId, setCustomerId] = useState<string>("");
  const [customerUserName, setCustomerUserName] = useState<string>("");
  const [customerFirstName, setCustomerFirstName] = useState<string>("");
  const [customerLastName, setCustomerLastName] = useState<string>("");
  const [customerEmail, setCustomerEmail] = useState<string>("");
  const [customerMobilePhoneNumber, setCustomerMobilePhoneNumber] = useState<string>("");
  const [customerZipCode, setCustomerZipCode] = useState<string>("");
  const [customerCity, setCustomerCity] = useState<string>("");
  const [customerCountry, setCustomerCountry] = useState<string>("");
  const [customerLanguage, setCustomerLanguage] = useState<string>("");

  const [exchangesDraft, setGroupExchangesDraft] = useState<any>([{}]);
  const [exchangesAbandonned, setGroupExchangesAbandonned] = useState<any>([{}]);
  const [exchangesSubmitted, setGroupExchangesSubmitted] = useState<any>([{}]);
  const [exchangesAnalysis, setGroupExchangesAnalysis] = useState<any>([{}]);
  const [exchangesAccepted, setGroupExchangesAccepted] = useState<any>([{}]);
  const [exchangesAvailable, setGroupExchangesAvailable] = useState<any>([{}]);
  const [exchangesExchanged, setGroupExchangesExchanged] = useState<any>([{}]);
  const [exchangesRefused, setGroupExchangesRefused] = useState<any>([{}]);
  const [exchangesRecycled, setGroupExchangesRecycled] = useState<any>([{}]);

  //Dristributor and group
  const [distributorId, setDistributorId] = useState<string>("");
  const [distributorCode, setDistributorCode] = useState<string>("");
  const [distributorName, setDistributorName] = useState<string>("");
  const [distributorGroupCode, setDistributorGroupCode] = useState<string>("");
  const [distributorGroupName, setDistributorGroupName] = useState<string>("");
  const [distributorClientAccountNumer, setDistributorClientAccountNumer] = useState<string>("");
  const [distributorDeliveryAccountNumber, setDistributorDeliveryAccountNumber] = useState<string>("");
  const [distributorPhone, setDistributorPhone] = useState<string>("");
  const [distributorEmail, setDistributorEmail] = useState<string>("");
  const [distributorResponsiveEmail, setDistributorResponsiveEmail] = useState<string>("");

  //Product and brand
  const [productId, setProductId] = useState<string>("");
  const [productReference, setProductReference] = useState<string>("");
  const [productName, setProductName] = useState<string>("");
  const [productBrandCode, setProductBrandCode] = useState<string>("");
  const [productBrandName, setProductBrandName] = useState<string>("");
  const [productPicture, setProductPicture] = useState<any>("");
  const [productType, setProductType] = useState<string>("");
  const [productUnitPrice, setProductUnitPrice] = useState<string>("");

  //USERS BY STATUS
  const [userDisabled, setGroupUserDisabled] = useState<any>([{}]);
  const [userEnabled, setGroupUserEnabled] = useState<any>([{}]);
  const [userPending, setGroupUserPending] = useState<any>([{}]);
  const [userWebServices, setGroupUserWebServices] = useState<any>([{}]);
  const [userDisabledLength, setUserDisabledLength] = useState<number>(0);
  const [userEnabledLength, setUserEnabledLength] = useState<number>(0);
  const [userPendingLength, setUserPendingLength] = useState<number>(0);
  const [userWebServicesLength, setUserWebServicesLength] = useState<number>(0);

  //ALERTS
  const [alertDeleteBrand, setAlertDeleteBrand] = useState<boolean>(false);
  const [alertDeleteReasonExchange, setAlertDeleteReasonExchange] = useState<boolean>(false);
  const [alertDeleteRefusalReason, setAlertDeleteRefusalReason] = useState<boolean>(false);
  const [alertDeleteProduct, setAlertDeleteProduct] = useState<boolean>(false);
  const [alertDeleteGroup, setAlertDeleteGroup] = useState<boolean>(false);
  const [alertDeleteContent, setAlertDeleteContent] = useState<boolean>(false);
  const [alertDeleteExchanges, setAlertDeleteExchanges] = useState<boolean>(false);
  const [alertDeleteDistributor, setAlertDeleteDistributor] = useState<boolean>(false);
  const [alertDeleteExchange, setAlertDeleteExchange] = useState<boolean>(false);
  const [alertDeleteCustomer, setAlertDeleteCustomer] = useState<boolean>(false);
  const [alertDeleteUser, setAlertDeleteUser] = useState<boolean>(false);
  const [successResetPassword, setSuccessResetPassword] = useState<boolean>(false);
  const [typeOfSearch, setTypeOfSearch] = useState<number>(1); // 0 -> on table, 1-> on pop up
  const [errorCheckSession, setErrorCheckSession] = useState<boolean>(false);

  //pages
  const [brandPage, setBrandPage] = useState(1);
  const [productsPage, setProductsPage] = useState(1);
  const [groupsPage, setGroupsPage] = useState(1);
  const [distributorsPage, setDistributorsPage] = useState(1);
  const [distributorsSetPage, setDistributorsSetPage] = useState(1);
  const [customerPage, setCustomerPage] = useState(1);
  const [exchangePage, setExchangePage] = useState(1);
  const [reasonForExchangePage, setReasonForExchangePage] = useState(1);
  const [reasonForRefusalPage, setReasonForRefusalPage] = useState(1);
  const [userPage, setUserPage] = useState(1);
  const [changeLogPage, setChangeLogPage] = useState(1);
  const [contentPage, setContentPage] = useState(1);

  const [openDistributorSelect, setOpenDistributorSelect] = useState<boolean>(false);
  const [openMembersOfSetSelect, setOpenMembersOfSetSelect] = useState<boolean>(false);
  const [membersOfSetListMembers, setMembersOfSetListMembers] = useState<any[]>([]);
  const [membersOfSetListIdSelect, setMembersOfSetListIdSelect] = useState<any[]>([]);
  const [brandDeleteToggle, setBrandDeleteToggle] = useState<boolean>(false);

  //Table render
  const [brandTableTransferData, setBrandTableTransferData] = useState<string[]>([]);
  const [productTableTransferData, setProductTableTransferData] = useState<string[]>([]);
  const [groupTableTransferData, setGroupTableTransferData] = useState<string[]>([]);
  const [distributorTableTransferData, setDistributorTableTransferData] = useState<string[]>([]);
  const [distributorSetTableTransferData, setDistributorSetTableTransferData] = useState<string[]>([]);
  const [customerTableTransferData, setCustomerTableTransferData] = useState<string[]>([]);
  const [exchangesTableTransferData, setExchangesTableTransferData] = useState<string[]>([]);
  const [contentsTableTransferData, setContentsTableTransferData] = useState<string[]>([]);
  const [reasonForExchangeTableTransferData, setReasonForExchangeTableTransferData] = useState<string[]>([]);
  const [reasonForRefusalTableTransferData, setReasonForRefusalTableTransferData] = useState<string[]>([]);
  const [changeLogsTableTransferData, setChangeLogsTableTransferData] = useState<string[]>([]);
  const [usersTableTransferData, setUsersTableTransferData] = useState<string[]>([]);

  const [changeLanguage, setChangeLanguage] = useState<boolean>(false);
  const contextValues = {
    changeLanguage,
    setChangeLanguage,
    login,
    setLogin,
    language,
    setLanguage,
    typeUser,
    setTypeUser,
    loading,
    setLoading,
    brandId,
    setBrandId,
    brandCode,
    setBrandCode,
    brandName,
    setBrandName,
    brandToDelete,
    setBrandToDelete,
    openBrandTable,
    setOpenBrandTable,
    openCreateBrand,
    setOpenCreateBrand,
    openGroupTable,
    setOpenGroupTable,
    openCreateGroup,
    setOpenCreateGroup,
    groupId,
    setGroupId,
    groupCode,
    setGroupCode,
    groupName,
    setGroupName,
    role,
    setRole,

    brandLength,
    setBrandLength,
    productLength,
    setProductLength,
    groupLength,
    setGroupLength,
    distributorLength,
    setDistributorLength,
    exchangesLength,
    setExchangesLength,
    reasonExchangeLength,
    setReasonExchangeLength,
    refusalReasonLength,
    setRefusalReasonLength,
    userLength,
    setUserLength,

    exchangesDraftLength,
    setExchangesDraftLength,
    exchangesDAbandonnedength,
    setExchangesAbandonnedLength,
    exchangesSubmittedLength,
    setExchSubmittedDraftLength,
    exchangesAnalisisLength,
    setExchangesAnalisisLength,
    exchangesAcceptedLength,
    setExchangesAcceptedLength,
    exchangesAviableLength,
    setExchangesAviableLength,
    exchangesExchangedLength,
    setExchangeExchangeddLength,
    exchangesRefusedLength,
    setExchangesRefusedLength,
    exchangesRecycledLength,
    setExchangesRecycledLength,

    productToDelete,
    setProductToDelete,
    groupToDelete,
    setGroupToDelete,
    distributorToDelete,
    setDistributorToDelete,
    customerToDelete,
    setCustomerToDelete,
    customersLength,
    setCustomersLength,

    openDistributorTable,
    setOpenDistributorTable,
    openCustomerTable,
    setOpenCustomerTable,
    openProductTable,
    setOpenProductTable,
    openReasonExchangeTable,
    setOpenReasonExchangeTable,

    openCreateDistributor,
    setOpenCreateDistributor,
    openCreateCustomer,
    setOpenCreateCustomer,
    openCreateProduct,
    setOpenCreateProduct,
    openCreateReasonExchange,
    setOpenCreateReasonExchange,

    //EXCHANGES
    //Customer and user
    customerId,
    setCustomerId,
    customerUserName,
    setCustomerUserName,
    customerFirstName,
    setCustomerFirstName,
    customerLastName,
    setCustomerLastName,
    customerEmail,
    setCustomerEmail,
    customerMobilePhoneNumber,
    setCustomerMobilePhoneNumber,
    ExchangeToDelete,
    setExchangeToDelete,

    //Dristributor and group
    distributorId,
    setDistributorId,
    distributorCode,
    setDistributorCode,
    distributorName,
    setDistributorName,
    distributorGroupCode,
    setDistributorGroupCode,
    distributorGroupName,
    setDistributorGroupName,
    distributorClientAccountNumer,
    setDistributorClientAccountNumer,
    distributorDeliveryAccountNumber,
    setDistributorDeliveryAccountNumber,
    distributorPhone,
    setDistributorPhone,
    distributorEmail,
    setDistributorEmail,

    exchangesDraft,
    setGroupExchangesDraft,
    exchangesAbandonned,
    setGroupExchangesAbandonned,
    exchangesSubmitted,
    setGroupExchangesSubmitted,
    exchangesAnalysis,
    setGroupExchangesAnalysis,
    exchangesAccepted,
    setGroupExchangesAccepted,
    exchangesAvailable,
    setGroupExchangesAvailable,
    exchangesExchanged,
    setGroupExchangesExchanged,
    exchangesRefused,
    setGroupExchangesRefused,
    exchangesRecycled,
    setGroupExchangesRecycled,
    brandProductLength,
    setBrandProductLength,
    contentLength,
    setContentLength,
    changeLogLength,
    setChangeLogLength,

    //Product and brand
    productId,
    setProductId,
    productReference,
    setProductReference,
    productName,
    setProductName,
    productBrandCode,
    setProductBrandCode,
    productBrandName,
    setProductBrandName,
    productPicture,
    setProductPicture,
    productType,
    setProductType,
    productUnitPrice,
    setProductUnitPrice,

    reasonExchangeToDelete,
    setReasonExchangeToDelete,
    reasonExchangeId,
    setReasonExchangeId,
    reasonExchangeGivenReason,
    setReasonExchangeGivenReason,
    openRefusalReasonTable,
    setOpenRefusalReasonTable,
    openCreateRefusalReason,
    setOpenCreateRefusalReason,
    refusalReasonToDelete,
    setRefusalReasonToDelete,
    refusalReasonId,
    setRefusalReasonId,
    refusalReasonLabel,
    setRefusalReasonGivenLabel,
    userToDelete,
    setUserToDelete,
    brandsListID,
    setBrandsListID,

    //USERS BY STATUS
    userDisabled,
    setGroupUserDisabled,
    userEnabled,
    setGroupUserEnabled,
    userPending,
    setGroupUserPending,
    userWebServices,
    setGroupUserWebServices,
    userDisabledLength,
    setUserDisabledLength,
    userEnabledLength,
    setUserEnabledLength,
    userPendingLength,
    setUserPendingLength,
    userWebServicesLength,
    setUserWebServicesLength,
    distributorResponsiveEmail,
    setDistributorResponsiveEmail,

    productsListID,
    setProductsListID,
    groupsListID,
    setGroupsListID,
    distributorListID,
    setDistributorListID,
    distributorListMembers,
    setDistributorListMembers,
    customerListID,
    setCustomerListID,
    exchangesListID,
    setExchangesListID,
    reasonForExchangesListID,
    setReasonForExchangesListID,
    refusalReasonListID,
    setRefusalReasonListID,
    changeLogListID,
    setChangeLogListID,
    usersListID,
    setUsersListID,
    contentsListID,
    setContentsListID,

    alertDeleteBrand,
    setAlertDeleteBrand,
    alertDeleteReasonExchange,
    setAlertDeleteReasonExchange,
    alertDeleteRefusalReason,
    setAlertDeleteRefusalReason,
    alertDeleteProduct,
    setAlertDeleteProduct,
    alertDeleteExchange,
    setAlertDeleteExchange,
    alertDeleteDistributor,
    setAlertDeleteDistributor,
    alertDeleteCustomer,
    setAlertDeleteCustomer,
    alertDeleteGroup,
    setAlertDeleteGroup,
    alertDeleteContent,
    setAlertDeleteContent,
    alertDeleteExchanges,
    setAlertDeleteExchanges,
    alertDeleteUser,
    setAlertDeleteUser,
    successResetPassword,
    setSuccessResetPassword,
    typeOfSearch,
    setTypeOfSearch,
    errorCheckSession,
    setErrorCheckSession,

    customerZipCode,
    setCustomerZipCode,
    customerCity,
    setCustomerCity,
    customerCountry,
    setCustomerCountry,
    customerLanguage,
    setCustomerLanguage,
    distributorSetListId,
    setDistributorSetListId,
    distributorSetListIdSelect,
    setDistributorSetListIdSelect,
    distributorSetLength,
    setDistributorSetLength,
    alertDeleteDistributorSet,
    setAlertDeleteDistributorSet,

    brandPage,
    setBrandPage,
    productsPage,
    setProductsPage,
    groupsPage,
    setGroupsPage,
    distributorsPage,
    setDistributorsPage,
    distributorsSetPage,
    setDistributorsSetPage,
    customerPage,
    setCustomerPage,
    exchangePage,
    setExchangePage,
    reasonForExchangePage,
    setReasonForExchangePage,
    reasonForRefusalPage,
    setReasonForRefusalPage,
    userPage,
    setUserPage,
    changeLogPage,
    setChangeLogPage,
    contentPage,
    setContentPage,
    openDistributorSelect,
    setOpenDistributorSelect,
    openMembersOfSetSelect,
    setOpenMembersOfSetSelect,
    membersOfSetListMembers,
    setMembersOfSetListMembers,
    membersOfSetListIdSelect,
    setMembersOfSetListIdSelect,
    brandDeleteToggle,
    setBrandDeleteToggle,

    brandTableTransferData,
    setBrandTableTransferData,
    productTableTransferData,
    setProductTableTransferData,

    groupTableTransferData,
    setGroupTableTransferData,
    distributorTableTransferData,
    setDistributorTableTransferData,
    distributorSetTableTransferData,
    setDistributorSetTableTransferData,
    customerTableTransferData,
    setCustomerTableTransferData,
    exchangesTableTransferData,
    setExchangesTableTransferData,
    contentsTableTransferData,
    setContentsTableTransferData,
    reasonForExchangeTableTransferData,
    setReasonForExchangeTableTransferData,
    reasonForRefusalTableTransferData,
    setReasonForRefusalTableTransferData,
    changeLogsTableTransferData,
    setChangeLogsTableTransferData,
    usersTableTransferData,
    setUsersTableTransferData,
  };

  return <ApplicationContext.Provider value={contextValues}>{props.children}</ApplicationContext.Provider>;
};

export { ApplicationContext, ApplicationContextProvider };

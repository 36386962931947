import { AxiosRequestConfig } from "axios";
import { axios as http } from "../../frameworks_and_drivers/Axios";
import { BACKEND_URL } from "../../config/settings.json";
import { IUsersList, IUsersListID } from "../interfaces/interfaz_user";
import { downloadFileFromResponse } from "./helps";
import HelpService from "../HelpService";
import { loadAbort } from "../../helpers/loadAbort";

export const getAllUsers = (props?: any) => {
  const controller = loadAbort();
  const { query } = props;
  const URL = `${BACKEND_URL}/users/get-all?` + HelpService.getQueryString(query);
  return {
    call: http.get<IUsersList>(URL, { signal: controller.signal }),
    controller,
  };
};

export const getAllUsersPagination = async (page: number, limit: number) => {
  const URL = `${BACKEND_URL}/users/get-all?limit=${limit}&offset=${page}`;
  const response = await http.get<IUsersList>(URL);
  return response;
};
export const getAllUsersByStatus = async (status: string) => {
  const URL = `${BACKEND_URL}/users/get-all?status=${status}`;
  const response = await http.get<IUsersList>(URL);
  return response;
};

export const getAllUsersById = async (objectId: string) => {
  const URL = `${BACKEND_URL}/users/${objectId}/get-one`;
  const response = await http.get<IUsersListID>(URL);
  return response;
};

export const usersLogin = async (username: string, password: string) => {
  const body = {
    username: username,
    password: password,
    is_admin: true,
  };
  const URL = `${BACKEND_URL}/users/login`;
  const response = await http.post(URL, body);
  return response;
};

export const updateUser = async (
  objectId: string,
  first_name: string,
  last_name: string,
  email: string,
  profile_picture: string,
  mobile_phone: string,
  lenguage_active: string,
  status: string
) => {
  // let body= new FormData()
  // body.append("first_name": first_name)
  // body.append("last_name": last_name)
  // body.append("email":email)
  // body.append("birth_date": birth_date)
  // body.append("profile_picture": profile_picture)
  // body.append("work_phone": work_phone)
  // body.append("home_phone": home_phone)
  // body.append("mobile_phone": mobile_phone)
  // body.append("title":title)
  // body.append("status": status)
  // body.append("menu_visible":menu_visible)
  // bodyFormData.append("users_settings",JSON.stringify({
  //     "lenguage_active": lenguage_active,
  //     "preferred_language": preferred_language
  // }))
  // bodyFormData.append("address",JSON.stringify({
  //      "address": address,
  //      "address_complement": address_complement,
  //      "coordinates": coordinates,
  //      "map_url": map_url,
  //      "geographical_coordinates":geographical_coordinates,
  //      "city": city,
  //      "country": country,
  //      "zipcode": zipcode,
  //      "state": state
  // }))

  const body = {
    first_name: first_name,
    last_name: last_name,
    email: email,
    mobile_phone: mobile_phone,
    users_settings: {
      lenguage_active: lenguage_active,
    },
    status: status,
  };
  const URL = `${BACKEND_URL}/users/${objectId}/update-one`;
  const response = await http.put(URL, body);
  // const response = await http.post(URL,body,opts2);
  return response;
};

export const CreateUsers = async (
  username: string,
  first_name: string,
  last_name: string,
  lenguage_active: string,
  email: string,
  mobile_phone: string,
  roles: string,
  profile_picture: string
) => {
  // let body= new FormData()
  // body.append("username": username)
  // body.append("first_name": first_name)
  // body.append("last_name": last_name)
  // body.append("email":email)
  // body.append("lasts_login":lasts_login)
  // body.append("birth_date": birth_date)
  // body.append("profile_picture": profile_picture)
  // body.append("work_phone": work_phone)
  // body.append("home_phone": home_phone)
  // body.append("mobile_phone": mobile_phone)
  // body.append("title":title)
  // body.append("menu_visible":menu_visible)
  // bodyFormData.append("users_settings",JSON.stringify({
  //     "lenguage_active": lenguage_active,
  //     "preferred_language": preferred_language
  // }))
  // bodyFormData.append("address",JSON.stringify({
  //      "address": address,
  //      "address_complement": address_complement,
  //      "coordinates": coordinates,
  //      "map_url": map_url,
  //      "geographical_coordinates":geographical_coordinates,
  //      "city": city,
  //      "country": country,
  //      "zipcode": zipcode,
  //      "state": state
  // }))
  // body.append("roles": [roles])
  const body = {
    username: username,
    users_settings: {
      lenguage_active: lenguage_active,
    },
    address: {
      address: "",
    },
    roles: [roles],
    first_name: first_name,
    last_name: last_name,
    email: email,
    profile_picture: profile_picture,
    mobile_phone: mobile_phone,
  };
  const URL = `${BACKEND_URL}/users/create-one`;
  const response = await http.post(URL, body);
  // const response = await http.post(URL,body,opts2);
  return response;
};

export const addUserToDistributor = async (objectId: string, item: string) => {
  const body = {
    item: item,
  };
  const URL = `${BACKEND_URL}/distributors/${objectId}/add-users`;
  const response = await http.put(URL, body);
  return response;
};

export const addUserToGroup = async (objectId: string, item: string) => {
  const body = {
    item: item,
  };
  const URL = `${BACKEND_URL}/groups/${objectId}/add-users`;
  const response = await http.put(URL, body);
  return response;
};

export const addUserToCustomer = async (
  business: string,
  company_name: string,
  terms_accepted: boolean,
  privacy_policy_accepted: boolean,
  comments: string,
  user: string,
  account_type: string
) => {
  const body = {
    business: business,
    company_name: company_name,
    terms_accepted: terms_accepted,
    privacy_policy_accepted: privacy_policy_accepted,
    comments: comments,
    user: user,
    account_type: account_type,
  };
  const URL = `${BACKEND_URL}/customers/create-one-from-user`;
  const response = await http.post(URL, body);
  return response;
};

export const addUserToCustomerOther = async (user: string) => {
  const body = {
    user: user,
    account_type: "Company",
  };
  const URL = `${BACKEND_URL}/customers/create-one-from-user`;
  const response = await http.post(URL, body);
  return response;
};

export const updateUserStatus = async (objectId: string, status: string) => {
  const body = {
    status: status,
  };
  const URL = `${BACKEND_URL}/users/${objectId}/update-one`;
  const response = await http.put(URL, body);
  return response;
};

export const deleteUser = async (objectId: string) => {
  const URL = `${BACKEND_URL}/users/${objectId}/remove-one`;
  const response = await http.delete(URL);
  return response;
};

export const generateNewPassword = async (username: string) => {
  const body = {
    username: username,
  };
  const URL = `${BACKEND_URL}/users/generate-password`;
  const response = await http.put(URL, body);
  return response;
};

export const resetNewPassword = async (objectId: string, password: string) => {
  const body = {
    password: password,
  };
  const URL = `${BACKEND_URL}/users/${objectId}/set-password`;
  const response = await http.put(URL, body);
  return response;
};

export const deleteManyUsers = async (ids: string[]) => {
  const URL = `${BACKEND_URL}/users/delete-many`;
  const body = {
    items: ids,
  };
  const response = await http.put(URL, body);
  return response;
};

export const getExportUsers = async (type: string) => {
  const URL = `${BACKEND_URL}/users/generate-report`;
  const opts: AxiosRequestConfig = {
    params: {
      export_type: type,
    },
    responseType: "blob",
  };
  const response = await http.get(URL, opts);
  const responseDownload = downloadFileFromResponse(response, type, "Users");
  return responseDownload;
};

export const getExportUsersByID = async (type: string, ids: string[]) => {
  const URL = `${BACKEND_URL}/users/create-report`;
  const body = {
    file_type: type,
    search: ids,
  };
  const opts: AxiosRequestConfig = {
    responseType: "blob",
  };
  const response = await http.post(URL, body, opts);
  const responseDownload = downloadFileFromResponse(response, type, "Users");
  return responseDownload;
};

export const updateDistributorUsers = async (userID: string, oldDistributor: string, objectId: string) => {
  const body = {
    item: userID,
    old_distributor: oldDistributor,
  };
  const URL = `${BACKEND_URL}/distributors/${objectId}/update-users`;
  const response = await http.put(URL, body);
  return response;
};

export const updateGroupUsers = async (userID: string, oldGroup: string, objectId: string) => {
  const body = {
    item: userID,
    old_group: oldGroup,
  };
  const URL = `${BACKEND_URL}/groups/${objectId}/update-users`;
  const response = await http.put(URL, body);
  return response;
};

export const deleteDistributorUsers = async (distributorId: string, userId: string) => {
  const URL = `${BACKEND_URL}/distributors/delete-users`;
  const body = {
    distributor: distributorId,
    user: userId,
  };
  const response = await http.put(URL, body);
  return response;
};
export const deleteGroupUsers = async (groupId: string, userId: string) => {
  const URL = `${BACKEND_URL}/groups/delete-users`;
  const body = {
    group: groupId,
    user: userId,
  };
  const response = await http.put(URL, body);
  return response;
};

//EMAIL

export const resendVerificationEmailAPI = async (email: string) => {
  const body = {
    is_admin: true,
    email: email,
  };
  const URL = `${BACKEND_URL}/users/send-verify-email`;
  const response = await http.post(URL, body);
  return response;
};

export const verifyEmailAPI = async (verificationCode: string | null) => {
  const body = {
    verification_code: verificationCode,
  };
  const URL = `${BACKEND_URL}/users/code-verify-email`;
  const response = await http.post(URL, body);
  return response;
};

export const resendResetPasswordEmailAPI = async (email: string) => {
  const body = {
    is_admin: true,
    email: email,
  };
  const URL = `${BACKEND_URL}/users/send-reset-password`;
  const response = await http.post(URL, body);
  return response;
};

export const verifyResetPasswordEmailAPI = async (password: string, authorizationCode: string | null) => {
  const body = {
    password: password,
    autorization_code: authorizationCode,
    is_admin: true,
  };
  const URL = `${BACKEND_URL}/users/code-confirm-password`;
  const response = await http.post(URL, body);
  return response;
};

export const checkSession = async () => {
  const URL = `${BACKEND_URL}/users/check-session`;
  const response = await http.get(URL);
  return response;
};

export const updateMigratedUsers = async (uuid: string, email: string | null) => {
  const body = {
    uuid: uuid,
    email_user: email,
    is_admin: true,
  };
  const URL = `${BACKEND_URL}/users/update-user-migrated`;
  const response = await http.put(URL, body);
  return response;
};

export const updatePassword = async (password: string, email: string | null) => {
  const body = {
    password: password,
    email_user: email,
  };
  const URL = `${BACKEND_URL}/users/set-password-akamai`;
  const response = await http.put(URL, body);
  return response;
};

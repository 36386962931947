import axios, { AxiosStatic as HttpHandler, AxiosRequestConfig, AxiosResponse, AxiosError } from "axios";
import { HTTPCodesEnum } from "../models/httpcodes.models";

import { STORE } from "../store/redux";
import { Profile, setProfile, clearState } from "../store/redux/actions/profile_actions";
import { OCP_KEY, AGORA_KEY } from "../config/settings.json";

const unlessPaths = ["/login", "/"];

const setInterceptors = () => {
  let data: Profile = {};

  const listener = () => {
    data = STORE.getState().profileReducer.profile;
  };

  STORE.subscribe(listener);

  const unless = (url: string, paths: string[]) => {
    return !paths.findIndex((path) => url.includes(path));
  };
  const redirect = () => {
    // clearLocalStorage();
    STORE.dispatch<any>(clearState());
    // window.location.replace("/account-login");
  };

  const onRequest = (request: AxiosRequestConfig<any>) => {
    const URL = request.url;
    // if (request.headers) request.headers["Ocp-Apim-Subscription-Key"] = `${OCP_KEY}`;
    // if (request.headers) request.headers["Agora-Subscription-Key"] = `${AGORA_KEY}`;
    if (URL && !unless(URL, unlessPaths)) if (request.headers && data.token) request.headers["Authorization"] = `Bearer ${data.token}`;
    // request.headers['Authorization'] = `Bearer eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCIsImtpZCI6IjYxOTUzOWIyNGUwYTBlMzlhZWYyMDBkNSJ9.eyJ1c2VybmFtZSI6InNlYmFzdGlhbiIsImVtYWlsIjoic2NhcmRlbmFzQGdtYWlsLmNvbSIsImZ1bGxfbmFtZSI6InNlYmFzdGlhbiBjYXJkZW5hcyIsInJvbGVzIjpbIjYxOTU2NDJlZGZhOGFjYjVmOTc1N2ExNiJdLCJpYXQiOjE2MzcxODMyMzksImV4cCI6MTY1NDQ2MzIzOSwiaXNzIjoiZmFjb20iLCJzdWIiOiI2MThkODEwNGU5NmQ1MjAwMTFlMmM3NzIiLCJqdGkiOiIyNDhjYjBmYi1jZmI2LTQ4OWEtOGE4OS1jODEyNzQ3NjZhOTEifQ.dTBXSuAuZ6mLHwBjZkJLoXa-AmAnzLInU2tMPbxKYQlg4UkpobrhtvyEt3jQPmxbnfO83Q41ZWJE8IjQ925GQj2EtIoElnVJZ8_3U45XEMN1FTmxFvgxdHnHP3V52yF3i8yTntPiDqk3Of8PsP1B_hO--yCXigwecDM0IPxVBNktz-jn6UvNC7IRCUBETm3BMeb9NfKBgvO48NAUoXGpq9_5ZUXEE2vpCHRc9U-hu9CS5crzZTSdZZxmpIebxOJStACDUJcYYZphe-x2eRef3B465Rd1hS1Ss4-n9wphY7dUXUd8Ph48_PAKhMlw2PNZSXVW97UFxY10AHvJh07nAA`;
    return request;
  };

  const onError = (err: any) => {
    return Promise.reject(err);
  };

  axios.interceptors.request.use(onRequest, onError);

  axios.interceptors.response.use(
    (response: AxiosResponse) => {
      console.log("response");
      return response;
    },
    (error: AxiosError) => {
      if (error?.response?.status === HTTPCodesEnum.UNAUTHORIZED || error?.response?.status === HTTPCodesEnum.FORBIDDEN) {
        redirect();
      }
      return Promise.reject(error);
    }
  );
};

const service = {
  axios,
};
export default service;
export { axios, setInterceptors };
export type { HttpHandler, AxiosResponse };

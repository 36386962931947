import { createStore, combineReducers, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import reducers from "./reducers";
import { persistStore } from "redux-persist";

const ROOT_REDUCER = combineReducers(reducers);

export type IRootState = ReturnType<typeof ROOT_REDUCER>;

const STORE = createStore(ROOT_REDUCER, applyMiddleware(thunk));
const PERSISTOR = persistStore(STORE);

export { STORE, PERSISTOR };

import React, {useEffect,useState} from 'react'
import { AxiosResponse } from "axios";

export const downloadFileFromResponse = (response: AxiosResponse, exportType: string, name : string) => {
    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', `${name}.${exportType}`); //or any other extension
    document.body.appendChild(link);
    link.click();
}

export function useWindowSize(){
    const[windowSize, setWindowSize] = useState<any>({
        width: undefined,
        height: undefined
    })

    useEffect(() => {
        function handleResize(){
            setWindowSize({
                width: window.innerWidth,
                height: window.innerHeight
            })
        }
        window.addEventListener('resize',handleResize);
        handleResize()
        return () => window.removeEventListener('resize',handleResize)
    },[])
    return windowSize
    }